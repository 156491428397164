/* custom fonts */
$font-path-url: "../../fonts";

@font-face {
  font-family: "opensansblack";
  src: url("#{$font-path-url}/opensans/OpenSans-ExtraBold.woff2")
      format("woff2"),
    url("#{$font-path-url}/opensans/OpenSans-ExtraBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "opensansbold";
  src: url("#{$font-path-url}/opensans/OpenSans-Bold.woff2") format("woff2"),
    url("#{$font-path-url}/opensans/OpenSans-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "opensansmedium";
  src: url("#{$font-path-url}/opensans/OpenSans-SemiBold.woff2") format("woff2"),
    url("#{$font-path-url}/opensans/OpenSans-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "opensansregular";
  src: url("#{$font-path-url}/opensans/OpenSans-Regular.woff2") format("woff2"),
    url("#{$font-path-url}/opensans/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "opensanslight";
  src: url("#{$font-path-url}/opensans/OpenSans-Light.woff2") format("woff2"),
    url("#{$font-path-url}/opensans/OpenSans-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@mixin font-shorthand(
  $size: $font-size-base,
  $lineHeight: $line-height-base,
  $weight: normal,
  $style: normal
) {
  font-size: $size !important;
  font-weight: $weight;
  line-height: $lineHeight;
  /* font-style: $style; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* main font mixing */
@mixin font-black($size: $font-size-base, $lineHeight: $line-height-base) {
  font-family: "opensansblack", "opensansregular", sans-serif;
  @include font-shorthand($size, $lineHeight);
}

@mixin font-bold($size: $font-size-base, $lineHeight: $line-height-base) {
  font-family: "opensansbold", "opensansregular", sans-serif !important;
  @include font-shorthand($size, $lineHeight);
}

@mixin font-medium($size: $font-size-base, $lineHeight: $line-height-base) {
  font-family: "opensansmedium", "opensansregular", sans-serif;
  @include font-shorthand($size, $lineHeight);
}

@mixin font-regular($size: $font-size-base, $lineHeight: $line-height-base) {
  font-family: "opensansregular", sans-serif;
  @include font-shorthand($size, $lineHeight);
}

@mixin font-light($size: $font-size-base, $lineHeight: $line-height-base) {
  font-family: "opensanslight", "opensansregular", sans-serif;
  @include font-shorthand($size, $lineHeight);
}

/* flex mixing */
@mixin flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.f-13 {
  font-size: 13px !important;
}

[data-theme="dark"] {
  .f-13 {
    color: #9d9fa1;
  }
}
