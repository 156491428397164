/* reset password page css start here */
.before-reset-login {
  @include flexbox;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url("../../images/background.jpg");
  .inner-page {
    width: 100%;
    margin: 0 auto;
    .logo {
      text-align: center;
      padding-bottom: 50px;
    }
    .reset-page {
      max-width: 550px;
      width: 550px;
      margin: 0 auto;
      background: $page-bg;
      .reset-page-content {
        padding: 50px;
        h1 {
          @include font-bold(25px, 1.37);
          color: $white;
        }
        p {
          @include font-regular(15px, 1.47);
          color: $white;
        }
        .email-section {
          @include font-regular(15px, normal);
          padding: 16px;
          margin-top: 25px;
          background: rgba($color: $white, $alpha: 0.05);
          color: rgba($color: $white, $alpha: 0.7);
        }
        form {
          padding-top: 53px;
          .form-group {
            position: relative;
            .form-control {
              @include font-regular(18px, normal);
              color: $white;
              border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
              &:focus {
                border-bottom: 1px solid $link-color;
              }
            }
          }
          .forgot-password {
            position: absolute;
            top: 30px;
            right: 0;
            img {
              cursor: pointer;
            }
          }
        }
        .submit-button {
          padding-top: 10px;
          button {
            @include font-medium(13px, normal);
          }
        }
      }
    }
  }
}

.confirmEmail {
  padding: 32px;
  img {
    display: block;
  }

  &__image {
    width: 30%;
  }

  &__logo {
    width: 25%;
    padding: 12px 0;
  }

  &__mail {
    @include font-light(16px, normal);
    color: $link-color !important;
    padding: 12px 0;
    text-decoration: underline;
  }

  &__button {
    margin-top: 12px;
    max-width: 30%;
    a {
      @include font-bold(16px, 1.37);
      color: $white;
    }
  }
}

.changePassword-form {
  background: rgba($color: $white, $alpha: 0.2);
  &__inner {
    padding: 36px;
    label {
      color: $white;
    }
    input {
      color: $white !important;
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.2) !important;
    }
  }

  &__sentence {
    @include font-regular(16px, 1.47);
    color: $white;
    text-align: center;
    margin: 12px auto 0 auto;
  }
}
