.groundDetail {
  &__root {
    margin-top: -10px;
    margin-bottom: -10px;
    a {
      color: $font-dark-grey !important;
      @include font-regular($size: 12px, $lineHeight: "normal");
      text-decoration: underline;
      cursor: pointer !important;
      margin-bottom: 20px;
    }
  }

  &__delete {
    margin-left: auto;
  }

  &__main-route {
    margin: 0 0;
    padding: 18px 8px;

    background-color: #f7f8f8;

    p.line-text {
      padding: 2px 0;
      color: #606060;
      @include font-regular(15px, "normal");
      border-bottom: 1px solid #ececec;
    }
  }

  &__container-route {
    padding: 0 18px;
  }

  &__contact-company {
    p {
      color: #606060;
      padding: 2px 0;
    }
  }

  &__illustration {
    width: 120px;
    height: 120px;
  }

  &__header-main {
    background-color: #e7e8ea;
    svg {
      cursor: pointer;
      &:hover {
        color: $orange;
      }
    }
    h3 {
      @include font-regular($size: 20px, $lineHeight: "normal");
      color: $black;
    }
  }

  &__route-text {
    p {
      color: $black;
      @include font-regular($size: 14px, $lineHeight: "normal");
    }
  }

  &__info {
    background-color: #f0f5f9;
    padding: 6px 18px;
    p {
      @include font-regular($size: 14px, $lineHeight: "normal");
      color: $black;
    }
  }

  &__schedule {
    flex: 3;
  }

  &__other {
    padding: 10px 25px 5px 25px;
    border-bottom: 2px solid #e0e1e3;

    label {
      @include font-medium($size: 14px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 1);
    }
    p {
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: #606060;
    }
    div:last-child {
      padding-right: 0;
    }
  }

  &__route-text {
    padding: 5px 0;
    margin-left: 0;
  }

  &__pickups {
    padding: 5px 25px;
    margin-left: 0;
    label {
      @include font-medium($size: 14px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 1);
    }
    p {
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: #606060;
    }
  }

  &__main {
    @include flexbox;
    align-items: center;
  }

  &__notes {
    p {
      margin: auto -12px;
      border-top: 1px solid rgba($color: $black, $alpha: 0.1);
      padding: 10px 24px;
      color: #606060;
      @include font-regular($size: 14px, $lineHeight: "normal");
    }
  }

  &__notes-head {
    margin: 0 -8px;
    padding: 5px 25px 5px 25px;
    border-bottom: 2px solid #e0e1e3;
    cursor: pointer;
    h6 {
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: #606060;
    }
    svg {
      color: rgba($color: $black, $alpha: 1);
    }
  }

  &__details {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__detail-item {
    span {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.7);
      margin-bottom: 5px;
    }
    p {
      @include font-regular($size: 15px, $lineHeight: 1.47);
      color: $black;
    }
  }

  &__note {
    margin-top: 20px;
    span {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.7);
      margin-bottom: 5px;
    }
    p {
      @include font-regular($size: 15px, $lineHeight: 1.47);
      color: $black;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;
    margin: 10px 0 0 0;
    padding: 10px 27px;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;

    h6 {
      text-transform: uppercase;
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: #606060;
      margin-right: auto;
    }

    button.primary {
      max-height: 30px;
      padding: 7px 15px;
    }
  }

  &__vehicles {
    
    background-color: #f8f8f8;
    padding: 10px;
  }

  &__vehicle {
    outline: none;
    @include flexbox;
    padding: 0 10px 0 10px;
    svg {
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      svg {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__vehicle-inner {
    background-color: #c4d4e1;
    border-radius: 12px;
    padding: 3px 0;
    width: 100%;
  }

  &__vehicle-field {
    padding: 0 10px;
    .dropdown,
    .dropleft {
      width: auto;
    }
    p.transport {
      @include font-bold(15px, "normal");
      color: $black;
    }
    label {
      padding-top: 3px;
      @include font-medium(12px, "normal");
      color: $black;
    }
    p {
      @include font-regular(11px, "normal");
      color: #606060;
    }
    input {
      color: $black;
      @include font-medium(15px, "normal");
      &:focus {
        color: $black;
        border-bottom: 1px solid rgba($color: $font-dark-grey, $alpha: 0.1);
      }
    }
    .form-group .form-control {
      border-bottom: none;
      color: $black;
    }
    svg {
      font-size: 1em;
      cursor: pointer;
      &:hover {
        color: $orange;
      }
    }
  }

  &__members {
    margin-top: 0;
    padding-bottom: 15px;
    span {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.7);
      margin-bottom: 5px;
      text-transform: uppercase;
    }
  }

  &__member-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    @include flexbox;
    background-color: #eee;
    img {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  &__member {
    @include flexbox;
    align-items: center;

    margin: 0 0;
    border-bottom: 1px solid #ececec;
    &:hover {
      button {
        opacity: 1;
        visibility: visible;
      }
      a {
        @include font-light($size: 12px, $lineHeight: "normal");
      }
    }
    /*
    &:nth-child(odd) {
      background-color: #fefefe;
    }
    &:nth-child(even) {
      background-color: #f8f8f8;
    }*/
    &:last-child {
      border-bottom: none;
    }
    p {
      @include font-medium($size: 14px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 1);
      margin-right: auto;
    }
    a {
      @include font-light($size: 0, $lineHeight: "normal");
      color: #606060;
      text-transform: lowercase;
      transition: 100ms ease-out font-size;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    button {
      height: auto;
      white-space: pre;
      opacity: 0;
      visibility: hidden;
      svg {
        color: $red;
      }
    }
  }
}

.vehicleModal {
  &__root {
    .card:hover {
      color: $primary-button-bg !important;
    }
    &.groups {
      padding: 6px;
    }
    &.filter {
      min-width: 600px;
    }
    padding: 5px;
    border-radius: 5px;
    background-color: $white;
    .form-control {
      padding: 6px 0;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.3);
    }
    .col-md-12 {
      label {
        color: #696969;
      }
    }
    .row {
      label {
        color: #696969;
      }
    }
    input {
      &::placeholder {
        color: rgba($color: $black, $alpha: 0.6);
      }
    }
    .react-datepicker__input-container {
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.3);
      input {
        height: 40px;
      }
    }
    .form-check-label {
      color: $black;
    }
    .css-1hwfws3 {
      padding: 0;
    }
    .css-2b097c-container {
      .css-1uccc91-singleValue {
        @include font-regular(14px, "normal");
        color: $black;
      }
      .css-yk16xz-control {
        border-bottom: 1px solid rgba($color: $black, $alpha: 0.3);
      }
      .css-b8ldur-Input {
        margin: 0;
      }
      .css-1wa3eu0-placeholder {
        margin: 0;
      }
    }
  }
}

[data-theme="dark"] {
  .vehicleModal {
    &__root {
      background-color: #262a36;
      h5 {
        color: $white !important;
      }

      form {
        .form-control,
        .css-yk16xz-control {
          border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
          color: $white;
          &::placeholder {
            color: rgba($color: $white, $alpha: 0.6);
          }
        }
      }
    }
  }
  .groundDetail {
    &__root {
      border: 1px solid #353945;
      border-bottom: none;
    }
    &__root,
    &__header-main,
    &__route,
    &__main,
    &__other,
    &__header {
      background-color: #262a36;
    }
    &__vehicles {
      background-color: #2e3240;
    }
    &__header-main {
      h3 {
        color: $white;
      }
    }

    &__main-route {
      background-color: #2e3240;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      strong {
        color: $white;
      }
      p {
        color: #9d9fa1;
        font-size: 15px;
      }
      .line-text {
        border-bottom: 1px solid #353945;
        color: #9d9fa1;
      }
    }
    &__link {
      color: $almost-white;
      &:hover {
        color: $primary-button-bg;
      }
    }

    &__notes-head {
      border-bottom: 1px solid #353945;
      svg {
        color: #9d9fa1;
      }
    }

    &__note {
      span {
        color: $almost-white;
      }
      textarea {
        color: $almost-white;
      }
    }

    &__date,
    &__time {
      span,
      p {
        color: $almost-white;
      }
    }

    &__info {
      background-color: rgba($color: $primary-button-bg, $alpha: 0.1);
      p {
        color: whitesmoke;
      }
    }

    &__pickups {
      label {
        color: whitesmoke;
      }
      background-color: transparent;
    }

    &__other {
      border-bottom: 1px solid #353945;
      label {
        color: $almost-white;
      }
    }

    &__details {
      span {
        color: $font-dark-grey;
      }
      p {
        color: $almost-white;
      }
    }
    &__main {
      h6 {
        color: $almost-white;
      }
      p {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }

    &__notes-head {
      border-bottom: none;
    }

    &__notes {
      background-color: transparent;
      h6 {
        color: $almost-white;
      }
      p {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }

    &__note {
      p {
        color: $almost-white;
      }
    }

    &__vehicle-field {
      input {
        color: $almost-white;
        border-bottom: none;
        &:focus {
          color: $almost-white;
          border-bottom: 1px solid rgba($color: $primary-button-bg, $alpha: 0.1);
        }
      }
    }
    &__vehicle {
    }
    &__vehicle-inner {
      background-color: #33455e;
      p.transport,
      label {
        color: $white;
      }
      p {
        color: #9a9c9f;
      }
    }
    &__vehicle-field {
      label {
      }
    }
    &__header {
      border-top: 1px solid #353945;
      border-bottom: 1px solid #353945;
      background-color: #2e3240;

      h6 {
        color: $almost-white;
      }
    }
    &__members {
      span {
        color: $almost-white;
      }
    }
    &__member {
      border-bottom: 1px solid #353945;

      &:nth-child(odd) {
        background-color: #252833;
      }
      &:nth-child(even) {
        background-color: #2e3240;
      }
      p {
        color: $almost-white;
      }
    }
  }
}
