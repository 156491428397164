/* reset password success page css start here */
.before-password-login {
  @include flexbox;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url("../../images/background.jpg") no-repeat;
  .inner-page {
    width: 100%;
    margin: 0 auto;
    .logo {
      text-align: center;
      padding-bottom: 50px;
    }
    .password-success-page {
      max-width: 550px;
      width: 550px;
      padding: 80px 48px 80px;
      margin: 0 auto;
      background: $page-bg;
      .password-success-content {
        .password-success-img {
          @include flexbox;
          align-items: center;
          justify-content: center;
        }
        .password-success-text {
          padding-top: 30px;
          h1 {
            @include font-bold(15px, 1.3);
            text-align: center;
            color: $white;
          }
        }
        .password-success-pg {
          width: 394px;
          margin: 0 auto;
          padding-top: 15px;
          p {
            @include font-regular(15px, 1.47);
            color: rgba($color: $white, $alpha: 0.7);
            text-align: center;
          }
        }
      }
    }
  }
}
