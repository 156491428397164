ul.avatars {
  display: flex;
  list-style-type: none;
  margin: auto;
  padding: 0px 7px 0px 0px;
  z-index: 1;
  position: relative;
  top: 0 !important;
  left: 0;
  background: none;
  .popup-content {
    z-index: 555 !important;
    width: 300px !important;
  }
}
li.avatars__item {
  height: 20px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 18px;
  img {
    object-fit: cover;
  }
  &:hover {
    background: none;
  }
  .avatar{
    bordeR:solid 1px #fff;
  }
}
li.avatars__item:nth-child(1) {
  z-index: 9;
}
li.avatars__item:nth-child(2) {
  z-index: 8;
}
li.avatars__item:nth-child(3) {
  z-index: 7;
}
li.avatars__item:nth-child(4) {
  z-index: 6;
}
li.avatars__item:nth-child(5) {
  z-index: 5;
}
li.avatars__item:nth-child(6) {
  z-index: 4;
}
li.avatars__item:nth-child(7) {
  z-index: 3;
}
li.avatars__item:nth-child(8) {
  z-index: 2;
}
li.avatars__item:nth-child(9) {
  z-index: 1;
}

img.avatars__img,
span.avatars__initials,
span.avatars__others {
  /*background-color: #596376;*/
  border: 2px solid #fff;
  border-radius: 10px;
  color: #ffffff;
  display: block;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 100;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
}
span.avatars__others {
  background-color: #1e8fe1;
}
