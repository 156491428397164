/* calendar list page css start here */

.icon-right {
  @include flexbox;
  align-items: center;
  .icon-squared {
    @include flexbox;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;

    background: rgba($color: $white, $alpha: 0.05);
    &:last-child {
      margin-right: 0;
    }
    svg {
      color: $almost-white;
      height: 22px !important;
    }

    &.purple {
      background-color: #627ce1;
    }

    &.orange {
      background-color: #f17f4e;
    }
  }
  .icon-round {
    @include flexbox;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 15px;
    background: rgba($color: $white, $alpha: 0.05);
    svg {
      color: $almost-white;
    }
    &.orange {
      background-color: #f57f4e;
    }
    &.pink {
      background-color: #fc3480;
    }
    &.blue {
      background-color: #4084bf;
    }
  }
}
.calendar-page {
  width: 100%;
  height: calc(100% - 30px);
  .calendar-head {
    @include flexbox;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ;
    padding: 18px 30px;
    background: $main-grey;

    
  }
  .calendar-bottom {
    width: 100%;
    height: calc(100% - 65px);
    margin-top: 0px;
    // background: $dark-light-theme;
    .calendar-bottom-inside {
      width: 100%;
      height: 100%;
      padding: 0;
      .date-section {
        label {
          color: $black;
        }
        @include flexbox;
        align-items: center;
        margin-bottom: 25px;
        width: 100%;
        background-color: $third-grey;
        padding: 8px 30px;
        .date-select-section {
          &.disabled {
            opacity: 0.4;
          }
          @include flexbox;
          align-items: center;
          justify-content: space-between;
          width: 197px;
          padding: 10px 20px;
          padding-right: 0;
          background: $white;
          margin-right: 10px;
          .left-text {
            padding-right: 20px;
            p {
              @include font-regular(12px, normal);
              color: $font-dark-grey;
            }
          }
          .date-text {
            @include flexbox;
            align-items: center;
            position: relative;
            padding-right: 20px;
            input {
              color: $black;
              text-shadow: none;
              &:focus {
                color: transparent;
              }
            }
            span {
              @include font-regular(14px, normal);
              color: $black;
            }
            .drop-icon {
              cursor: pointer;
            }
          }
        }
        .until-section {
          width: 197px;
          height: 50px;
          background: $dark-theme;
        }
      }
      /* calendar section start */
      .clendar-section {
        width: 100%;
        height: 100%;
        padding: 0 2%;
        .event__selectable {
          background-color: $white;
          margin: 2px 0;
          border-radius: 4px;
          overflow: hidden;
          padding: 6px 18px;
          .event-title {
            @include font-bold(14px, "normal");
            &:hover {
              text-decoration: underline;
              color: $primary-button-bg;
            }
          }
          .event-info {
            color: #606060;
            @include font-regular(14px, "normal");
          }
          .icons-container {
            margin-right: 1.5rem;
          }
        }
        .calendar-list {
          @include flexbox;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 12px 30px;
          margin-bottom: 5px;
          background: $light-grey;
          .calendar-icon {
            @include flexbox;
            align-items: center;
            .icon {
              position: relative;
              text-align: center;
              .calendar-inside {
                position: absolute;
                top: 17px;
                left: 0;
                width: 100%;
              }
            }
            .text-section {
              @include flexbox;
              flex-direction: column;
              padding-left: 15px;
              .text-event-head {
                h3 {
                  @include font-regular(17px, normal);
                  word-break: break-word;
                  a {
                    color: $black;
                  }
                }
              }
              .text-detail {
                @include flexbox;
                align-items: center;
                margin-top: 5px;
                p {
                  @include font-regular(13px, normal);
                  color: $font-grey;
                }
                .pad-section {
                  width: 1px;
                  height: 10px;
                  margin: 0 10px;
                  background: $font-grey;
                }
              }
            }
          }
          .right-icon {
            @include flexbox;
            align-items: center;
            .dropdown,
            .dropleft {
              justify-content: flex-start;
            }
            .icon-section {
              padding-right: 5px;
              img {
                cursor: pointer;
              }
            }
            .dot-icon {
              padding-left: 0;
              img {
                cursor: pointer;
              }
            }
            .dropdown-menu {
              background: $page-bg;
              color: $white;
              font-size: 14px;
              padding: 0;
              // transform: translate3d(0, -10px, 0px) !important;
              &:focus {
                outline: none;
              }
              .dropDownList {
                padding: 5px;
                background: $page-bg;
                cursor: pointer;
                &:hover {
                  background: rgba($color: $white, $alpha: 0.2);
                }
              }
            }
          }
        }
        .green-left {
          border-left: 1px solid $green;
        }
        .red-left {
          border-left: 1px solid $red;
        }
        .yellow-left {
          border-left: 1px solid $yellow;
        }
        .blue-left {
          border-left: 1px solid $blue;
        }
        .purple-left {
          border-left: 1px solid $purple;
        }
        .infinite-scroll-component {
          overflow: auto;
        }
      }
    }
  }
}

.right-icon {
  @include flexbox;
  align-items: center;
  .dropleft {
    justify-content: flex-start;
  }
  .icon-section {
    padding-right: 5px;
    img {
      cursor: pointer;
    }
  }
  .dot-icon {
    padding-left: 50px;
    img {
      cursor: pointer;
    }
  }
  .dropdown-menu {
    background: $page-bg;
    color: $white;
    font-size: 14px;
    padding: 0;
    // transform: translate3d(0, -10px, 0px) !important;
    &:focus {
      outline: none;
    }
    .dropDownList {
      padding: 5px;
      background: $page-bg;
      cursor: pointer;
      &:hover {
        background: rgba($color: $white, $alpha: 0.2);
      }
    }
  }
}

.move-section {
  width: 100%;
  .calendar-list {
    @include flexbox;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 30px;
    margin-bottom: 15px;
    // background: $light-grey;

    .left-icon {
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
    }
    .right-icon {
      @include flexbox;
      align-items: center;

      .icon-section {
        padding-right: 5px;
        img {
          cursor: pointer;
        }
      }
      .dot-icon {
        padding-left: 50px;
        img {
          cursor: pointer;
        }
      }
      .status-icon {
        padding-left: 20px;
        &:before {
          content: " \25CF";
          font-size: 20px;
          color: $green;
        }
      }
    }
  }
  .green-left {
    border-left: 1px solid $green;
  }
  .red-left {
    border-left: 1px solid $red;
  }
  .yellow-left {
    border-left: 1px solid $yellow;
  }
  .blue-left {
    border-left: 1px solid $blue;
  }
  .purple-left {
    border-left: 1px solid $purple;
  }
}

.calendar-icon {
  @include flexbox;
  align-items: center;

  .text-section {
    @include flexbox;
    flex-direction: column;
    padding-left: 15px;
    .text-event-head {
      h3,
      a {
        @include font-bold(17px, normal);
        color: $black !important;
        background: none;
        word-break: break-word;
        padding: 0 !important;
      }
    }
    .text-detail {
      @include flexbox;
      align-items: center;
      p {
        @include font-regular(13px, normal);
        color: $font-grey;
      }
      .pad-section {
        width: 1px;
        height: 10px;
        margin: 0 10px;
        background: $font-grey;
      }
    }
  }
}

.icon {
  position: relative;
}

.event-item {
  background-color: transparent;
  .event-status {
    height: 8px;
    width: 8px;
    background-color: #bbb;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 5;
    &.Confirmed {
      background-color: $green;
    }
    &.Cancelled {
      background-color: $error-color;
    }
    &[class*="Tentative"] {
      background-color: $yellow;
    }
    &.Postponed {
      background-color: $purple;
    }
  }
}

.infinite-scroll-component__outerdiv {
  height: 100%;
}
.eventList {
  height: 100% !important;
  &::-webkit-scrollbar {
    width: 1em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  &::scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
  }
}

.custom-option {
  background-color: black;
  color: $white;
  cursor: pointer;
  &:hover {
    color: $primary-button-bg;
  }
}

.customStatus__container,
.groups__container {
  margin-top: 18px;
  width: 100%;
  h4 {
    @include font-medium(14px, "normal");
    color: rgba($color: $black, $alpha: 0.7);
  }
}

.customStatus__control,
.groups__control,
.ticketsDropdown__control {
  background-color: #fff !important;
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.7) !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.customStatus__control--is-focused,
.customStatus__control--is-menu-open,
.ticketsDropdown__control--is-menu-open {
  border: none !important;
  box-shadow: none !important;
}

.customStatus__indicator-separator,
.groups__indicator-separator,
.ticketsDropdown__indicator-separator {
  display: none !important;
}

.ticketsDropdown__menuList,
.ticketsDropdown__menu,
.groups__menu,
.customStatus__menu {
  z-index: 999 !important;
  left: 0 !important;
  background-color: #fff !important;
}

.groups__menu {
  right: 0 !important;
  left: auto !important;
}

.containerTickets {
  height: 60px;
  .ticketsDropdown__control {
    
  }
  .guestlist__options {
    cursor: pointer;
    &:hover {
      p,
      h3 {
        color: $primary-button-bg;
      }
    }
  }
  p {
    &.title {
      @include font-bold(14px, normal);
      color: $black;
    }
    &.details {
      @include font-regular(12px, normal);
      color: rgba($color: $black, $alpha: 0.7);
    }
  }
}

.statusCustom {
  &-option {
    color: rgba($color: $black, $alpha: 0.7);
    &:hover {
      color: $primary-button-bg;
    }
    cursor: pointer;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
    &:last-child {
      border-bottom: none;
    }
  }
}

.groupItem__root {
  .dropdown {
    width: auto;
    cursor: pointer;
  }
}

[data-theme="dark"] {
  .calendar-head {
    background-color: $header-dark;
    border-bottom: 1px solid $border-dark-grey;
  }

  .calendar-page .calendar-bottom .calendar-bottom-inside .date-section {
    background-color: $header-dark;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    label {
      color: $almost-white;
    }
    .date-select-section {
      background-color: $black-bg;
      .left-text {
        padding-right: 20px;
        p {
          @include font-regular(12px, normal);
          color: $font-dark-grey;
        }
      }
      .date-text {
        input {
          color: $almost-white;
        }
        span {
          color: $almost-white;
        }
      }
    }
  }

  .sidebarEvents__list {
    .event-item {
      background-color: transparent;
    }
  }

  .calendar-icon .text-section .text-event-head h3,
  .calendar-icon .text-section .text-event-head a {
    color: $almost-white !important;
    &:hover {
      color: $primary-button-bg !important;
    }
  }

  .icon .calendar-inside h3,
  .icon .calendar-inside p,
  .icon .calendar-inside span {
    color: $almost-white;
  }

  .calendar-page
    .calendar-bottom
    .calendar-bottom-inside
    .clendar-section
    .calendar-list {
    background-color: #262a36;
    .event-item {
      background-color: #262a36;
    }
    .right-icon {
      svg {
        color: $almost-white;
      }
    }
    .calendar-icon .text-section .text-event-head {
      h3,
      a {
        color: $almost-white !important;
      }
    }
    .calendar-icon .icon .calendar-inside {
      h3,
      p,
      span {
        color: $almost-white;
      }
    }
  }

  .customStatus__control,
  .groups__control,
  .ticketsDropdown__control {
    background-color: #2a2d37 !important;
    border: none !important;
    box-shadow: none !important;
  }
}
