.deleteModal {
  &__root {
    margin: 0 auto;
    max-width: 375px;
    border-radius: 2px;
    box-shadow: 0 1px 20px 0 rgba(26, 27, 41, 0.1);
    background-color: #2b2f3c;
    padding: 30px;
  }
  &__title {
    @include font-bold($size: 22px, $lineHeight: 1.68);
  }
  &__item {
    @include flexbox;
    justify-content: flex-start;
    margin-bottom: 24px;
    img {
      max-width: 20px;
      margin-left: 0;
      margin-right: 5px;
    }
    h6 {
      @include font-regular($size: 13px, $lineHeight: 1.38);
      color: rgba($color: $white, $alpha: 0.7);
    }
  }
  &__text {
    @include font-regular($size: 16px, $lineHeight: 1.38);
    color: #a2a3a7;
    margin-bottom: 25px;
  }
  &__buttons {
    @include flexbox;
    padding: 0 0 25px 0;
    button {
      margin: 0 5px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
        @include font-bold($size: 13px, $lineHeight: 1.38);
        background-color: rgba(248, 110, 124, 0.25);
      }
    }
  }
}
