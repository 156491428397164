/* reset password page css start here */
.before-login {
  @include flexbox;
  align-items: center;
  width: 100%;
  height: auto;
  background: transparent;
  .inner-page {
    width: 100%;
    margin: 0 auto;
    .emailsent-page {
      max-width: 470px;
      width: 470px;
      padding: 30px 48px;
      margin: 0 auto;
      border-radius: 2px;
      background: $white;

      @media screen and (max-width: $phone-landscape) {
        width: 90%;
        max-width: unset;
      }
      .emailsent-page-content {
        .emailsent-img {
          @include flexbox;
          align-items: center;
          justify-content: center;
        }
        .emailsent-text {
          text-align: center;
          padding-top: 20px;
          h1 {
            @include font-bold(15px, 1.3);
            text-align: center;
            color: $black;
          }
          p {
            @include font-regular(15px, normal);
          }
        }
        .emailsent-pg {
          padding-top: 18px;
          p {
            @include font-regular(15px, 1.47);
            color: rgba($color: $black, $alpha: 0.7);
            text-align: center;
          }
        }
        .submit-button {
          padding-top: 25px;
          button {
            @include font-medium(13px, normal);
          }
        }
      }
    }
  }
}
