.event-inside.hotel {
}

.addGround {
  &__vehicle {
    @include flexbox;
    padding: 12px 20px 6px 20px;
    background-color: #c4d4e1;
    border-radius: 6px;
    margin: 6px auto;
    svg.delete {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 1em;
      height: 1em;
      color: $red;
      font-size: 0.8em;
      cursor: pointer;
    }
    > div {
      flex: 1;
      padding-right: 8px;
      &:last-child {
        padding-right: 0;
      }
    }
    .form-group {
      label {
        color: $black !important;
      }
      flex: 1;
      padding: 0 5px;

      &:first-child {
        padding-left: 0;
      }
    }
    .form-control {
      height: 38px;
    }
  }

  &__note {
    margin-top: 20px;
  }

  &__add {
    @include font-regular($size: 11px, $lineHeight: "normal");
    text-transform: uppercase;
    a {
      text-decoration: underline;
      color: $white;
    }
  }

  &__vehicle-button {
    width: 25px;
    height: 62px;
    margin: 0 12px;
    @include flexbox;
    justify-content: center;
    align-items: center;
  }

  &__cta {
  }
}

[data-theme="dark"] {
  .addGround {
    .form-group .css-1wa3eu0-placeholder {
      color: $almost-white;
    }
    &__vehicle {
      background-color: #33455e;

      .css-yk16xz-control {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
      .css-2b097c-container {
        border-bottom: none !important;
      }
      .css-1uccc91-singleValue,
      .form-group label {
        color: $white !important;
      }
      .form-group input {
        &::placeholder {
          color: #696969;
        }
        color: $white;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}
