.page-header {
  @include flexbox;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ebeef1;
  padding: 18px 30px 18px 30px;
  background: linear-gradient(90deg, #f7f9fa 0%, #fafafa 100%);
  h1 {
    margin-left: 15px;
  }
  .content-right {
    @include flexbox;
    align-items: center;
  }
}
.menu__toggle {
  margin-right: 10px;
}
@media screen and (max-width: 1025px) {
  .page-header-logo {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
  }

  .page-header {
    padding: 45px 30px 5px 30px;
    h1 {
      margin: 0px;
      padding: 0px;
    }
  }
}
@media screen and (max-width: 560px) {
  .menu__toggle {
    background-color: #627ce1;
    color: white;
  }
}

.list {
  background: $white;
  border-radius: 5px;
  border: 0px solid #ccc;
  overflow: hidden;
  padding-bottom: 10px;
  height: 95%;
  color: rgba($color: $black, $alpha: 1);
  box-shadow: 0px 0px 6px 0px rgba(155, 156, 157, 0.45);
  @media screen and (min-width: 1025px) {
    flex: 1;
  }
}

.list-header {
  position: relative;
  @include flexbox;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 9px 18px;
  position: relative;
  .main-text {
    h3 {
      @include font-medium(16px, normal);
      color: rgba($color: $black, $alpha: 1);
    }
  }
}

.list-subheader {
  padding: 6px 10px;
  border-top: 1px solid rgba($color: $black, $alpha: 0.1);

  border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
  @include font-regular(12px, normal);
  /*text-transform: uppercase;*/
  color: rgba($color: $black, $alpha: 0.7);
  background-color: #f7f7f7; //linear-gradient(90deg, #e5e4e5 0%, gainsboro 100%);
}

.little-modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: $page-bg;
  color: rgba($color: $white, $alpha: 1);
  width: 300px;
  text-align: left;
  &__header {
    background-color: #272a36;
    color: rgba($color: $white, $alpha: 1);
    @include font-light(12px, normal);
    text-transform: uppercase;
    padding: 16px;
  }

  &__body {
    padding: 16px;
  }
}

.fullWidth {
  max-width: 100% !important;
}

.toggleButton {
  @include flexbox;
  align-items: center;
  max-width: 317px;
  border-radius: 30px;
  background-color: $border-light-grey;
  cursor: pointer;
  margin: 0 auto;
  .left,
  .right {
    @include font-light(12px, normal);
    color: rgba($color: $white, $alpha: 1);
    text-transform: uppercase;
    background-color: $border-light-grey;
    padding: 10px 36px;
    border-radius: 30px;
    &.active {
      background-color: $primary-button-bg;
    }
  }
}

.floating {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.divider {
  color: rgba($color: $white, $alpha: 0.7);
  margin: 0 6px;
}

.icon-svg {
  svg {
    margin: 0 auto;
  }
  max-width: 18px;
}

.darker {
  background-color: $dark-light-theme;
}

.lighter {
  background-color: $dark-theme;
}

.br-6 {
  border-radius: 6px;
}

.flex-1 {
  flex: 1;
}

.relative {
  position: relative !important;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  &.approved {
    background-color: $action-green;
  }
}

.expandable-link {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  z-index: 3;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 15px;
}

.swal-button--confirm {
  background-color: $primary-button-bg;
}

.dropdown-menu {
  border-radius: 3px;
  border-radius: 0;
  padding: 0.5rem 0;
  min-width: 15rem;
  z-index: 101;
  .dropDownList {
    &.delete {
      color: $red;
    }
    cursor: pointer;
    padding: 0.5rem 1rem;
    @include font-regular($size: 14px, $lineHeight: "normal");
    &:hover {
      color: $primary-button-bg;
      svg {
        color: $primary-button-bg;
      }
    }
  }
}

.dropDownList {
  &.delete {
    color: $red;
  }
  cursor: pointer;
  padding: 0.5rem 1rem;
  @include font-regular($size: 14px, $lineHeight: "normal");
  &:hover {
    color: $primary-button-bg;
    svg {
      color: $primary-button-bg;
    }
  }
}

.general__dropzone {
  padding: 24px;
  border: 3px dashed $border-dark-grey;
  color: $black;
  margin: 12px;
}

.dropdown,
.dropleft {
  /*width: 30%;*/
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-option {
  border-radius: 5px;
  // padding: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 4.5px;
  margin-left: 4.5px;
  background: rgb(63, 94, 251);

  transition: 300ms ease-in background-color;
  &.orange {
    background-color: #f57f4e;
  }
  &.pink {
    background-color: #fc3480;
  }
  &.blue {
    background-color: #4084bf;
  }
}

.cursor {
  cursor: pointer;
}

.dropdown-list {
  background-color: $almost-white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  max-height: 300px;
  overflow-y: scroll;
  min-width: 250px;
  a:not([href]):not([class]) {
    color: $black;
    @include font-regular($size: 14px, $lineHeight: "normal");
    &:hover {
      color: $primary-button-bg;
    }
  }
  a {
    color: $black;
    @include font-regular($size: 14px, $lineHeight: "normal");
    &:hover {
      color: $primary-button-bg;
    }
  }
  span {
    display: block;
    color: rgba($color: $black, $alpha: 0.7);
    @include font-regular($size: 12px, $lineHeight: "normal");
  }

  color: $black;
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  &::scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
  }
}

.emptyState__root {
  height: 100%;
  @include flexbox;
  justify-content: center;
  flex-direction: column;
  img {
    margin-left: auto;
    margin-right: auto;
  }
  p {
    color: rgba($color: $black, $alpha: 0.3);
  }
  button {
    white-space: pre;
  }
}
.emptyState__root_detail {
  height: 100%;
  @include flexbox;
  justify-content: center;
  flex-direction: column;
  img {
    margin-left: 41%;
    margin-right: auto;
  }
  p {
    color: rgba($color: $black, $alpha: 0.3);
  }
  button {
    white-space: pre;
  }
}

.viewer-documents-drag {
  background-color: rgba($color: $black, $alpha: 0.5);
  cursor: grabbing;
  display: block;
  width: 100%;
  padding: 10px;
}

.custom-control-label {
  color: rgba($color: $black, $alpha: 1);
}

.h-100 {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.production-layout {
  padding: 128px 0 35px 0;
  background: $solid_background;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100vh;
  position: relative;
}

svg.delete {
  color: $red;
  &:hover {
    color: darken($color: $red, $amount: 15);
  }
}

.h-0 {
  height: 0;
  svg {
    height: 0;
  }
}

.react-datepicker-popper {
  z-index: 555 !important;
}

.rightSide__date {
  .react-datepicker-popper {
    left: -85px !important;
  }
}
ul.slick-dots {
  position: relative !important;
  height: auto !important;
  top: auto !important;
  left: auto !important;
  bottom: 0 !important;
  width: 100% !important;
  margin: 0 !important;
  list-style: none !important;
  text-align: center !important;
}

.react-datepicker.react-datepicker--time-only {
  left: 60px;
}

.swal2-popup {
  width: 22em !important;
}

.swal2-content {
  width: 18em;
}

.swal2-icon {
  height: 3.5em;
  width: 3.5em;
}

.swal2-title {
  font-size: 1.5em;
}

.__react_component_tooltip {
  max-width: 300px;
}

[data-theme="dark"] {
  .swal2-popup {
    background-color: #2e3240 !important;
  }
  .swal2-title,
  .swal2-content {
    color: $white;
  }
  .img-thumbnail {
    background-color: #2e3240;
    border: 1px solid #2e3240;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  }
  .popup-content {
    background-color: $page-bg !important;
    border: none !important;
  }
  .emptyState__root {
    p {
      color: #5e6063;
    }
  }
  .list {
    background: $dark-theme-bg;
    color: rgba($color: $white, $alpha: 1);
  }

  .list-header {
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    background: $dark-theme-bg;
    .main-text {
      h3 {
        color: rgba($color: $white, $alpha: 1);
      }
    }
  }

  .list-subheader {
    color: rgba($color: $white, $alpha: 0.7);
    background-color: #272b36;
  }
  .page-header {
    background-color: $header-dark;
    border-bottom: 1px solid $border-lighter-grey;
  }

  .dropdown-menu {
    background-color: #2b2f3c !important;
    border: 1px solid $border-dark-grey;
    z-index: 50;
    .dropDownList {
      @include font-regular($size: 14px, $lineHeight: "normal");
      color: $almost-white;
      &.border-bottom {
        border-bottom: 1px solid $border-light-grey !important;
      }
      &.delete {
        color: $red;
      }
      &:hover {
        color: $primary-button-bg;
      }
    }
  }
  .toggleButton {
    background-color: $border-lighter-grey;
    cursor: pointer;
    .left,
    .right {
      background-color: $border-lighter-grey;
    }
  }

  .dropdown-list {
    background-color: $page-bg;
    box-shadow: none;
    max-height: 300px;
    overflow-y: scroll;
    a {
      color: $almost-white;
      @include font-regular($size: 14px, $lineHeight: "normal");
    }
    span {
      display: block;
      color: rgba($color: $white, $alpha: 0.7);
      @include font-regular($size: 12px, $lineHeight: "normal");
    }
  }
}
.btn-primary {
  background: #01aeff !important;
  border: 0px;
}
.content {
  color: $black;
}
.modal-md {
  width: 50%;
  max-width: 90%;
}
.divisorVertical {
  font-size: 8px !important;
}
/*This is a weird class implemented in a few places */
.null {
  padding: 0px;
}
.tox-tinymce {
  z-index: 1000 !important;
}

.mailContainer {
  width: 850px !important;
}

.form-control.inline,
.hotelMember__root .react-datepicker-wrapper input,
.eventHorizontal .react-datepicker__input-container input,
.eventHorizontal .css-yk16xz-control {
  border: 0px solid !important;
  border-bottom: 0px !important;
}

//There's a weird implementation of bootstrap not every single class is in place
.spinner-border-sm {
  width: 1rem !important;
  height: 1rem !important;
  border-width: 0.2em;
}
.card.clickable:hover,
.clickable:hover {
  cursor: pointer;
  background: #f0f5f9;
}

.clickable.active {
  cursor: pointer;
  background: #f0f5f9;
  border-left: 4px solid #01aeff !important;
  transition: border-left 0.2s ease-in;
}
.clickable:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: calc(100%);
  background-color: #01aeff;
  transition: width 200ms ease-in;
}

.taskItem__note {
  background: #f1f1f1;
}
.tox-tinymce-inline {
  z-index: 1060 !important;
}
.noteWatermark {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  z-index: 1;
  opacity: 0.085;
  position: absolute;
  max-height: 80% !important;
  max-width: 100%;
  height: 35px;
  right: 20px;
  bottom: 20px;
  width: 35px;
}
.noteDraggable .noteWatermark {
  background-position: 0px;
  background-position-x: center !important;
}
.note {
  position: relative;
}
.note__text > ul {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.note__text,
.mce-content-body {
  z-index: 2;
  height: auto;
  // max-height: 130px;
  overflow-y: scroll;
  overflow-x: auto;
  color: $black;
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  &::scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
  }
}
#general .mce-content-body {
  min-height: 100px;
  overflow-y: auto;
  max-height: 100px;
  color: $black;
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  &::scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
  }
}
.img-fluid-files {
  max-width: 50%;
  height: auto;
}
.emptyStateStyle {
  max-height: 160px;
  min-height: 100px;
  max-width: 120%;
  margin-top: 60%;
  display: block;
}
.emptyStateStyleToDo {
  max-height: 160px;
  min-height: 100px;
  max-width: 90%;
  margin-top: 30%;
  display: block;
}
.MuiSvgIcon-root {
  font-size: 1.14rem !important;
}
/*
.event-inside .MuiSvgIcon-root {
  fill: $black !important;
}*/

.ListGroupHeader {
  background: #e2e2e2;
}

#PreMeeting {
  background: #01aeff !important;
}
#PreMeeting .noteWatermark {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  z-index: 0;
  opacity: 0.1;
  position: absolute;
  max-height: 80% !important;
  max-width: 80%;
  height: 90vh;
  width: 90vh;
}
.sidebarOnline .avatarStatus {
  position: relative !important;
}
.guestList th {
  font-size: 12px;
  font-weight: 500;
}
.guestList .requester {
  font-size: 14px;
  font-weight: 600;
}
.navItem i {
  color: #8e9bb6 !important;
}
.statusIconChat {
  font-size: 8px !important;
}

.altLinkSmall {
  font-size: 9px !important;
  color: #01aeff !important;
}
/*
.event-inside  .active{ 
  border: solid 1px #01aeff !important;
}
*/
.labelTitle {
  color: #8a8a8a;
}
.smallest {
  font-size: 60%;
}
.contentTitle {
  font-size: 14px;
  color: #000;
}

.stop {
  font-family: "opensansregular", sans-serif;
  font-size: 10px !important;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f29c32;
  padding-top: 4px;
}
.form-control {
  font-size: 14px !important;
}
.attachment__icon {
  text-align: left;
}
.attachment__icon img {
  max-height: 75px;
}
.text-head,
.headerBg {
  background: #fff !important;
  /* background: linear-gradient(
    90deg
    , #f7f9fa 0%, #fafafa 100%) !important;
}*/
}
.event-page {
  background: #f5f6fa !important;
}
input[type="date"] {
  padding-top: 10px;
  padding-bottom: 6px;
}
// .itineraries__form{
//   background:transparent !important;
// }
.contactItem__root .avatar {
  max-width: 44px !important;
}
.tox-tinymce {
  z-index: 1 !important;
}

.event-status {
  width: 8px !important;
  height: 8px !important;
}
.breadcrumb {
  background: transparent !important;
}
.containerFullHeight {
  height: calc(100vh - 150px);
}
.containerFullHeight .card {
  height: 100%;
}
.containerFullHeight .card-body {
  display: block;
}
// .eventRow.active,
// .eventRow.active:hover {
//   //background: #f0f0f0;
// }
.dropboxColor {
  color: #0060ff;
}
.modalScrollable {
  max-height: 80vh;
  overflow-y: auto;
}

// .filesContainer .uncheck, .selectorContainer .uncheck{
//   // opacity:0;

// }
// .filesContainer:hover .uncheck,.selectorContainer:hover .uncheck{
//   opacity:100;
// }
.googleDriveColor {
  color: #2684fc;
}
.pickFolders .active:before,
.eventPickerContainer .active:before {
  content: "";
  position: absolute;
  // top: 20%;
  height: calc(100% - 40%);
  left: 0;
  width: 2px;
  background-color: #01aeff;
}

.eventPickerContainer .active:before {
  content: "";
  position: absolute;
  top: 20%;
  height: calc(100% - 40%);
  left: 0;
  width: 2px;
  background-color: #01aeff;
}
.form-control.underline {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.leftSide__date .react-datepicker {
  margin-left: 60px;
}
.bgTitleModal {
  background: #e5e8eb;
}
.bgTitleModal h3 {
  font-size: 12px;
  font-weight: normal;
}
.formContainer-body {
  height: 78vh;
  overflow-y: auto;
}
.navigationContainer {
  color: #000;
  font-size: 12px;
  background: #f5f6fa;
}
.navigationContainer .active {
  background: #01aeff !important;
  color: #fff;
}
.navigationContainer .active .fas {
  color: #fff !important;
}

.brandingOrangeBackground {
  background: #ff5100;
  color: #fff;
}

.overflow50 {
  height: 50vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 0.3em;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8);
  outline: none;
}
.containerEditor > div {
  height: 100% !important;
  width: 100% !important;
}
.moreDetailsContainer {
  position: fixed;
  top: 5vh;
  left: 5vw;
  height: 90vh;
}
.z-index-0 {
  z-index: 0;
}
.moreDetails {
  width: 99.8%;
}
.popup-content .color-option {
  width: 22px;
  height: 22px;
  padding: 0px;
}
.colorIndicator {
  width: 100%;
  position: absolute;
  height: 5px;
  left: 0px;
  bottom: 0px;
}

.ql-editor {
  min-height: 100px;
}

.activeEvent,
.event-inside .active {
  background: #f0f5f9;
}

.workspacesOnProfile {
  max-height: 300px;
  overflow: auto;
}

.bg-subOption {
  background: #e7e7e7;
}
.hotelItem__root.ground,
.hotelItem__root.flight {
  max-height: 77px !important;
}

.hotelItem__root.flight .row {
  border: 0px;
}
.leftWhiteBorder {
  border-left: solid 1px #fff;
}
.mce-content-body {
  height: 100% !important;
  outline: none !important;
  border: 0px !important;
}
.mce-edit-focus {
  border: 0px !important;
  -webkit-tap-highlight-color: transparent !important;
  background: transparent !important;
  outline: none !important;
}

.mce-tinymce,
.mce-stack-layout,
.mce-edit-area {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.mce-tinymce iframe {
  flex: 1;
}

.tox,
.tox-tinymce {
  z-index: 50000 !important;
}

.tox:not([dir="rtl"]) {
  direction: ltr;
  text-align: left;
  outline: 0 !important;
  border: none;
}

.map {
  min-height: 250px;
}

.ql-editor {
  overflow: auto;
  color: black;
}

.moreMenu {
  background: #627ce1 !important;
}

.shareBg {
  background: #8e9bb6;
  border: solid 0px #8393b5;
}
.popup-content {
  z-index: 200000 !important;
}
.clickableItem {
  cursor: pointer;
}
.floatTopRight {
  position: absolute;
  top: 3px;
  right: 2px;
}

.formAlternativeStyle .form-control {
  display: block;
  width: 100%;
  // height: calc(1.5em + .75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.userTeam.inactive,
.userTeam.inactive {
  opacity: 0.6 !important;
}

.extendEventScreen {
  width: 700px !important;
}
.toDoFromEvent .list {
  background: transparent !important;
  box-shadow: 0px 0px 0px 0px rgb(155 156 157 / 45%) !important;
  border: 0px !important;
}

[type="date"] {
  height: 31px !important;
}
.EventInstance.active {
  background: #f0f5f9;
}
.EventInstance.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: calc(100%);
  background-color: #01aeff;
  transition: width 200ms ease-in;
}

.active .EventInstance {
  background: #f0f5f9 !important;
}
.active .EventInstance:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: calc(100%);
  background-color: #01aeff;
  transition: width 200ms ease-in;
}
.rbc-now > a {
  background: #01aeff;
  color: #fff !important;
  font-weight: normal !important;
  border-radius: 5px !important;
}
