* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  text-rendering: auto;
}

ul,
ol,
li {
  list-style-type: none;
  padding: 0;
}

.rbt {
  padding-bottom: 0 !important;
  border-bottom: none !important;
}

.rbt-input-wrapper {
  input {
    padding: 4px 0 !important;
    border: none !important;
  }
}

.rbt-input-multi.form-control {
  border: none !important;
}

html,
body {
  height: 100%;
  font-family: "opensans", sans-serif;
}

body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  -webkit-tap-highlight-color: transparent !important;
}

*:before,
*:after {
  -webkit-tap-highlight-color: transparent !important;
}
.btn {
  text-transform: capitalize !important;
}

.btn:focus {
  outline: none;
}

body,
ul,
figure {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: red;
  outline: none;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: darken(red, 10);
  }
}

p {
  font-size: 12px;
}

h1 {
  font-size: 15px;
  margin: 1;

  @media screen and (min-width: $tablet-portrait) {
    font-size: 30px;
  }
}

h2 {
  font-size: 24px;
  @media screen and (min-width: $tablet-portrait) {
    font-size: 40px;
  }
}

h3 {
  font-size: 24px;
  @media screen and (min-width: $tablet-portrait) {
    font-size: 30px;
  }
}

h4 {
  font-size: 19px;
  @media screen and (min-width: $tablet-portrait) {
    font-size: 19px;
  }
}

.text-primary {
  color: $primary-button-bg !important;
}

.custom-control-label:before {
  background-color: $white;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: auto;
  line-height: auto;
}

.tinyLoader {
  &__container {
    position: absolute;
    top: 0;
    right: 5px;
    width: 15px;
    height: 15px;
    z-index: 5;
    .spinner-border {
      width: 100%;
      height: 100%;
    }
  }
}

.h-auto {
  height: auto;
}
//Used for the listing of users assigned
.avatarsContainer {
  max-width: 100px;
  position: relative;
  margin-right: 12px;
  overflow: hidden;
}
.avatarContainer {
  width: 35px;
}
.avatarLogo {
  border-radius: 15%;
}
.avatar {
  border-radius: 15%;

  max-height: 100% !important;
  max-width: 22px !important;
}
h6 {
  color: $black;
  @include font-medium($size: 14px, $lineHeight: "normal");
}

/*This is because an issue with font awesome, probably there's a better way*/
.fa-plus {
  color: white !important;
  &_none{
    color: unset !important;
  }
}
.labelContainer {
  padding: 2px 12px;
  border-radius: 5px;
  background-color: #01aeff;
  font-family: "opensansregular", sans-serif !important;
  font-size: 11px !important;
  font-weight: normal;
  line-height: "normal";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fefefe;
  display: inline-flex;
  margin: 0 0 4px 0;
}

.mainSidebarContainer {

  width: calc(210px - 70px);
  left: 70px;
  height: 100%;
  margin: 0;

  position: absolute;
  z-index: 45;
}

.beta{
  display: block;
  &.active{
    display: none;
  }
}

h1 {
  @include font-bold(15px, normal);
  color: $black;
}
.icons-container svg {
  height: 16px;
}
.table td {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.sectionHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  background-color: #e3e3e3;
  color: #000000;
  min-height: 38px;
}

/* USER PROFILE*/
.profileContainer {
  position: fixed;
  color: $black;
  background-color: $white;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: auto;
  width: 300px;
  left: 210px;
  bottom: 10px;
}
.profileContainer .btn {
  color: $black !important;
  text-align: left;
  font-size: 12px;
  font-family: "opensansregular", sans-serif !important;
}
.profileContainer .btn .text-danger {
  color: orangered !important;
}
.profileContainer .avatar {
  max-width: 35px;
}

/*EMAIL MODAL */
#emailModal .text-head svg {
  color: $white;
  margin: 0px;
}
#emailModal .actionIcons svg {
  color: #000;
}
#emailModal .text-head svg
/*RESET BOOTSTRAP DEFAULTS*/
.card {
  border: 0px !important;
}

.icon .calendar-inside h3 {
  @include font-regular(9px, normal);
  color: $black;
}
.icon .calendar-inside p {
  @include font-bold(12px, normal);
  line-height: 1em;
  color: $black;
}

.calendar-inside {
  position: absolute;
  top: 14px;
  left: 8px;
  text-align: center;
}
.calendar-inside span {
  @include font-regular(11px, normal);
  color: $black;
  position: relative;
  bottom: 6px;
  left: 0;
}

.EventInstance {
  p {
    color: rgba($color: $black, $alpha: 0.8);
  }
}

.EventInstance .icon img {
  width: 32px;
  height: 32px;
  margin-top: 9px;
}
.EventInstance .icon span {
  border-radius: 5px;
  position: absolute;
  left: 2px;
  top: 13px;
  z-index: 10;
  width: 28px;
  height: 28px;
}
.modalContainer {
  background: $solid_background !important;
}

.create-button {
  position: relative;
  z-index: 100;
}

.gray-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.event-picker {
  .css-1hwfws3 {
    padding-left: 0 !important;
  }
  .css-yk16xz-control,
  .css-1pahdxg-control {
    border: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
}

#emailRecipients {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 6px;
}

.addTask {
  &__editor {
    .mce-content-body {
      height: 200px;
      color: $black;
      overflow-y: auto;
      max-height: 200px;
      overflow-x: auto;
      color: $black;
      img {
        max-width: 80%;
        max-height: 80%;
      }
    }
  }
  &__label {
    .custom-control-label {
      line-height: 24px !important;
    }
  }
}

.inviteContainer {
  height: calc(87vh);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  .btn-delete {
    svg {
      color: $red;
    }
  }
}

.btnToolTip svg {
  font-size: 0.9rem !important;
}

.counter {
  position: absolute;
  top: -5px;
  right: -4px;
  font-size: 8px;
  padding: 3px 4px;
  border-radius: 20px;
  background-color: $red;
  color: $white;
  z-index: 10;
}

.update__item {
  small {
    color: $theme-text;
    opacity: 0;
    visibility: hidden;
    text-align: center;
  }
  &:hover {
    cursor: pointer;
    small {
      opacity: 1;
      visibility: visible;
    }
  }
}

.editLogo {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  z-index: 5;
  cursor: pointer;
  transition: opacity 100ms ease-in;
  svg {
    color: $white;
    font-size: 1.3em;
  }

  &:hover {
    opacity: 1;

  }
}

.avatarItem {
  cursor: pointer;
}

.avatarStatus {
  height: 8px;
  width: 8px;
  background-color: $action-green;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px;
}

.meet {
  &__window {
    position: absolute;
    bottom: 5%;
    right: 1%;
    width: 200px;
    height: 125px;
    min-height: 150px;
    min-width: 150px;
    z-index: 100;
    &:hover {
      .meet__window-actions {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__invitation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 300px;
    @include flexbox;
    z-index: 100;
  }

  &__window-actions {
    opacity: 0;
    visibility: hidden;
    transition: all 200ms ease-in;
    padding-bottom: 10px;
    flex: 1;
    @include flexbox;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.chat {
  &__sidebar {
    position: fixed;
    bottom: 1%;
    right: -500px;
    width: 400px;
    height: calc(100vh - 2%);
    z-index: 85;
    background-color: $white;
    border-radius: 3px;
    transition: all 400ms ease-out;
    &.show {
      right: 1%;
    }
  }

  &__item {
    &:last-child {
      border-bottom: none;
    }
    padding: 10px 15px;
    border-bottom: solid 1px $border-color;
    cursor: pointer;
    &:hover {
      background-color: #eff5fa;
    }
  }

  &__sidebar-header {
    padding: 10px 15px;
    /*background: linear-gradient(90deg, gainsboro 0%, #ddddde 100%);*/
    svg {
      color: $black;
      cursor: pointer;
    }
  }

  &__window {
    position: fixed;
    bottom: 1%;
    right: 1%;
    width: 400px;
    height: 450px;
    z-index: 55;
    &.minimized {
      height: 50px;
    }
  }

  &__pic-uploader {
    input[type="file"] {
      display: none;
    }
    position: absolute;
    bottom: 9px;
    right: 40px;
    z-index: 5;
    height: 25px;
    width: 25px;
    svg {
      cursor: pointer;
      &:hover {
        color: $primary-button-bg;
      }
    }
  }

  &__header {
    background-color: $white;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: solid 1px rgba(33, 37, 41, 0.15);
    svg {
      cursor: pointer;
      &:hover {
        color: $primary-button-bg;
      }
    }
  }
}
.react-chat-messagesList {
  border: 0px !important;
}
.react-chat-viewerBox {
  border: 0px !important;
}
.react-chat-textarea {
  border-bottom: solid 1px #e7e7e7 !important;
  overflow-y: auto;
  margin-right: 24px !important;
}
.react-chat-bubble {
  font-size: 12px !important;
}
.react-chat-messageBox {
  max-width: 100% !important;
}

.react-chat-messagesList {
  &::-webkit-scrollbar {
    display: none !important;
  }
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}
.react-chat-additional {
  font-size: 9px !important;
  color: rgb(139, 139, 139) !important;
}
.guestRequest.child tr {
  margin-top: 1px;
  margin-bottom: 1px;
}
.guestRequest.child td {
  background: #fff;
  border-right: 1px solid rgb(238, 236, 236);
  border-bottom: solid 1px rgb(238, 236, 236);
}
.guestRequest .approved {
  border-left: 4px solid #1ba863;
}
.guestRequest .rejected {
  border-left: 4px solid #ff0000;
}
.guestRequest .pending {
  border-left: 4px solid #fb9f00;
}
.guestRequest:hover {
  cursor: pointer;
  background: #f1f1f1;
}

.c-pointer {
  cursor: pointer;
}

.typeahead__modifier {
  .rbt-input-multi.form-control {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

.subItemInList {
  background: #f5f5f5 !important;
}
.subItemInList table {
  background: #fff;
}
.card {
  border: 0px none !important;
}

.onboarding {
  &__container {
    background-image: url("../../images/login-bg.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh);
    .carousel {
      width: 55%;
      height: 60%;
      margin-top: calc(50% - 40%);
      @media screen and (max-width: 1279px) {
      }
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 18px;
      border-radius: 20px;
      background-size: 20px 20px;
    }

    .carousel-control-next-icon {
      background-image: url("../../images/chevron-right.png");
    }

    .carousel-control-prev-icon {
      background-image: url("../../images/chevron-left.png");
    }
    .carousel-indicators .active {
      opacity: 1;
    }
    .carousel-indicators {
      left: 0;
      margin: 0;

      li {
        width: 5px;
        height: 5px;
        box-sizing: content-box;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #fff;
        background-clip: border-box;
        border-top: 0 solid transparent;
        border-bottom: 0 solid transparent;
        opacity: 0.5;
        transition: opacity 0.6s ease;
        border-radius: 4px;
        &.active {
          width: 20px;
          height: 5px;
          background-color: #fff;
        }
      }
      li:first-child {
        position: relative;
        box-sizing: content-box;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: 5px;
        height: 5px;
        background-color: #fff;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-clip: border-box;
        border-top: 0 solid transparent;
        border-bottom: 0 solid transparent;
        opacity: 0.5;
        transition: opacity 0.6s ease;
        border-radius: 4px;
        &.active {
          width: 20px;
          height: 5px;
          opacity: 1;
          border-radius: 14px;
        }
        .first-scroll {
          width: 30px;
          height: 5px;
          background: white;
        }
        .round-scroll {
          width: 8px;
          height: 5px;
          background: white;
        }
      }
    }
  }

  &__carousel-container {
    @include flexbox;
    /*align-items: center;*/
    justify-content: center;
    height: 100%;
    position: relative;
  }

  &__slide {
    text-align: center;
    color: $white;
    img {
      margin-top: 46px;
      margin-bottom: 46px;
    }
    h2 {
      @include font-bold(20px, "normal");
      color: $white;
    }
    p {
      @include font-regular(15px, "normal");
      color: $white;
      height: 30px;
    }
  }
}

/* ON BOARDING*/
.onBoarding.contact-transparent {
  right: 0;
  left: 0;

  //background-image: url("/static/media/login-bg.d0bf6e47.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
}
.onBoarding.contact-transparent .eventForm__horizontal {
  background: #9eb1bf !important;
  color: #fff !important;
}

.onBoarding.contact-transparent .eventForm__horizontal input,
.onBoarding .eventHorizontal .css-1wa3eu0-placeholder,
.onBoarding .css-2b097c-container .css-yk16xz-control {
  color: #fff !important;
}
.onBoardingImportEvents {
  background-color: rgba(255, 255, 255, 0.2) !important;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
.onBoardingImportEvents .event-expennd-section {
  min-width: 70% !important;
  width: 100% !important;
}
.onBoardingImportEvents .text-head {
  background: transparent !important;
}
.onBoardingImportEvents .text-head .title {
  color: #fff;
}
.onBoarding .multipleEvents__toolbar {
  padding: 5px 10px;
}
.onBoarding .event-page {
  height: auto;
}

.shrinker {
  .col-md-6 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.actionButtons .popup-content {
  max-width: 300px !important;
}

.no-outline {
  outline: none !important;
}

.weather {
  &__box {
    background-image: url("../../images/weather_bg.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }


  &__city {
    @include font-medium($size: 12px, $lineHeight: "normal");
    color: $white;
  }

  &__deegre {
    @include font-regular($size: 25px, $lineHeight: "normal");
    color: $white;
  }

  &__type {
    @include font-regular($size: 12px, $lineHeight: "normal");
    color: $white;
  }

  &__interval {
    @include font-regular($size: 12px, $lineHeight: "normal");
    color: $white;
  }

  &__box-right {
    position: relative;
    p {
      &:first-child {
        @include font-medium($size: 12px, $lineHeight: "normal");
      }
    }
    &::before {
      content: "";
      position: absolute;
      height: 90%;
      width: 1px;
      left: 0;
      background-color: $white;
    }
  }
}

.hotel-transparent,
.hotelDetail__root {
  .mce-content-body {
    height: 150px;
    max-height: 150px;
    padding-bottom: 2px;
    overflow-y: scroll;
    overflow-x: auto;
    color: $black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
}

.css-1w9j89e-menu {
  z-index: 11 !important;
}


.leftSide__date .react-datepicker-popper{
  
  transform: none !important;
  margin-left:0px !important;
}
.mailBtn{
  background-color: #fc3480 !important;
}

.degreeThing{
  font-size:16px;
  text-transform: uppercase;
  position:absolute;
  
}
.sidebarUserItem{
 border-top: solid 1px #284163;
 width:98% !important;
}

.activitiesItem__time {
  min-width:60px !important;
}