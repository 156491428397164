.contactDetail {
  &__root {
    padding-bottom: 20px;
    form {
      padding-bottom: 30px;
    }
  }
  &__actions {
    cursor: pointer;

    margin-bottom: 8px;
    padding: 6px 0;
    width: 100%;
    svg.edit {
      &:hover {
        color: $orange;
      }
    }
    h4 {
      @include font-regular(19px, "normal");
      color: $black;
      margin-right: auto;
    }
  }

  &__main-profile {
    width: calc(100%);
    padding: 0;

    a,
    p {
      color: #606060;
    }
  }
  &__input {
    label {
      margin: 0 !important;
    }
    padding: 6px 0;
    .form-control {
      color: #606060;
      &.disabled-input {
        border: none;
      }
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
      &:active,
      &:focus {
        // color: $black;
        // border-bottom: 1px solid rgba($color: $primary-button-bg, $alpha: 1);
      }
    }

    label {
      @include font-medium(14px, 1em);
      color: $black;
      white-space: pre;
      margin: 0 6px 6px 0;
    }
  }

  &__main {
    padding: 0 0 12px 0;
    @include flexbox;
    flex-direction: column;
    align-items: center;
  }
  &__profile {
    img {
      width: 80px;
      border-radius: 15%;
    }
  }

  &__info {
    text-align: left;
    margin-top: 8px;
    h3 {
      @include font-bold($size: 20px, $lineHeight: "normal");
      color: $black;
    }
    p {
      @include font-regular($size: 15px, $lineHeight: "normal");
      color: $font-dark-grey;
    }
  }

  &__info-container {
    height: 100%;
    width: calc(100%);
    margin: 0 auto;
    border-top: 1px solid rgba($color: $black, $alpha: 0.2);
    .row {
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
    }
    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__contact {
    @include flexbox;
    align-items: center;
    div {
      flex: 1;
      &:first-child {
        margin-right: 35px;
      }
    }
  }

  &__position,
  &__contact {
    padding: 15px;
    // border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
    span {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.3);
    }
    p {
      @include font-regular($size: 15px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 1);
    }
    .form-control {
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.15);
    }
    label {
      color: $font-dark-grey;
    }
    input.form-control {
      color: #606060;
      &:active,
      &:focus {
        color: $black;
        border-bottom: 1px solid rgba($color: $font-dark-grey, $alpha: 0.1);
      }
    }
  }

  &__buttons {
    padding: 15px 30px;
    padding-top: 50px;
    @include flexbox;
    justify-content: flex-end;
    align-items: center;
    button {
      @include font-bold($size: 12px, $lineHeight: "normal");
      background-color: $red;
      color: rgba($color: $white, $alpha: 1);
      text-transform: uppercase;
      width: 140px;
      height: 30px;
      &:hover {
        background-color: rgba(248, 110, 124, 0.75);
      }
    }
  }
}

[data-theme="dark"] {
  .contactDetail {
    &__root {
      background-color: #262a36;

      form {
        background-color: #262a36;
      }
    }

    &__main-profile {
      background-color: #2e3240;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      a,
      p {
        color: #9d9fa1;
      }
    }
    &__info-container {
      background-color: #2e3240;
      .row {
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
        &:last-child {
          border: none;
        }
      }
    }
    &__actions {
      background-color: #262a36;
      h4 {
        color: $almost-white;
      }
      svg {
        color: #9d9fa1;
      }
    }
    &__input {
      .form-control {
        color: rgba($color: $white, $alpha: 0.9);
      }
      label {
        color: rgba($color: $white, $alpha: 0.45);
      }
    }
    &__info {
      h3 {
        color: $white;
      }
      p {
        color: #9d9fa1;
      }
    }

    &__position,
    &__contact {
      span {
        color: rgba($color: $white, $alpha: 0.3);
      }
      p {
        color: rgba($color: $white, $alpha: 1);
      }
      .form-control {
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.15);
      }
      label {
        color: $font-dark-grey;
      }
      input {
        color: $white;
        &:focus {
          color: $white;
          border-bottom: 1px solid rgba($color: $font-dark-grey, $alpha: 0.1);
        }
      }
    }
  }
}
