.rbc-calendar {
  color: $white;
  @include font-regular(14px, normal);
}

.rbc-toolbar {
  button {
    color: $black;
    &.rbc-active {
      color: $black;
    }
  }
}

.rbc-header {
  text-align: center;
  padding: 14px 14px 14px 14px;
  border-bottom: solid 1px #ebedee;
  span {
    color: $black;
  }
  &:nth-child(n + 1) {
    border-left: none;
  }
}

.rbc-off-range {
  /*text-decoration: line-through;*/
}

.rbc-date-cell {
  text-align: left;
  padding-left: 8px;
  padding-top: 8px;
  > a {
    color: $black !important;
  }
}

.rbc-off-range-bg {
  background-color: #eff0f3;
  /*  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAALElEQVQYV2NkwA2MGXHIGTMwMJzFJgmWAGlCl4RLoEuiSCBLYkjAJLFKgCQBILoGO5hdyysAAAAASUVORK5CYII=)
    repeat;*/
}

.rbc-today {
  background-color: transparent;
}

.rbc-date-cell.rbc-now.rbc-current,
.rbc-date-cell.rbc-current {
  a {
    border: 1px solid $primary-button-bg;
    border-radius: 50%;
    padding: 0 2px;
    text-align: center;
    font-weight: bold;
  }
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}

.rbc-month-view {
  background-color: #fff;

  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.175) !important;
}

.rbc-show-more {
  display: none;
}

.rbc-event.rbc-selected {
  background-color: transparent;
  border: none;
}

.rbc-event {
  width: 95%;
  margin: 0 auto;
  margin-top: 1px;
  background-color: transparent;
  height: 100%;
  .rbc-show-more-modal {
    color: $black;
    @include font-regular(12px, "normal");
    background-color: #f0f0f0;
    padding: 2px;
    border-radius: 2px;
    margin: 2px 0;
    display: block;
    &:hover {
      background-color: #eff5fa;
      color: $primary-button-bg;
    }
  }
  .inner {
    background-color: #e3e4e6;
    padding: 2px 2px 2px 6px;
    border-radius: 2px;
    &:hover {
      background-color: #eff5fa;
    }
  }
  .event-container {
    display: block;
  }
  .event-title {
    @include font-bold(14px, normal);
    color: $black;
    padding-top: 2px;
  }
  .event-location {
    @include font-regular(11px, normal);
    color: rgba($color: $black, $alpha: 0.7);
    width: 90%;
  }
  .event-status {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 5;
    &.Confirmed {
      background-color: $green;
    }
    &.Cancelled {
      background-color: $error-color;
    }
    &[class*="Tentative"] {
      background-color: $yellow;
    }
    &.Postponed {
      background-color: $purple;
    }
  }
  .event-icons {
    margin-top: 0px;
    .icon-section {
      svg {
        color: #737577;
        width: 13px;
        margin-right: 3px;
      }
    }
  }
}
.event-icons .MuiSvgIcon-root {
  height: auto;
}
.rbc-month-view {
  position: unset;
}

.rbc-show-more {
  color: $white;
  text-align: center;
  margin-top: 8px;
  &:hover,
  &:active,
  &:visited {
    color: $primary-button-bg;
  }
}

.customToolbar {
  .css-1pahdxg-control {
    background: #fff !important;
  }
  .groups__indicators,
  .customStatus__indicators {
    padding-right: 5px;
  }
  margin-bottom: 18px;
  margin-left: -24px;
  margin-right: -24px;
  background: linear-gradient(90deg, #f7f9fa 0%, #fafafa 100%);
  border-bottom: 1px solid #ebeef1;

  padding: 8px 24px;
  > h3 {
    width: 260px;
    font-size: 14px;
    color: $black;
  }
  .btn-reset {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    padding: 0 5px;
    height: 23px;
    @include font-regular(0.85rem, "normal");
    color: $black;
    svg {
      color: $black;
      font-size: 1.2rem;
    }
    &:focus {
      outline: none;
    }
  }
  span {
    color: $black;
  }
  a {
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Dark Mode
[data-theme="dark"] {
  .rbc-calendar {
    color: $white;
    @include font-regular(14px, normal);
  }

  .rbc-toolbar {
    button {
      color: $white;
      &.rbc-active {
        color: $black;
      }
    }
  }

  .rbc-header {
    background-color: #2d3848;
    text-align: left;
    padding: 14px 14px 14px 6px;
    border-bottom: none;
    span {
      color: $almost-white;
    }
    &:nth-child(n + 1) {
      border-left: none;
    }
  }

  .rbc-off-range {
    /*text-decoration: line-through;*/
  }

  .rbc-date-cell {
    text-align: left;
    padding-left: 8px;
    padding-top: 8px;
    a {
      color: $almost-white !important;
    }
  }

  .rbc-off-range-bg {
    background-color: #2c303c;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAALElEQVQYV2NkwA2MGXHIGTMwMJzFJgmWAGlCl4RLoEuiSCBLYkjAJLFKgCQBILoGO5hdyysAAAAASUVORK5CYII=)
      repeat;
  }

  .rbc-today {
    background-color: #2d3848;
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #41454f;
  }

  .rbc-row-bg {
    // border: 1px solid #41454f;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #41454f;
  }

  .rbc-month-view {
    border: none !important;
    background-color: #2c303c;
    border-radius: 15px !important;
  }

  .rbc-event {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #363a45;
    padding: 6px;
    border-radius: 2px;
    &:hover {
      background-color: $dark-hover;
    }
    .event-container {
      display: block;
    }
    .event-title {
      @include font-regular(14px, normal);
      color: $white;
      padding-top: 6px;
    }
    .event-location {
      @include font-regular(11px, normal);
      color: #9d9fa1;
    }
    .event-status {
      height: 5px;
      width: 5px;
      background-color: #bbb;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
      &.Confirmed {
        background-color: $green;
      }
      &.Cancelled {
        background-color: $error-color;
      }
      &[class*="Tentative"] {
        background-color: $yellow;
      }
      &.Postponed {
        background-color: $purple;
      }
    }
    .event-icons {
      margin-top: 3px;
      .icon-section {
        svg {
          width: 13px;
          margin-right: 3px;
        }
      }
    }
  }

  .rbc-show-more {
    color: $white;
    text-align: center;
    margin-top: 8px;
    &:hover,
    &:active,
    &:visited {
      color: $primary-button-bg;
    }
  }

  .customToolbar {
    background-color: transparent;

    h3 {
      width: 260px;
      font-size: 16px;
      color: $almost-white;
    }
    button {
      svg {
        color: $almost-white;
      }
      &:focus {
        outline: none;
      }
    }
    span {
      color: $white;
    }
    a {
      color: $white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
