.hotelItem {
  &__root {
    &.ground {
      padding: 5px 15px;
    }
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    padding: 6px 6px 6px 15px;
    border-bottom: 1px solid $second-grey;
    transition: background-color 400ms ease-in;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: calc(100%);
      background-color: $primary-button-bg;
      transition: width 200ms ease-in;
    }
    &.active {
      &:before {
        width: 5px;
      }
      background-color: $hover-grey;
    }
    &:hover {
      svg {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__label {
    padding: 2px 12px;
    border-radius: 5px;
    background-color: rgba(64, 132, 191, 0.7);
    @include font-regular($size: 11px, $lineHeight: "normal");
    color: $almost-white;
    display: inline-flex;
    margin: 0 0 4px 0;
  }

  &__image {
    width: 60px;
    height: 60px;
    @include flexbox;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-grey;
    background-color: $white;
    border-radius: 4px;
    img {
      object-fit: cover;
      width: 60px;
      max-height: 100%;
      margin: 0 auto;
    }
  }

  .popup-content {
    z-index: 555;
  }

  &__details {
    @include flexbox;
    align-items: center;
    position: relative;

    h6 {
      /*@include font-medium($size: 14px, $lineHeight: "normal");*/
      color: $black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 3px;
      @include flexbox;
      align-items: center;
      span {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 0;
        z-index: 5;
      }
      svg {
        font-size: 1em;
        margin-left: 4px;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        &:hover {
          color: $orange;
        }
      }
    }
    p {
      @include font-regular($size: 12px, $lineHeight: "normal");

      color: #606060;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__staffs {
    max-width: 100px;
    position: relative;
    margin-right: 12px;
  }

  &__right {
    @include flexbox;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }

  &__actions {
    cursor: pointer;
    width: 25px;
    position: relative;
  }

  &__menu {
    border-radius: 2px;
  }

  &__menu-item {
    @include font-regular($size: 12px, $lineHeight: "normal");
    padding: 15px 20px;
    border-bottom: 1px solid #3d4049;
    color: $white;
    &.delete {
      color: $red;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  &__more {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    width: 25px;
  }
}

.hotelMenu {
  &__root {
    position: relative;
    top: auto;
    left: auto;
  }
}

.hotelMember {
  &__check {
    svg {
      color: $font-dark-grey;
    }
  }
}

.hotel-check {
  padding: 6.5px 0;
  label {
    @include font-regular($size: 14px, $lineHeight: "normal");
    color: rgba($color: $white, $alpha: 1);
    cursor: pointer;
  }
  input[type="checkbox"] {
    font: 400 15px Arial;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: lightgray;
    height: 15px;
    width: 15px;
    border: none;
    outline: 0;
    cursor: pointer;
    &:checked:after {
      display: block;
    }
    &:checked {
      background: $primary-button-bg;
    }
    &:after {
      content: "";
      position: relative;
      left: 40%;
      top: 20%;
      width: 26%;
      height: 40%;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: none;
    }
  }
}

.selectableHotel {
  &__root {
    @include flexbox;
    align-items: center;
    padding: 10px 18px;
  }
  &__toggler {
    width: 45px;
    cursor: pointer;
    svg {
      color: rgba($color: $black, $alpha: 0.7);
    }
  }
  &__details {
    h6 {
      @include font-medium(14px, "normal");
      color: $black;
    }
    p {
      @include font-light(11px, "normal");
      color: rgba($color: $black, $alpha: 0.7);
    }
  }
}

[data-theme="dark"] {
  .hotel-check {
    input[type="checkbox"] {
      background-color: #27364b;
      &:checked {
        background: #366794;
      }
    }
  }

  .selectableHotel {
    &__toggler {
      width: 45px;
      cursor: pointer;
      svg {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }
    &__details {
      h6 {
        @include font-medium(14px, "normal");
        color: $white;
      }
      p {
        @include font-light(11px, "normal");
        color: rgba($color: $white, $alpha: 0.7);
      }
    }
  }

  .hotelMember {
    &__check {
      svg {
        color: #696969;
      }
    }
  }
  .hotelItem {
    &__image {
      border: 1px solid #30343f;
      background-color: #28303f;
    }
    &__root {
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
      &:hover,
      &.active {
        background-color: $dark-hover;
      }
      svg {
        color: $white;
      }
    }
    &__details {
      h6 {
        color: $white;
      }
      p {
        color: #9d9fa1;
      }
    }
  }
}
