form.form-hotel {
  padding: 0;
  padding-top: 0;
  .form-group {
    .react-datepicker-popper {
      transform: translate3d(4px, 56px, 0px) !important;
    }
  }
}

.hotelForm-clear {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  opacity: 0.2;
  cursor: pointer;
  @include flexbox;
  svg {
    color: $red;
    font-size: 1rem;
  }
  &:hover {
    opacity: 1;
  }
}

.rc-time-picker-panel {
  top: 752px !important;
}

.rc-time-picker-panel-select {
  ul {
    position: relative;
    top: 0;
    left: 0;
    background-color: inherit;
  }
  ul li {
    color: inherit;
  }
}

.rc-time-picker {
  width: 100%;
}

.rc-time-picker-panel-inner {
  height: 179px;
  box-shadow: none !important;
  background-color: $page-bg !important;
  border: none !important;
  .rc-time-picker-panel-select {
    width: 100%;
    max-height: 108px;
  }
  .rc-time-picker-panel-input-wrap {
    background-color: #21242f !important;
    @include font-regular($size: 14px, $lineHeight: "normal");
    color: rgba($color: $white, $alpha: 1) !important;
    padding: 0 !important;
    border: none;
  }
  input {
    background-color: transparent;

    color: $white !important;
  }
  li {
    @include font-regular($size: 14px, $lineHeight: "normal");
    color: rgba($color: $white, $alpha: 1) !important;
  }
  li.rc-time-picker-panel-select-option-selected {
    background: $link-color !important;
  }
}
.rc-time-picker-panel-combobox {
  height: 144px;
}

.hotelTimePicker {
  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    border-radius: 0;
    font-size: 14px;
    color: $white;
    padding-bottom: 11px;
    padding-left: 0;
    &:hover {
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.1) !important;
    }
  }
  .rc-time-picker-clear {
    display: none;
  }
}

.rc-time-picker-input,
.rc-time-picker {
  &:focus,
  &:active,
  &:hover {
    border: none !important;
    outline: none;
  }
}

.time-picker-container {
  .drop-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
}

.hotel-options {
  @include flexbox;
  align-items: center;
  .btn {
    background-color: #2a2d37;

    font-size: 13px;
  }
}

.rc-tooltip-inner {
  border-radius: 1.2px !important;
  box-shadow: 0 1px 12px 0 rgba(26, 27, 41, 0.1) !important;
  background-color: #2b2f3c !important;
}

.rc-tooltip-arrow {
  display: none !important;
}

.hotelTooltip {
  padding: 13px 20px;
  &__title {
    @include font-bold($size: 15px, $lineHeight: "normal");
    color: $white;
  }
  &__details {
    @include font-regular($size: 13px, $lineHeight: "normal");
    color: rgba($color: $white, $alpha: 0.7);
  }
}

.hotelMember {
  &__root {
    padding: 2px 18px;
    width: 98%;
    margin: 4px auto;
    &.selected {
      background-color: #eff5fa;
    }
    p {
      color: $font-dark-grey;
    }
    .form-group {
      margin-bottom: 0;
      padding-bottom: 0;
      input.form-control.filled {
        font-size: 14px !important;
        text-shadow: none !important;
        background-color: transparent;
        color: $black !important;
        border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
        &:hover {
          background: transparent;
        }
        &::placeholder {
          color: $font-dark-grey !important;
        }
      }
    }
    .react-datepicker__input-container input {
      height: 40px;
    }
    .react-datepicker-wrapper {
      background-color: transparent;
      input {
        border-bottom: 1px solid rgba($color: $black, $alpha: 0.1) !important;
      }
    }
    .drop-icon {
      position: absolute;
      top: 50%;
      right: 0;
      width: 18px;
      height: auto;
      z-index: 1;
      transform: translateY(-50%);
    }
    .row {
      align-items: center;
    }

    .form-control.filled {
      font-size: 12px;
    }
  }
}

[data-theme="dark"] {
  .hotelMember__root .form-group input.form-control.filled {
    color: $almost-white !important;
  }
  .hotelMember__root {
    p {
      color: $almost-white;
    }
    &:nth-child(odd) {
      background-color: #252833;
    }
    &:nth-child(even) {
      background-color: #2e3240;
    }
    .react-datepicker-wrapper {
      background-color: transparent;
    }
    .form-group input {
      background-color: #2a2d37;
    }
    &.selected {
      background-color: #343d4c;
    }
  }
}
