.profileModal {
  &__picture {
    width: 200px;
  }

  &__section {
    margin: 0 -29px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
  }

  &__field {
    flex: 1;
    padding-left: 12px;
    padding-bottom: 18px;
    padding-top: 18px;
    text-align: left;
    span {
      @include font-regular(12px, 1);
      color: rgba($color: $black, $alpha: 0.3);
    }
    p {
      margin-top: 0;
      @include font-regular(14, 1);
      color: $black;
    }
  }
}

[data-theme="dark"] {
  .profileModal {
    &__picture {
      width: 200px;
    }

    &__section {
      margin: 0 -29px;
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    }

    &__field {
      flex: 1;
      padding-left: 12px;
      padding-bottom: 18px;
      padding-top: 18px;
      text-align: left;
      span {
        @include font-regular(12px, 1);
        color: rgba($color: $white, $alpha: 0.3);
      }
      p {
        margin-top: 0;
        @include font-regular(14, 1);
        color: $white;
      }
    }
  }
}
