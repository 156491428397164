/* reset password page css start here */
.signup-background {
  width: 100%;
  // background: url("../../images/login-bg.png") no-repeat;
  background-size: cover;
  background-position: center;



  .signup-content {
    width: 100%;
    height: 100%;
    padding: 15px;



  }
  .inside-content {
    padding-top: 0;
    @include flexbox;
    height: 100%;
    align-items: center;
    @media screen and (max-width: 767px) {
      display: flex;
      align-self: center;
      justify-content: center;
      flex-direction: column;
      width: 105%;
    }
    .row {
      @include flexbox;
      align-items: flex-start;
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    .carousel-left {
      @include flexbox;
      flex-direction: column;
      // margin-top: 146px;
      width: 100%;
      .signup-left {
        padding-top: 317px;
        @media screen and (max-width: 767px) {
          padding: 24px;
        }
        .carousel {
          width: 100%;
          height: 100%;
          // color: $white;
          .carousel-inner {
            .carousel-item {
              padding-top: 41px;
              transition: transform 0.8s ease-in-out;
              .carousel-text {
                h1 {
                  @include font-black(70px, normal);
                  // color: rgba($color: $white, $alpha: 0.2);
                  position: absolute;
                  top: 0;
                }
                h2 {
                  @include font-bold(25px, 1.36);
                  // color: $white;
                }
                p {
                  @include font-regular(15px, 1.6);
                  // color: rgba($color: $white, $alpha: 0.7);
                }
              }
            }
          }
          .carousel-indicators .active {
            opacity: 1;
          }
          .carousel-indicators {
            position: absolute;
            left: 0;
            bottom: -32px;
            justify-content: left;
            margin: 0;
            li {
              width: 5px;
              height: 5px;
              box-sizing: content-box;
              -ms-flex: 0 1 auto;
              flex: 0 1 auto;
              margin-right: 3px;
              margin-left: 3px;
              text-indent: -999px;
              cursor: pointer;
              background-color: #fff;
              background-clip: border-box;
              border-top: 0 solid transparent;
              border-bottom: 0 solid transparent;
              opacity: 0.5;
              transition: opacity 0.6s ease;
              border-radius: 4px;
              &.active {
                width: 20px;
                height: 5px;
                background-color: #fff;
              }
            }
            li:first-child {
              position: relative;
              box-sizing: content-box;
              -ms-flex: 0 1 auto;
              flex: 0 1 auto;
              width: 5px;
              height: 5px;
              background-color: #fff;
              margin-right: 3px;
              margin-left: 3px;
              text-indent: -999px;
              cursor: pointer;
              background-clip: border-box;
              border-top: 0 solid transparent;
              border-bottom: 0 solid transparent;
              opacity: 0.5;
              transition: opacity 0.6s ease;
              border-radius: 4px;
              &.active {
                width: 20px;
                height: 5px;
                opacity: 1;
                border-radius: 14px;
              }
              .first-scroll {
                width: 30px;
                height: 5px;
                background: white;
              }
              .round-scroll {
                width: 8px;
                height: 5px;
                background: white;
              }
            }
          }
        }
      }
    }
    .col-md-6 {
      &:last-child {
        @media screen and (max-width: 1100px) {
          margin-top: 50px;
        }
      }
      padding: 0;
      ul {
        width: 100%;
        height: auto;
        position: absolute;
        top: 54px;
        left: 0;
        z-index: 60000;
        background: rgba($color: $white, $alpha: 1);
        li {
          // color: rgba($color: $white, $alpha: 0.7);
          font-size: 14px;
          cursor: pointer;
        }
      }
      .form-group {
        .contact-number {
          @include flexbox;
          align-items: center;
          .form-control {
            padding-right: 0 !important;
            &:focus {
              border-bottom: 1px solid $link-color;
            }
          }
          .contact {
            width: 40px;
          }
          b {
            width: 1px;
            height: 20px;
            border-right: 1px solid rgba($color: $black, $alpha: 0.1);
          }
          input:disabled {
            background: transparent;
            // color: $white;
          }
          .line {
            width: 1.5px;
            height: 23px;
            background: rgba($color: $white, $alpha: 0.1);
          }
          .phone-no {
            .form-control {
              padding-left: 12px;
              &:focus {
                border-bottom: 1px solid $link-color;
              }
            }
          }
        }
      }
    }
    .signup-right {
      min-width: 550px;
      width: 550px;
      margin: 0 auto;
      background-color: #fff;
      box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
   
      @media screen and (max-width: 767px) {
        display: flex;
        align-self: center;
        flex-direction: column;
        justify-content: center;
        min-width: unset;
        width: 100%;
        
      }
      .signup-right-bg {
        width: 100%;
        height: 100%;
        padding: 25px;
        // background: #EDEEF2;//rgba($color: $white, $alpha: 0.2);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        @media screen and (max-width: 767px) {
          
          display: flex;
          align-self: center;
          flex-direction: column;
          margin-left: -15px;
      
          
        }
        .right-content {
          .head-section {
            @include flexbox;
            align-items: center;
            h1 {
              @include font-bold(20px, 1.3);
              padding-bottom: 3px;
              // color: $white;
            }
            p {
              @include font-regular(14px, 1.4);
              color: rgba($color: $black, $alpha: 0.7);
            }
          }
          /* form */
          form {
            padding-top: 24px;
            .form-group {
              position: relative;
              label {
                @include font-regular(12px, normal);
                // color: $white;
              }
              input {
                text-shadow: none;
                // color: $white;
                -webkit-text-fill-color: $black !important;
              }
              .form-control {
                @include font-regular(15px, normal);
                // color: $white;
                padding: 5px 10px;
                margin-top: 5px;
                border-radius: 5px;
                border: 1px solid rgba($color: $black, $alpha: 0.2);
                &:focus {
                  border-bottom: 1px solid $link-color;
                }
              }
            }
            .location-text {
              padding-bottom: 12px;
              line-height: 1;
              span {
                font-size: 12px;
                color: rgba($color: $black, $alpha: 0.5);
                margin-bottom: 0;
              }
            }
            .password-second {
              padding-top: 15px;
              margin-bottom: 15px;
            }
            .forgot-password {
              width: auto;
              position: absolute;
              top: 39px;
              right: 0;
            }
            .p-right {
              padding-right: 10px;
            }
            .p-left {
              padding-left: 10px;
            }
            .password-section {
              position: relative;
            }
          }
          /* fotgot password */
          .forgot-password {
            @include flexbox;
            justify-content: flex-end;
            width: 100%;
            p {
              color: $black;
              cursor: pointer;
            }
            img {
              cursor: pointer;
            }
          }
          /* remember signup section */
          .agree-section {
            @include flexbox;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .input-left {
              .radio {
                @include flexbox;
                align-items: center;
                input {
                  @include font-regular(25px, normal);
                  cursor: pointer;
                }
                label {
                  @include font-regular(14px, 1.5);
                  // color: $white;
                  padding-left: 10px;
                  span {
                    color: $link-color;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .submit-button {
            padding-top: 30px;
            button {
              @include font-medium(13px, normal);
            }
          }
        }
      }
      /* signup footer */
      .signup-footer {
        width: 100%;
        background: rgba($color: $white, $alpha: 0.2);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;

        .footer-inside {
          padding: 0px 10px 23px;
          .footer-content {
            @include flexbox;
            align-items: center;
            justify-content: center;
            p {
              @include font-regular(15px, normal);
              color: rgba($color: $black, $alpha: 0.7);
              cursor: pointer;
            }
            span {
              @include font-regular(15px, normal);
              color: $link-color;
              cursor: pointer;
              padding-left: 5px;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
