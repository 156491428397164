.privacy-bg {
  min-height: 100vh;
  height:100%;
  width: 100%;
  background: $page-bg;
  @media (max-width: $phone-landscape) {
    min-width: 0;
    width: auto;
  }
  .privacy-head {
    @include flexbox;
    justify-content: flex-end;
    width: 100%;
    padding: 20px 20px 0;
    .close-bg {
      @include flexbox;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 30px;
      height: 30px;
      background: rgba($color: $white, $alpha: 0.1);
      border-radius: 50px;
    }
  }
  .privacy-text {
    padding: 20px 40px 35px;
    h1 {
      @include font-bold(22px, 1.5);
      color: $white;
      margin-bottom: 10px;
    }
    p {
      @include font-regular(14px, 1.5);
      color: rgba($color: $white, $alpha: 0.8);
      padding-bottom: 30px;
    }
    h3 {
      @include font-medium(16px, 1.5);
      color: $white;
      margin-bottom: 10px;
    }
  }
}

.privacy-bg-light {
  min-height: 100vh;
  height:100%;
  width: 100%;
  background: $white;
  @media (max-width: $phone-landscape) {
    min-width: 0;
    width: auto;
  }
  .privacy-head {
    @include flexbox;
    justify-content: flex-end;
    width: 100%;
    padding: 20px 20px 0;
    .close-bg {
      @include flexbox;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 30px;
      height: 30px;
      background: rgba($color: $page-bg, $alpha: 0.1);
      border-radius: 50px;
    }
  }
  .privacy-text {
    padding: 20px 40px 35px;
    h1 {
      @include font-bold(22px, 1.5);
      color: $page-bg;
      margin-bottom: 10px;
    }
    p {
      @include font-regular(14px, 1.5);
      color: rgba($color: $page-bg, $alpha: 0.8);
      padding-bottom: 30px;
    }
    h3 {
      @include font-medium(16px, 1.5);
      color: $page-bg;
      margin-bottom: 10px;
    }
  }
}