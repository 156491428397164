// /* calendar dropdown start here */
.react-datepicker {
  border: none !important;
  padding: 2px;
  background: $white !important;
  box-shadow: 0 1px 12px 0 rgba(26, 27, 41, 0.1) !important;

  .react-datepicker__header {
    background-color: $white !important;
    border: none !important;
    color: $black;
  }
  .react-datepicker__day {
    color: $black;
    border-radius: 100% !important;
    width: 34px;
    height: 34px;
    line-height: 2.8;
    &:hover {
      background: $link-color !important;
      border-radius: 100% !important;
    }
  }
  .react-datepicker__current-month {
    @include font-regular(17px, normal);
    color: rgba($color: $black, $alpha: 0.8);
    padding-bottom: 20px;
  }
  .react-datepicker__day-name {
    @include font-regular(14px, normal);
    color: $black;
    width: 35px;
  }
}
.react-datepicker-wrapper {
  display: block;
  input[type="text"] {
    width: 100%;
    background: transparent;
    border: none;
    color: $black;
    outline: none;
    cursor: pointer;
  }
  input {
    font-size: 14px !important;
  }
}
.react-datepicker-ignore-onclickoutside {
  background: transparent;
  border: none;
  color: $black;
}
.react-datepicker__day--disabled {
  color: rgba($color: $black, $alpha: 0.4) !important;
}
.react-datepicker__time-list {
  top: 0;
  background-color: $white;
}
.react-datepicker__time-list-item {
  color: $black;
  &:hover {
    background-color: rgba($color: $primary-button-bg, $alpha: 0.3) !important;
  }
}

.react-datepicker__time-container {
  width: 105px !important;
}
// .react-datepicker-popper {
//   left: auto !important;
//   right: -10px;
//   margin: 0 !important;
//   top: -1px !important;
//   z-index: 40 !important;
// }
// .react-datepicker__day-name {
//   margin: 0 !important;
//   font-size: 11px !important;
// }
// .react-datepicker__day {
//   margin: 0 !important;
// }
.css-1hb7zxy-IndicatorsContainer {
  svg {
    width: 16px;
    color: $black;
  }
}
.css-tlfecz-indicatorContainer {
  padding: 0 !important;
}
.css-yk16xz-control {
  cursor: pointer !important;
}

[data-theme="dark"] {
  // /* calendar dropdown start here */
  .react-datepicker__day--disabled {
    color: rgba($color: $white, $alpha: 0.4) !important;
  }
  .react-datepicker__time-list {
    background-color: $black-bg;
  }
  .react-datepicker__time-list-item {
    color: $white;
    &:hover {
      background-color: $blue-hover !important;
    }
  }

  .react-datepicker__time-container {
    width: 105px !important;
  }
  .react-datepicker-time__header {
    color: $almost-white;
  }
  .react-datepicker {
    border: none !important;
    padding: 2px;
    background: #2b2f3c !important;
    .react-datepicker__header {
      background-color: #2b2f3c !important;
      border: none !important;
      color: $white;
    }
    .react-datepicker__day {
      color: $white;
      &:hover {
        background: $link-color !important;
      }
    }
    .react-datepicker__current-month {
      color: rgba($color: $white, $alpha: 0.5);
    }
    .react-datepicker__day-name {
      color: $white;
    }
  }
  .react-datepicker-wrapper {
    display: block;
    input[type="text"] {
      color: $white;
    }
    input {
      font-size: 14px !important;
    }
  }
  .react-datepicker-ignore-onclickoutside {
    background: transparent;
    border: none;
    color: $white;
  }
  .react-datepicker__day--disabled {
    color: rgba($color: $white, $alpha: 0.4) !important;
  }

  .css-1hb7zxy-IndicatorsContainer {
    svg {
      color: $white;
    }
  }
}
