.btn {
  text-transform: capitalize;
  @include font-medium(14px, 1);
  &.capital {
    text-transform: capitalize;
  }
  &.big {
    height: 44px;
    width: 159px;
    padding: 0px;
  }
  &.btn-block {
    width: 100%;
  }
  &.medium {
    width: 180px !important;
  }
  &.w-260 {
    width: 260px !important;
  }
  &.w-200 {
    width: 200px !important;
  }
  &.w-100 {
    width: 100px !important;
  }
  &.w-120 {
    width: 110px !important;
  }
  &.w-140 {
    width: 140px !important;
  }
  &.w-160 {
    width: 160px !important;
  }

  &.w-40 {
    width: 40px !important;
  }

  &.primary {
    border-radius: 5px;
    font-size: 14px;
    color: $white;
    box-shadow: none;
    border-color: transparent;
    background: $primary-button-bg;
    color: $white;
    width: 100%;
    text-transform: capitalize;
    &:hover {
      color: $white;
      border-color: transparent;
      background: darken($color: $primary-button-bg, $amount: 15);
    }
    &.disabled,
    &:disabled {
      opacity: 0.5;
    }
  }
  &.warning {
    background-color: $orange;
    &:hover {
      background-color: darken($color: $orange, $amount: 15);
    }
  }
  &.secondary {
    font-size: 15px;
    color: $white;
    box-shadow: none;
    border-color: transparent;
    background: $secondary-button-bg;
    color: $white;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      border-color: transparent;
    }
    &.disabled,
    &:disabled {
      opacity: 0.5;
    }
  }
  &.rounded {
    border-radius: 60px !important;
  }
}
.btn.active {
  color: $light-grey !important;
  background: $primary-button-bg !important;
}
.btn-link {
  color: $white;
}
.btn:hover {
  color: $light-grey !important;
  background: $primary-button-bg !important;
}

.btn-action {
  background: $action-green !important;
}

.btn-danger {
  background-color: $red;
}

.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

[data-theme="dark"] {
  .btn {
    &.primary {
      background-color: rgba(64, 132, 191, 0.7);
      &:hover {
        color: $white;
        border-color: transparent;
        background-color: darken($color: $primary-button-bg, $amount: 15);
      }
    }
  }
}
