// @font-face {
//   font-family: 'infront icon';
//   src:  url('../icon-font/infront-icons.eot?80we03');
//   src:  url('../icon-font/infront-icons.eot?80we03#iefix') format('embedded-opentype'),
//     url('../icon-font/infront-icons.ttf?80we03') format('truetype'),
//     url('../icon-font/infront-icons.woff?80we03') format('woff'),
//     url('../icon-font/infront-icons.svg?80we03#infront-icons') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'infront icon' !important;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-actions:before {
  content: "\e900";
  color: #131b42;
}
.icon-add:before {
  content: "\e901";
  color: #e3e4e8;
}
.icon-cert-badge .path1:before {
  content: "\e902";
  color: rgb(0, 0, 0);
}
.icon-cert-badge .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(73, 193, 188);
}
.icon-clients:before {
  content: "\e904";
  color: #dcdde3;
}
.icon-close-modal:before {
  content: "\e905";
}
.icon-collapse-arrow-down:before {
  content: "\e906";
  color: #131b42;
}
.icon-collapse-arrow-up:before {
  content: "\e907";
  color: #131b42;
}
.icon-edit-icon:before {
  content: "\e908";
  color: #63676c;
}
.icon-expand:before {
  content: "\e909";
  color: #fff;
}
.icon-help-copy:before {
  content: "\e90a";
  color: #dcdde3;
}
.icon-log-out:before {
  content: "\e90b";
  color: #dcdde3;
}
.icon-mail-icon-copy-2 .path1:before {
  content: "\e90c";
  color: rgb(16, 19, 51);
}
.icon-mail-icon-copy-2 .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mail-icon-copy-2 .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mail-icon .path1:before {
  content: "\e90f";
  color: rgb(16, 19, 51);
}
.icon-mail-icon .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mail-icon .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-more-icon:before {
  content: "\e912";
  color: #d0d1d7;
}
.icon-my-compliance:before {
  content: "\e913";
  color: #dcdde3;
}
.icon-notification-icon:before {
  content: "\e914";
  color: #dcdde3;
}
.icon-remove-icon:before {
  content: "\e915";
  color: #a3a7ab;
}
.icon-search:before {
  content: "\e916";
  color: #131b42;
}
.icon-settings-copy:before {
  content: "\e917";
  color: #dcdde3;
}
.icon-sort-ascending:before {
  content: "\e918";
  color: #d0d1d7;
}
.icon-sort-descending:before {
  content: "\e919";
  color: #d0d1d7;
}
.icon-tick:before {
  content: "\e91a";
  color: #131b42;
}
.icon-vendors:before {
  content: "\e91b";
  color: #dcdde3;
}
