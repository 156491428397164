.css-2b097c-container {
  padding: 0;
  border-radius: 0;
  border: none;
  .css-yk16xz-control {
    border: none;
    border-bottom: 0px solid rgba($color: $black, $alpha: 0.1);
    border-radius: 0;
    background-color: transparent;
    color: $black;
  }
  .css-1wa3eu0-placeholder {
    color: $black;
    width: 100%;
  }
  .css-1n7v3ny-option,
  .css-yt9ioa-option {
    background-color: transparent;
    color: $black;
    &:hover {
      background-color: rgba($color: $primary-button-bg, $alpha: 0.3);
    }
  }
}

[data-theme="dark"] {
  .css-2b097c-container {
    .css-yk16xz-control {
      color: $white;
    }
    .css-1uccc91-singleValue {
      color: $almost-white;
    }
    .css-1wa3eu0-placeholder {
      color: #696969;
      width: 100%;
    }
    .css-1n7v3ny-option,
    .css-yt9ioa-option {
      color: $white;
    }
  }
}
