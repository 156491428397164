/* visible by page css start here */
.visible-page {
  min-width: 500px;
  max-width: 500px;
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  background: $white;
  .form-control.filled:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &.flightMembers {
    min-width: 630px;
    max-width: 630px;
  }
  /* right section start here */
  .visible-expand-section {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: $solid_background !important;

    .visible-head {
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      padding: 13.5px 30px;
      background: $sidebar-grey;
      width: 100%;
      .back-icon {
        svg,
        img {
          color: $font-dark-grey;
          cursor: pointer;
        }
      }
      .text-left {
        width: 100%;
        padding-left: 15px;
        h1 {
          @include font-bold(15px, normal);
          color: $black;
        }
        .flight-text {
          @include flexbox;
          align-items: center;
          width: 100%;
          p {
            @include font-regular(13px, 1.69);
            color: $font-dark-grey;
          }
          span {
            border: 1px solid rgba($color: $white, $alpha: 0.15);
            margin: 0 5px;
            width: 10px;
            border-style: dashed;
          }
        }
      }
      .close-icon d-none {
        @include flexbox;
        justify-content: flex-end;
        width: auto;
        .close-round {
          @include flexbox;
          align-items: center;
          justify-content: flex-end;
          padding: 7px;
          background: #484954;
          border-radius: 100%;
          cursor: pointer;
        }
      }
    }
    .visible-main {
      width: 100%;
      height: auto;
      padding: 20px 30px 0;
      background: $solid_background !important;
      .visible-search {
        @include flexbox;
        align-items: center;
        justify-content: space-between;
        .input-section {
          width: 100%;
          margin-right: 10px;
          input {
            width: 100%;
            height: 40px;
            background: #2a2d37;
          }
          .form-control {
            padding: 6px 20px;
            border: none;
          }
        }
        .select-button {
          width: 130px;
          button {
            height: 40px;
            white-space: pre;
            background: #2a2d37;
          }
        }
      }
    }
    .admin-container {
      padding-bottom: 10px;
      background: $solid_background !important;

      .visible-detail {
        @include flexbox;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: $black;
        padding: 10px 30px 10px;
        background-color: #f7f8f8;
        margin-bottom: 10px;
        .head-section {
          p {
            @include font-regular(12px, normal);
            cursor: pointer;
            color: rgba($color: $black, $alpha: 0.5);
          }
        }
      }
    }
    .submit-button {
      background: $solid_background !important;
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      padding: 10px 30px;
      margin: 0;
      width: 100%;
    }
    .visible-info-main {
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      width: 97%;
      margin: 3px auto;
      padding: 8px 30px;
      &.selected {
        background-color: #eff5fa;
      }
      .admin-detail {
        @include flexbox;
        align-items: center;
        img,
        svg {
          color: $font-dark-grey;
          cursor: pointer;
        }
        .admin-img {
          width: 60px;
          overflow: hidden;
          padding-left: 20px;
        }
        .admin-info {
          @include flexbox;
          flex-direction: column;
          padding-left: 10px;
          &__more {
            &.disabled {
              opacity: 0.3;
              pointer-events: none;
              cursor: not-allowed;
            }
            @include font-regular(12px, "normal");
            color: rgba($color: $black, $alpha: 0.8);
            text-decoration: underline;
            margin-left: 12px;
            &:hover {
              color: $primary-button-bg;
              text-decoration: none;
            }
          }
          p {
            @include font-regular(15px, normal);
            color: rgba($color: $black, $alpha: 0.8);
            padding-bottom: 2px;
            .line {
              width: 1px;
              height: 10px;
              margin: 0 10px;
              border: 1px solid rgba($color: $black, $alpha: 0.2);
            }
          }
          .email {
            @include font-regular(12px, normal);
            color: rgba($color: $black, $alpha: 0.7);
          }

          .popup-content {
            padding: 0 !important;
            margin: 0 !important;
            left: auto !important;
            right: 0 !important;
            width: 400px !important;
          }

          .recordLocator {
            &__form {
              padding: 0 0;
              small {
                @include font-light(10px, normal);
                color: $white;
              }
              input {
                color: $black;
                border-bottom: 1px solid rgba($color: $black, $alpha: 0.85);
              }
              input::placeholder {
                color: rgba($color: $black, $alpha: 0.65);
                @include font-light(14px, normal);
              }
            }
            &__field {
              flex: 1;
              padding: 14px 6px;
            }
            &__header {
              padding: 2px 6px;
              background-color: #272a36;
              p {
                color: $white;
                @include font-light(13px, normal);
              }
            }
          }
        }
      }
    }
    .active {
      background: rgba($color: $blue, $alpha: 0.1);
    }
  }
}

[data-theme="dark"] {
  .visible-page .form-control.filled {
    border: 1px solid $almost-white;
    color: rgba($color: $white, $alpha: 0.7);
    &::placeholder {
      color: rgba($color: $white, $alpha: 0.4) !important;
    }
  }

  .visible-page
    .visible-expand-section
    .visible-info-main
    .admin-detail
    .admin-info {
    .form-control.flight {
      &::placeholder {
        color: rgba($color: $white, $alpha: 0.4) !important;
      }
      color: $white;
      border-bottom: 1px solid #373c49;
      max-width: 110px;
    }
  }
  .visible-page
    .visible-expand-section
    .visible-info-main
    .admin-detail
    .admin-info__more {
    color: rgba($color: $white, $alpha: 0.8);
  }
  .visible-page
    .visible-expand-section
    .visible-info-main
    .admin-detail
    .admin-info
    p {
    color: $white;
  }
  .visible-page
    .visible-expand-section
    .admin-container
    .visible-detail
    .head-section {
    p {
      color: $white;
    }
  }
  .visible-info-main {
    p {
      color: rgba($color: $white, $alpha: 0.8);
    }
    .email {
      color: rgba($color: $white, $alpha: 0.7);
    }
  }
  .visible-page .visible-expand-section .visible-head {
    background-color: $dark-modal-header;
    .text-left {
      h1 {
        color: $almost-white;
      }
      p {
        color: $font-dark-grey;
      }
    }
  }
 
}
