/* Custom variables */
@import "./base/fonts";
@import "./base/variables";
@import "./base/fonts-icon";
@import "./base/button";
@import "./base/form";
@import "./base/base";
@import "./base/modal";
@import "./base/pages";
@import "react-big-calendar/lib/sass/styles";
@import "./loader-page/loader";
@import "./login-page/login";
@import "./reset-password-page/reset-password";
@import "./reset-password-page/reset-password-success";
@import "./forgot-password-page/forgot-password";
@import "./signup-page/signup";
@import "./email-sent-page/email-sent";
@import "./loader-page/loader";
@import "./navbar/navbar";
@import "./organization/createOrganization";
@import "./calendar/calendar-list";
@import "./calendar/calendar-dropdown";
@import "./event/event-navigation-header";
@import "./flight/add-flight";
@import "./flight/one-way-flight";
@import "./flight/round-trip-flight";
@import "./itineraries/itineraries-form";
@import "./itineraries/itineraries-item";
@import "./hotel/hotel-items";
@import "./common/avatars";
@import "./hotel/add-hotel-pop";
@import "./contact/contact-list";
@import "./attachments/attachment-list";
@import "./hotel/hotel-detail";
@import "./common/delete-modal";
@import "./common/mailing-list";
@import "./contact/contact-detail";
@import "./hotel/hotel-email";
@import "./ground/add-ground";
@import "./ground/ground-detail";
@import "./time-dropdown/time-dropdown";
@import "./terms-&-condition/privacy-policy";
@import "./terms-&-condition/terms&condition";
@import "./event/visible-by";
@import "./collab/collab";
@import "./settings/settings";
@import "./base/generics";
@import "./event/add-event-pop";
@import "./guestList/questList";
@import "./dashboard/dashboard";
@import "./notifications/notifications";
@import "./todo/todo";
@import "./event/event-detail-page";
@import "./activities/activities";
@import "./big-calendar/big-calendar";
@import "./profile/profile";
@import "./event/event";
@import "./base/rbt";
@import "./base/select";
@import "./flight/flight-detail";

body {
  background-color: #f5f6fa;
  @media screen and (max-width: $phone-landscape) {
    line-height: unset;
  }
}

//  e-lib

// .e-toolbar-items {
//  display: flex !important;
//  flex-direction: column !important;
// }

// .e-toolbar{
//   overflow: unset !important;
// }
.image-loader {
  width: 64px !important;
  height: 64px !important;
  position: relative !important;
  background: #bedeff !important;
  border-radius: 4px !important;
  overflow: hidden !important;
}
.image-loader:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  transform: rotate(45deg) translate(30%, 40%);
  background: #7fd6fe !important;
  box-shadow: 32px -34px 0 5px #01aeff;
  animation: slide 2s infinite ease-in-out alternate;
}
.image-loader:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #01aeff;
  transform: rotate(0deg);
  transform-origin: 35px 145px;
  animation: rotate 2s infinite ease-in-out;
}

@keyframes slide {
  0%,
  100% {
    bottom: -35px;
  }
  25%,
  75% {
    bottom: -2px;
  }
  20%,
  80% {
    bottom: 2px;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(-15deg);
  }
  25%,
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(25deg);
  }
}

.e-control ul {
  position: unset !important;
}

.e-documenteditorcontainer {
  height: 95% !important;
}

.container_editor_viewerContainer {
  height: 95% !important;
}

.e-documenteditor-optionspane {
  height: 95% !important;
}

.e-spreadsheet {
  height: 95% !important;
}

.e-documents-container {
  height: 100vh !important;
}

.main-container {
  min-height: 100vh;
  display: flex;
  width: 100%;
}

/* carousel prev or next icon */
// .carousel-control-prev {
//   display: none;
// }
// .carousel-control-next {
//   display: none;
// }

/* safari key remove icon */
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.control-pane {
  height: 100% !important;
}

.control-section {
  height: 100% !important;
}
.rte-control-section {
  height: 100% !important;
}
.e-richtexteditor {
  height: 100% !important;
  background: none !important;
}

.e-rte-content {
  height: 100% !important;
  background: none !important;
  border: none !important;
}

.e-content {
  height: 100% !important;
  background: none !important;
  border: none !important;
}

.e-content > p {
  height: 100% !important;
}

/* global row */
.row {
  margin: 0;
  width: 100%;
}

.tox-sidebar-wrap {
  background-color: #f5f6fa !important;
}

.tox-edit-area {
  max-width: 900px !important;
  margin: 0 auto !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0px 30px transparent;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #01aeff !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  display: flex !important;
  color: #000000 !important;
  justify-content: center !important;
  align-items: center !important;
  color: currentColor !important;
  font-weight: 900 !important;
  transition: all 0.15s ease-in-out !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  transform: scale(1.1) !important;
}

.swiper-button-next:active,
.swiper-button-prev:active {
  transform: scale(1) !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 700;
  color: #fff !important;
}

.new-document-card {
  overflow: hidden;
  border-radius: 12px !important;
  width: 200px;
  cursor: pointer;
  transition: all 0.3s;
}

.template-card-selected {
  border: 2px solid #01aeff !important;
}

.template-card-btn-container {
  display: flex;
  z-index: 100;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  transform: translateY(-115%);
  background-color: rgba(0, 0, 0, 0.19);
  transition: all 0.3s;
  padding: 5px;
}

.card-btn-delete {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  color: red;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.e-rte-table td,
.e-rte-table th {
  border: dashed 0.1px rgba(0, 0, 0, 0.1) !important;
}

.no-event-selected {
  z-index: 101;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-document-card:hover > .template-card-btn-container {
  transform: translate(0);
}

.new-document-card:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
  transform: scale(1.06);
}

.e-rte-toolbar {
  position: relative !important;
  z-index: 0 !important;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.new-document-card:active {
  transform: scale(1);
}

.quick-access-files-container {
  display: flex;
  height: calc(50dvh - 200px);
  overflow-y: auto;

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
  }
}

/* toast css */
.Toastify {
  @include flexbox;
  justify-content: flex-end;
  width: 100%;
  .Toastify__toast-container {
    width: auto;
    z-index: 99999999;
  }
  .Toastify__toast-container--top-center {
    top: 20px;
    @media screen and (max-width: $phone-landscape) {
      width: 310px;
      margin: 0 auto;
      left: 15px;
      right: 15px;
      top: 10px;
    }
  }
  /* toast error */
  .Toastify__toast--error {
    background-color: $red;
  }
  .Toastify__toast {
    min-height: 50px;
    height: 50px;
    padding: 15px;
    border-radius: 2px;
    z-index: 999999999;
    &.Toastify__toast--success {
      background-color: #03ac13;
    }
  }
  .Toastify__toast-body {
    @include font-regular(14px, normal);
    color: $white;
  }
}

/* Round */
.green-round {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 30px;
  left: -12px;
  border-radius: 20px;
  background: $green;
}
.red-round {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 30px;
  left: -12px;
  border-radius: 20px;
  background: $red;
}
.yellow-round {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 30px;
  left: -12px;
  border-radius: 20px;
  background: $yellow;
}
.purple-round {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 30px;
  left: -12px;
  border-radius: 20px;
  background: $purple;
}
.blue-round {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 30px;
  left: -12px;
  border-radius: 20px;
  background: $blue;
}

.draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  height: 57px;
}

.notification-btn {
  &.btn {
    text-transform: none !important;
  }
}

.draggable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  background: #01aeff;
  color: white;
}

[data-theme="dark"] {
  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: inset 0 0 0px 30px transparent;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $white !important;
  }
}

/* STYLE DEFINED BY STARLIN TO CLEAN CONTENT*/

.title {
  @include font-medium($size: 14px, $lineHeight: "normal");
  color: $black;
  padding-top: 2px;
}
.subTitle,
.userTeam__user span {
  @include font-regular(12px, normal);
  color: rgba($color: $subTitleGray, $alpha: 1) !important;
}
.plnify.card,
.plnify .card-header,
.plnify .card-body,
.plnify .card-footer {
  background: #fff;
  border: 0px !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.calendar-view {
  height: 530px;
  padding: 0 10px !important;
  margin: 0 !important;
  box-shadow: none;
}

.calendar-view > .rbc-calendar > .customToolbar {
  margin: 0 !important;
}
.events-view {
  height: calc(36vh - 60px);
  padding: 0 !important;
}

.events-view > .calendar-bottom {
  height: 100% !important;

  overflow-y: auto;
}

.swal2-select {
  width: 90% !important;
  border: "1px solid #ced4da" !important;
  border-radius: 5px;
  outline: none !important;
  padding: 5px !important;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.3em !important;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

// .file-grid-item{
//   background-color: rgba(255, 255, 255, 1);
//   box-shadow: 2px 0px 5px 2px rgba($color: #000000, $alpha: 0.2);
//   border-radius: 15px;
//   padding: 20px;
//   font-size: 30px;
//   text-align: center;

// }

// .file-grid-container{
//     display: grid;
//     gap: 10px 10px;
//     grid-template-columns: auto auto auto;

//     margin: 0 auto;
//     padding: 10px;
// }

.file-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  width: 100%;
}

.file-grid-item {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 0px 5px 2px rgba($color: #000000, $alpha: 0.2);
  border-radius: 15px;
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.export-style-menu {
  position: relative !important;
  transition: all 0.3s ease-in-out;
}

.export-style-menu-content {
  display: flex !important;
  opacity: 0 !important;
  transform: translateY(100%) !important;
  height: 0;
  border: none !important;
  transition: all 0.3s ease-in-out;
}

.export-child-item {
  transition: all 0.3s ease-in-out;
}

.general-save-btn {
  transition: all 0.3s ease-in-out;
}

.general-save-btn.active {
  opacity: 1;
  height: unset;
  transform: translateY(0);
}

.general-save-btn.inactive {
  opacity: 0;
  height: 0;
  transform: translateY(-20%);
  transition: all 0.1s ease-in-out;
}

.collap-arrow {
  transition: all 0.3s ease-in-out;
}

.collap-arrow.active {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}

.collap-arrow.inactive {
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}

.export-item-conatiner.active {
  height: unset;
  position: relative;
  opacity: 1;

  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.export-item-conatiner.inactive {
  height: 0;
  opacity: 0;
  z-index: -1;
  transform: translateY(-25%);
  transition: all 0.3s ease-in-out;
}

.export-child-item.active {
  height: 100%;
  opacity: 1;
  position: relative;
  z-index: 0;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.export-child-item.inactive {
  height: 0;
  opacity: 0;
  z-index: -1;
  transform: translateY(-10%);
  transition: all 0.3s ease-in-out;
}

.export-style-menu:hover {
  border: 1px solid #01aeff !important;
}
.export-style-menu:hover .export-style-menu-content {
  display: flex !important;
  opacity: 1 !important;
  height: 30px;
  border: none !important;
  transform: translateY(0) !important;
}

@media screen and (min-width: 1200px) {
  .calendar-view {
    height: calc(100vh - 100px);
  }
}

.plnify.card h1 {
  font-family: "opensansbold", "opensansregular", sans-serif !important;
  font-size: 20px !important;
  font-weight: normal;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 3px;
}
.invalid {
  font-size: 10px;
  color: orangered;
}

.marker i {
  position: absolute;
  left: 10px;
  top: 12px;
  font-size: 16px;
}
.marker {
  text-align: center;
  position: relative;
}

.mapContainer > div {
  border-radius: 15px;
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  height: calc(95vh - 45px);
}

.mapContainer {
  height: 50%;
  width: 100%;
}

.mapEventContainer {
  width: 100%;
  height: 50%;
}

.move__event__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100vh;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
}

.move__event__select {
  height: calc(100% - 110px);
}

.skeleton-loading {
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 4px;
  margin: 5px 0;
}

.skeleton-image {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #ddd;
  margin-right: 5px;
}

.skeleton-text {
  width: 50%;
  height: 100%;
  justify-content: space-around;
}

.skeleton-header {
  height: 16px;
  width: 50%;
  border-radius: 25px;
  margin-bottom: 5px;
  background-color: #ddd;
}

.skeleton-description {
  height: 16px;
  width: 100%;
  border-radius: 25px;
  background-color: #ddd;
}

.cursor-default {
  cursor: default;
}

.anchor-class {
  cursor: pointer;
  color: #01aeff;
  font-size: 12px;
}

.anchor-class:hover {
  color: #2600ff;
}

.anchor-class:active {
  font-weight: bold;
}

.skeleton-loading {
  animation: loadingAnimation 1.5s infinite;
}

@keyframes loadingAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@media screen and (min-width: 1124px) {
  .move__event__container {
    width: 40vw;
  }

  .calendarContainer {
    display: flex;
    flex-direction: row;
    height: calc(96vh - 45px);
  }

  .mapContainer {
    height: 100%;
    width: 70%;
  }

  .mapEventContainer {
    width: 25%;
    height: 100%;
    padding: 0 15px;
  }
}

.accordion-button {
  width: 100% !important;
  height: 100%;
  padding: 2px;
  margin: 0;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
}

.accordion-button:hover {
  background-color: #01aeff;
}

.isActiveMenu {
  transform: rotate(180deg);
  animation: 1s ease-in-out;
}

h2.accordion-header {
  line-height: 0;
  margin: 0;
  margin-top: 0.65px;
  height: 100%;
}

.accordion-button:not(.collapsed) {
  color: #212529;
}

.event-active {
  background-color: #f0f5f9 !important;
  color: black !important;
  font-weight: bold !important;
}

.collab-container {
  width: 100% !important;
  height: 100vh !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.event-sidebar-container {
  display: none;
  transition: all 0.3s ease-out;
  &_expanded {
    position: unset;
    width: 20%;
    transition: all 0.5s;
    animation: showSideBar 0.2s ease-in;
  }
}

@keyframes showSideBar {
  0% {
    transform: translate(-100%);
  }
  100% {
    display: none;
  }
}

.clean-ul {
  width: unset !important;
  height: unset !important;
  position: unset !important;
  top: unset !important;
  left: unset !important;
  z-index: unset !important;
  li {
    color: rgba($color: $black, $alpha: 0.7);
    padding: 10px 20px;
    font-size: 14px;
    line-height: 1.1;
    cursor: pointer;
    &:hover {
      background: rgba($color: $black, $alpha: 0.2);
    }
  }
}

.calendar-drop-down {
  background-color: #213954;

  &__options {
    border-bottom: 1px solid #1c2f45 !important ;
    &:hover {
      color: #01aeff;
      cursor: pointer;
    }

    &_isActive {
      color: #01aeff;
    }
  }
}

.event-sidebar-btn {
  width: 40px;
  height: 40px;
  border-radius: 0 25px 25px 0px;
  transition: all 0.2s;
}

.event-sidebar-btn:hover {
  width: 60px;
}

.collab-content-container {
  height: 95%;
  width: 60%;
  margin: auto;

  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 100vh;
    margin: 0 !important;
    font-size: 0.9rem !important;
  }
}

.collab-comments {
  background-color: #f2f2f2;
  height: 100px;
  top: -100px;
  left: 0;
}

.collab-comments.open {
  background-color: #f2f2f2;
  height: 500px;
  top: -500px;
  left: 0;
}

.collab-comments-btn {
  width: 35px;
  height: 8px;
  cursor: pointer;
  background-color: #f2f2f2;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}

.email-sidebar {
  &_expanded {
    width: 300px !important;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 1200px) {
      position: fixed;
      z-index: 1000;
    }
  }

  &_slim {
    position: relative;
    left: -50%;
    width: 0px !important;
    transition: all 0.2s ease-in-out;
  }
}

.email-edit-container {
  &_expanded {
    width: calc(100vw - 800px) !important;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 1200px) {
      width: calc(100vw - 500px) !important;
    }

    @media screen and (max-width: 768px) {
      width: 100vw !important;
    }
  }

  &_full-expanded {
    width: calc(100vw - 500px) !important;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 1200px) {
      width: calc(100vw) !important;
    }

    @media screen and (max-width: 768px) {
      width: 100vw !important;
    }
  }

  &_slim {
    width: calc(100vw - 500px) !important;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 768px) {
      width: 100vw !important;
    }
  }
}

.bg-none {
  background-image: url(../../public/none.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 400%;
}

.toggle-switch {
  cursor: pointer;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 15px;
  display: flex;
  align-items: center;
  transition: all 0.3s;

  &.active {
    background-color: #01aeff;
  }
}

.toggle-title {
  font-family: "opensansmedium", "opensansregular", sans-serif;
  font-size: 14px !important;
  margin-left: 2px;
  font-weight: lighter;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.toggle-switch-inner {
  width: 18px;
  height: 18px;
  margin: 0 2px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
  &.active {
    transform: translateX(18px);
  }
}

.file-preview {
  margin: 10px auto;
  width: 95%;
  height: 27rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    height: 12rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    // your code
    height: 20rem;
  }

  :first-child {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}
