.mailingList {
  &__filter-form {
    @include flexbox;
    align-items: center;
    .form-group {
      flex: 3;
      margin-right: 10px;
    }
    button {
      flex: 2;
      background-color: #2a2d37 !important;
    }
  }
  &__check {
  }
  &__title {
    margin-right: auto;
  }
  &__section {
    margin-top: 20px;
  }
  &__section-header {
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    .btn:hover {
      color: $black-bg;
    }
    h6 {
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.5);
      text-transform: uppercase;
    }
    button {
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.5);
      text-transform: uppercase;
    }
  }
  &__member-root {
    transition: background-color 300ms ease-out;
    margin: 0 -30px;
    cursor: pointer;
    &:hover {
      background-color: rgba($color: $primary-button-bg, $alpha: 0.1);
    }
  }
  &__member {
    @include flexbox;
    align-items: center;
    padding: 15px 30px;
  }
  &__check {
    width: 15px;
    opacity: 0.3;
    margin-right: 15px;
    cursor: pointer;
  }
  &__avatar {
    width: 30px;
    margin-right: 15px;
  }
  &__details {
    p {
      &:first-child {
        @include font-regular($size: 15px, $lineHeight: "normal");
        color: $black;
      }
      &:last-child {
        @include font-regular($size: 12px, $lineHeight: "normal");
        color: rgba($color: $black, $alpha: 0.7);
      }
    }
  }
}
.back-arrow {
  margin-left: 0;
  margin-right: 25px;
  cursor: pointer;
}

[data-theme="dark"] {
  .mailingList {
    &__section-header {
      h6 {
        color: rgba($color: $white, $alpha: 0.5);
      }
      button {
        color: rgba($color: $white, $alpha: 0.5);
      }
    }
    &__details {
      p {
        &:first-child {
          color: $white;
        }
        &:last-child {
          color: rgba($color: $white, $alpha: 0.7);
        }
      }
    }
  }
}
