.form-group {
  .line {
    display: flex;
    margin-top: 2px;
    .password-line {
      width: 100%;
      height: 3px;
      background: $dar-grey;
      margin: 1px;
    }
  }
  .line-text {
    display: flex;
    justify-content: flex-end;
  }
  &:hover {
    small {
      opacity: 1;
      visibility: visible;
    }
  }
}
.form-row {
  margin-right: -10px;
  margin-left: -10px;
  > .col,
  > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}
label {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 1;
  small {
    color: $red;
    font-size: 10px;
    text-decoration: underline;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out;
  }
}
.invalid-feedback {
  display: block;
  position: absolute;
  font-size: 12px;
  margin-top: 1px;
  font-style: italic;
  color: $error-color;
}
.form-control {
  font-size: 15px;
  background: none;
  border: none;
  border-radius: 0;
  color: $black;
  padding: 0;
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
  height: auto;
  padding-bottom: 0;
  &::placeholder {
    color: rgba($color: $black, $alpha: 0.2);
  }
  &.filled {
    border-radius: 2px;
    height: 40px;
    font-size: 13px;
    padding: 0 20px;
    border: 1px solid rgba($color: $black, $alpha: 0.1);
    padding-bottom: 0 !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    color: rgba($color: $black, $alpha: 0.7);
    &:hover,
    &:active {
      background-color: transparent;
      border-bottom: 1px solid $primary-button-bg;
    }
    &::placeholder {
      color: rgba($color: $black, $alpha: 0.6) !important;
    }
    &.select {
      padding-left: 6px;
      padding-bottom: 0;
      border: none;
    }
    &:focus {
      color: $input-text;
      border: none;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid $link-color;
    }
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: $input-placeholder;
    opacity: 1;
  }
  &:focus {
    color: $black;
    background: none;
    border: none;
    border-bottom: 1px solid rgba($color: #4084bf, $alpha: 1);
    outline: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid $link-color;
  }
  .was-validated .form-control:invalid,
  &.is-invalid {
    border-color: $error-color;
    background-image: none;
    &:focus {
      border-color: $error-color;
      box-shadow: 0 0 0 0.2rem rgba($error-color, 0.25);
    }
    ~ .invalid-feedback,
    ~ .invalid-tooltip {
      display: block;
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}

.error-message {
  padding-top: 10px;
  color: orangered;
  @include font-regular(14px, normal);
  // text-transform: capitalize;
}

.css-2b097c-container {
  border-radius: 2px;
  background-color: transparent;
  height: 40px;
  font-size: 13px;
  padding: 0 14px;
  border-bottom: none;
}

.customselect__control.css-yk16xz-control {
  background-color: transparent;
  border: none;
  height: 40px;
}

.customselect__indicator-separator.css-1okebmr-indicatorSeparator {
  display: none;
}

.customselect__control {
  background-color: #2a2d37 !important;
  border: none !important;
  color: $white !important;
  &:active,
  &:focus {
    border: none !important;
  }
  &--menu-is-open {
    background-color: #2a2d37;
    border: none !important;
  }
  &--is-focused {
    background-color: #2a2d37;
    border: none !important;
  }
}

.customselect__menu {
  background-color: #2a2d37 !important;
}

.customselect__menuList {
  background-color: #2a2d37 !important;
}

.customselect__option {
  color: $white !important;
  @include font-regular(14px, normal);
  cursor: pointer !important;
  &:hover {
    background-color: $primary-button-bg !important;
  }
}

.customselect__value,
.customselect__value-container {
  border: none !important;
  &:active,
  &:focus {
    border: none !important;
  }
}

.customselect__single-value,
.customselect__placeholder {
  @include font-regular(14px, normal);
  color: $white !important;
}

textarea.form-control {
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.3) !important;
}

.drop-icon {
  position: absolute;
  top: 25px;
  right: 0;
  
  color: $black;
}

[data-theme="dark"] {
  .form-group .form-control {
    color: $almost-white;
  }
}
