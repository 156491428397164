.itinerariesForm {
  &__root {
    background-color: red;
    width: 100%;
  }

  &__items {
    padding: 10px 15px;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.04);
    &:last-child {
      border-bottom: none;
    }
  }
}
