@import "./generals";
.createOrganization input{
  color: #000000 !important;
}
.createOrganization {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  //background: url("../../images/login-bg.png") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__logo {
    max-width: 124px;
    margin: 12px auto;
    text-align: center;
    img {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &__thumbnail {
    position: relative;
    text-align: center;
    background-color: #f2f7f7;
    padding: 30px;
    border-radius: 4px;
    input {
      height: 100%;
      opacity: 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    p {
      margin-top: 10px;
    }
  }

  &__link {
    text-align: center;
    margin-top: 16px;
    a {
      color: $black;
      @include font-regular(16px, 1.69);
      text-decoration: underline;
      &:hover {
        color: $primary-button-bg;
      }
    }
  }

  &__selected {
    height: 100px;
    width: 100px;
    
    object-position: center right;
    object-fit: cover;
    max-width: 100%;
  }

  &__form {
    border-radius: 2px;
    background-color: rgba($color: $black, $alpha: 0.2);
    box-shadow: 0 1px 12px 0 rgba(26, 27, 41, 0.1);
    padding: 40px 25px 40px;
    width: calc(100% - 30px);
    max-width: 600px;
    h6,
    label,
    p {
      color: $black !important;
      cursor: default;
    }
    input {
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.2) !important;
    }

    h6 {
      font-size: 18px !important;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
    }

    > p {
      opacity: 0.7;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
    }
    .pb-4 {
      padding-bottom: 33px !important;
    }
    .organization-submit {
      margin-top: 25px;
      button {
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  &__little {
    margin-top: 4px;
    color: $black;
    font-size: 13px;
  }
  h6 {
    color: black;
  }
}

.logoCropper {
  min-width: 500px;
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  background-color: #2b2f3c;
  border-radius: 2px;
  &__footer {
    padding: 30px 0 0 0;
    button {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    h6 {
      font-size: 25px;
      font-weight: bold;
    }
  }
  &__cropper {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__close {
    border-radius: 140px;
    height: 31px;
    width: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
}

.ReactCrop {
  max-height: 70vh;
  &__image {
    max-height: 70vh;
  }
}

.text-head.org {
  @include flexbox;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 19.5px 30px;
  background: rgba($color: $black, $alpha: 0.1);
  .text-left {
    h1 {
      @include font-bold(15px, normal);
      color: $black;
    }
    p {
      @include font-regular(13px, 1.69);
      color: rgba($color: $black, $alpha: 0.7);
    }
  }
  label {
    color: black !important;
  }
  .close-icon d-none {
    @include flexbox;
    justify-content: flex-end;
    width: auto;
    .close-round {
      @include flexbox;
      align-items: center;
      justify-content: flex-end;
      padding: 7px;
      background: rgba($color: $black, $alpha: 0.1);
      border-radius: 100%;
      cursor: pointer;
    }
  }
}
