.notifications {
  &__updates,
  &__logs {
    position: relative;
    @include flexbox;
    align-items: center;
    padding: 18px 30px 18px 0;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
  }

  &__updates-details {
    p {
      @include font-regular(14px, normal);
      color: $black;
    }
    span {
      @include font-regular(12px, normal);
      color: rgba($color: $black, $alpha: 0.7);
    }
  }

  &__logs-details {
    p {
      @include font-regular(14px, normal);
      color: $black;
    }
  }

  &__logs-subdetails {
    @include font-regular(12px, normal);
    color: rgba($color: $black, $alpha: 0.7);

    span {
      color: rgba($color: $black, $alpha: 0.7);
    }
  }

  &__list {
    padding-left: 30px;
  }
}

[data-theme="dark"] {
  .notifications {
    &__updates,
    &__logs {
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    }

    &__updates-details {
      p {
        color: $white;
      }
      span {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }

    &__logs-details {
      p {
        color: $white;
      }
    }

    &__logs-subdetails {
      @include font-regular(12px, normal);
    }
  }
}
