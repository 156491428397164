.attachmentItem {
  &__root {
    @include flexbox;
    justify-content: flex-start;
    align-items: center;
    padding: 9px 18px;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.04);
    border-bottom: 1px solid $divider-grey;

    transition: background-color 400ms ease-in;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: calc(100%);
      background-color: $primary-button-bg;
      transition: width 200ms ease-in;
    }
    &.active {
      &:before {
        width: 5px;
      }
      background-color: $hover-grey;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  &__icon {
    padding: 0;
  }

  &__image {
    margin-right: 15px;
    width: 40px;
    border-radius: 2px;
  }

  &__details {
    flex: 1;
    h6 {
      @include font-bold($size: 15px, $lineHeight: 1.2);
      color: $black;
      a:hover {
        color: $primary-button-bg;
      }
    }
    p {
      @include font-regular($size: 13px, $lineHeight: 1.2);
      color: $font-dark-grey;
    }
  }
}

.attachment {
  &__name {
    padding: 6px 0;
    h6 {
      @include font-medium($size: 14px, $lineHeight: 1.2);
      color: $black;
    }
  }
  &__detail-main {
    h6 {
      @include font-medium($size: 16px, $lineHeight: 1.2);
      color: $black;
    }
  }
  &__input {
    padding: 6px 0;
  }

  &__section {
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
    margin-bottom: 12px;
  }

  &__file-container {
    margin: 0;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
  }
  &__detail-actions {
    padding: 6px 0;
    svg.edit {
      &:hover {
        color: $orange;
      }
    }
    h4 {
      @include font-regular(19px, "normal");
      color: $black;
      margin-right: auto;
    }
  }
  &__detail {
    form {
      padding: 0 0 12px 0;
      display: flex;
      flex-direction: column;
      width: calc(100%);
      margin: 0 auto;
      border-radius: 3px;
      textarea {
        padding: 3px;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        height: 250px;
        box-shadow: inset 0px 0px 3px 0px rgba(155, 156, 157, 0.3);
        &::placeholder {
          color: rgba($color: $black, $alpha: 0.3);
        }
      }
    }
    svg.delete {
      color: #ed0034;
    }
    svg {
      color: #747678;
    }
    label {
      color: $font-dark-grey;
    }
    .form-control:disabled,
    .form-control[readonly] {
      border-bottom: none;
    }
    input,
    textarea {
      color: $font-dark-grey;
      &::placeholder {
        color: $font-dark-grey;
      }
      &:focus {
        color: $black;
        border-bottom: 1px solid rgba($color: $font-dark-grey, $alpha: 0.1);
      }
    }
  }
  &__icon {
    max-width: 200px;
    padding: 0px 0 6px 0;
    text-align: center;
  }
  &__name {
    color: $font-dark-grey;
    input {
      border: none;
      @include font-bold($size: 18px, $lineHeight: 1.2);
    }
  }

  &__note {
    margin-top: 18px;
    padding: 18px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.04);
    border-top: 1px solid rgba($color: $black, $alpha: 0.04);
    input {
      border: none;
      &::placeholder {
        color: rgba($color: $black, $alpha: 0.5);
      }
    }
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
  padding: 5px 15px;
  font-size: 14px !important;
  text-transform: capitalize;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

[data-theme="dark"] {
  .attachment {
    &__note {
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.04);
      border-top: 1px solid rgba($color: $white, $alpha: 0.04);
    }
    &__detail-actions {
      background-color: #262a36;
      svg {
        color: $almost-white;
      }
    }
    &__input {
      background-color: #262a36;
    }
    &__name {
      background-color: $black-bg;
      h6 {
        color: $white;
      }
    }
    &__detail-main {
      h6 {
        color: $almost-white;
      }
    }
    &__detail {
      background-color: #262a36;
      svg {
        color: $white;
      }
      label {
        color: $font-dark-grey;
      }
      input {
        color: $almost-white;
        &:focus {
          color: $almost-white;
          border-bottom: 1px solid rgba($color: $primary-button-bg, $alpha: 0.1);
        }
      }
    }

    &__detail-actions {
      h4 {
        color: $almost-white;
      }
      svg {
        color: #9d9fa1;
      }
    }
    &__file-container {
      background-color: #2e3240;
    }

    &__name,
    &__input {
      background-color: transparent;
    }

    &__detail {
      form {
        background-color: #2e3240;
        textarea {
          &::placeholder {
            color: rgba($color: $white, $alpha: 0.3);
          }
        }
      }
    }
  }
  .attachmentItem {
    &__root {
      border-bottom: 1px solid $border-lighter-grey;
      &.active,
      &:hover {
        &:before {
          width: 5px;
        }
        background-color: $dark-hover;
      }
    }
    &__details {
      a {
        &:hover {
          color: $primary-button-bg;
        }
      }
      h6 {
        color: $white;
      }
      p,
      span {
        color: $font-dark-grey;
      }
    }
  }
}
