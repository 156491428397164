/* reset password page css start here */
.before-login {
  @include flexbox;
  align-items: center;
  width: 100%;
  height: auto;
  background: transparent;


  .inner-page {
    width: 100%;
    margin: 0 auto;
    .forgot-page {
      max-width: 470px;
      width: 470px;
      padding: 30px 35px 35px;
      margin: 0 auto;
      position: relative;
      border-radius: 2px;
      background: $white;

      @media screen and (max-width: $phone-landscape) {
        width: 90%;
        max-width: unset;
      }
      .forgot-page-content {
        .close-icon d-none {
          width: 100%;
          @include flexbox;
          justify-content: flex-end;
          position: absolute;
          top: 20px;
          right: 20px;
          .close-round {
            @include flexbox;
            align-items: center;
            cursor: pointer;
            padding: 7px;
            background: rgba($color: $black, $alpha: 0.4);
            border-radius: 100%;
            cursor: pointer;
          }
        }
        .forgot-page-head {
          @include flexbox;
          align-items: center;
          justify-content: space-between;
          h1 {
            @include font-bold(15px, 1.37);
            color: $black;
          }
          .forgot-text {
            h1 {
              @include font-bold(15px, 1.7);

              margin: 0;
            }
            p {
              color: rgba($color: $black, $alpha: 0.7);
            }
          }
        }
        .body-section {
          .forgot-pg {
            width: 300px;
            p {
              @include font-regular(15px, 1.57);
              color: rgba($color: $black, $alpha: 0.7);
            }
          }
          form {
            padding-top: 30px;
            .form-group {
              position: relative;
              .form-control {
                @include font-regular(15px, normal);
                color: $black;
                border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
                &:focus {
                  border-bottom: 1px solid $link-color;
                }
              }
              label {
                @include font-regular(12px, normal);
                color: $black;
              }
              input {
                text-shadow: none;
                color: $black;
                -webkit-text-fill-color: $black !important;
              }
            }
            .forgot-password {
              position: absolute;
              top: 42px;
              right: 0;
              img {
                cursor: pointer;
              }
            }
          }
          .submit-button {
            padding-top: 10px;
            button {
              @include font-medium(13px, normal);
            }
          }
        }
      }
    }
  }
}
