/*=============================================
*   screen width
=============================================*/

$phone-landscape: 576px;
$tablet-portrait: 768px;
$tablet-landscape: 992px;
$desktop: 1280px;
$desktop-hd: 1385px;
/* -----------Custom variables will goes here */

$background_modals: #eceaeb;
$white: #ffffff;
$black: #000000;
$subTitleGray: #606060;
$grey: #f4f4f4;
$light-grey: #282c38;
$yellow: #fb9f00;
$brown: #563542;
$orange: #e4784c;
$brown-light: #f86e7c;
$green: #1ba863;
$light-green: #13b128;
$action-green: #2c9a67;
$red: #dd3b44;
$blue: #01aeff ;
$purple: #bd10e0;
$dar-grey: #b5b6b6 !default;
$theme-color: #0a196b !default;
$theme-text: #898da0 !default;
$almost-white: #fefefe;
$header-background: #e3e3e3;
// Old darks
$dark-theme: #1c1f29;
$dark-theme-bg: #2c303c;
$dark-light-theme: #21242f;
$dark-grey-theme: #2b2f3c;
$dark-darker-theme: #262a35;

// New Darks
$sidebar-dark: #222631;
$border-dark-grey: #2d313c;
$header-dark: #262a36;
$theme-dark-font: #bcbdc1;
$resource-dark: #2d3240;
$resource-header-dark: #2e3745;
$border-light-grey: #373c49;
$border-lighter-grey: #353a47;
$dark-hover: #30394a;
$blue-dark-theme: #2a374a;
$dark-heading: #242834;
$dark-grey-1: #2a2e39;
$dark-modal-header: #1d202a;

$main-grey: linear-gradient(
  90deg,
  rgba(229, 228, 229, 1) 0%,
  rgba(220, 220, 220, 1) 100%
); /* #ececec;*/
$second-grey: #e6e8ea;
$third-grey: #f0f0f0;
$white: #ffffff;
$light-grey: #f7f8f8;
$font-grey: #898989;
$font-dark-grey: #1d1d1d;
$font-light-grey: #717375;
$sidebar-grey: linear-gradient(
  90deg,
  rgba(220, 220, 220, 1) 0%,
  rgba(221, 221, 222, 1) 100%
); /*#E5E7E8;*/ /* #e6e8ea;*/
$border-grey: #ebedee;
$hover-grey: #f0f5f9;
$divider-grey: #eaeaea;
$solid_background: #eceaeb;
$border-color: #cbcbcb;
/* Button */
$primary-button-bg: #01aeff !default;
$secondary-button-bg: #313440 !default;

/* input */
$input-placeholder: #828282;
$input-text: #ffffff;
$input-label: #000000;
$error-color: #ff0000;
$input-focus-border-color: #01aeff;

/* page */
$page-bg: #2b2f3c;
$black-bg: #181b24;
$link-color: #01aeff;

/* hover */
$blue-hover: #30394a;
