/* loader start here */
.loader-main {
  @include flexbox;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  .spinner-border {
    color: $black !important;
  }
}

.spinner-border {
  color: $black !important;
}

[data-theme="dark"] {
  .loader-main {
    .spinner-border {
      color: $almost-white !important;
    }
  }
  .spinner-border {
    color: $almost-white !important;
  }
}
