.teamItem {
  position: relative;
  @include flexbox;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
  transition: background-color 300ms ease-in;

  &:hover {
    background-color: $hover-grey;
    cursor: pointer;
   
  }

  .teamItem__notifications,
  .teamItem__more.delete {
    &:active {
      transform: scale(0.9);
    }
    opacity: 1;
    visibility: visible;
  }

  &.active {
    background-color: $hover-grey;
    &:before {
      content: "";
      position: absolute;
      top: 20%;
      height: calc(100% - 40%);
      left: 0;
      width: 2px;
      background-color: $primary-button-bg;
    }
  }

  &__notifications {
    opacity: 0;
    visibility: hidden;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__more {
    &.delete {
      opacity: 0;
      visibility: hidden;
      svg {
        color: $red;
      }
    }
    .dropdown {
      width: auto;
    }
    &:active,
    &:focus {
      outline: none;
    }
    .dot-icon {
      position: relative;
    }
  }
  &__details {
    p {
      @include font-regular(13px, normal);
      color: $black;
    }
  }

  &__users {
    padding-top: 3px;
    @include font-regular(12px, normal);
    color: rgba($color: $black, $alpha: 0.7);
  }

  &__actions {
    @include flexbox;
    button {
      &:first-child {
        max-width: 18px;
      }
      &:last-child {
        margin-right: 0;
      }
      margin: 0 3px;
      max-width: 24px;
    }
  }
}

.organizationSidebar {
  &__root {
   
    position: absolute;
    z-index: 50;
    width: 70px;
    height: 100%;
    border-right: 1px solid #284163;
    background: #2b4566;
    padding: 18px 5px;
    @include flexbox;
    flex-direction: column;

    a {
      &:hover {
        color: $primary-button-bg;
      }
    }
  }

  &__button {
    position: relative;
    margin: 0px auto 6px auto;
    height: 45px;
    width: 45px;

    @include flexbox;
    overflow: hidden;

    align-items: center;
    justify-content: center;
    transition: all ease-in 100ms;
    &:hover {
      height: 50px;
      width: 50px;
      border: none;
      background-color: transparent;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      border: 1px solid rgba($color: $white, $alpha: 0.05);
    }
  }
}

.createTeam {
  &__form {
    background-color: $dark-grey-theme;
  }

  &__header {
    @include font-regular(12px, normal);
    color: rgba($color: $white, $alpha: 1);
    text-transform: uppercase;
    padding: 16px;
  }

  &__form-inner {
    padding: 16px 16px 0 16px;
    &.last {
      padding: 32px 16px;
    }
  }

  &__item {
    @include flexbox;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
  }
}

.userTeam {
  // padding: 18px 0 0 0;
  .email-hover {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }

  &:hover {
    .email-hover {
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }
  &.inactive {
    .userTeam__user {
      opacity: 0.5;
    }
  }
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
  &__details {
    p {
      font-size: 11px;
    }
  }
  .col-md-4 {
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    &:nth-child(7) {
      border-bottom: none;
    }
  }
  &__profilePicture {
    width: 38px;
    height: 38px;
    margin-right: 16px;

    overflow: hidden;
    img {
      object-fit: contain;
    }
  }

  &__icon {
    max-width: 16px;
    margin: 0 3px;
  }

  &__user {
    @include flexbox;
    align-items: center;
    span {
      padding-top: 3px;
      @include font-regular(12px, normal);
      color: rgba($color: $white, $alpha: 0.7);
    }
  }

  &__name {
    @include font-regular(14px, normal);
    color: $white;
    @include flexbox;
    align-items: center;
    span {
      padding-top: 0;
      margin-left: 4px;
    }
    svg {
      font-size: 1em !important;
    }
  }

  .nav-tabs .nav-item {
    flex: 1;
    text-align: center;
    a {
      @include font-regular(14px, normal);
      color: $white;
    }
    a:hover {
      background: none;
      outline: none;
      border: none;
    }
    &.active,
    &:hover {
      background-color: transparent;
      border-bottom: 2px solid $primary-button-bg;
      a {
        @include font-regular(14px, normal);
        color: $primary-button-bg;
      }
    }
  }
}

.inviteUser {
  &__form {
    background-color: $dark-light-theme;
    width: 60%;
  }

  &__form-inner {
    padding: 16px;
  }

  &__header {
    padding: 8px 16px;
    background-color: $dark-theme;
    text-transform: uppercase;
    @include font-regular(12px, normal);
    color: rgba($color: $white, $alpha: 0.7);
  }

  &__buttons {
    padding-top: 16px;
    button {
      margin: 0 6px;
      @include font-regular(10px, normal);
    }
  }
}
.organizationItem__photo.active {
  border: solid 3px #01aeff;
}

.userItem {
  &__detail {
    flex: 1;
    padding: 12px 0;
  }

  &__detail-title {
    @include font-regular(12px, normal);
    color: rgba($color: $black, $alpha: 0.7);
    margin-bottom: 6px;
  }

  &__detail-content {
    @include font-regular(16px, normal);
    color: rgba($color: $black, $alpha: 1);
  }
}

.px-18 {
  padding-right: 18px;
  padding-left: 18px;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.border-bottom-normal {
  border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
}

.grey_text {
  color: rgba($color: $white, $alpha: 0.7);
}

.close-round {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 7px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  cursor: pointer;
}



.selectOrganization {
  &__container {
    @media (max-width: $phone-landscape) {
      max-width: unset !important;
      width: 100%;
      margin: 0;
    }
  }

  &__container-inner {
    background-color: rgba($color: $white, $alpha: 0.2);
    border-radius: 3px;

    @media (max-width: $phone-landscape) {
      padding: 15px;
      margin: 0;
    }
  }
  &__body {
    padding: 18px 18px 24px 18px;
    max-width: 600px;
    width: 100%;
    .clickable {
      &:hover {
        background-color: rgba($color: #01aeff, $alpha: 0.05) !important;
      }
      &:active {
        background-color: rgba($color: #01aeff, $alpha: 0.1) !important;
      }
    }
  }
  &__header {
    background-color: #d5d5d5;
    text-align: center;
    padding: 18px;
    h3 {
      @include font-bold($size: 24px, $lineHeight: "normal");
    }
  }
  &__footer {
    a {
      color: $white;
    }
  }
  &__back {
    cursor: pointer;
    color: $white;
    svg {
      color: $white;
    }
  }
  &__inner {
    @include flexbox;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f3f3f3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  }

  &__bg {
    filter: blur(7px);
    -webkit-filter: blur(7px);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__item {
    cursor: pointer;
    width: 190px;

    height: 190px;
    margin-left: 0.5%;
    margin-right: 0.5%;
    border-radius: 0;
    overflow: hidden;
    max-height: 23vh;
  }
  &__detail {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba($color: #cbcfd2, $alpha: 1);
    padding: 4px 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 5;
  }
}

.createTeam__item {
  color: $black;
}
.createTeam__header {
  padding: 8px;
  color: $black;
  background: $grey !important;
}
.icon-section svg {
  color: rgb(77, 77, 77);
}
