.sidebar {
  position: fixed;
  left: 0;
  height: 100vh;
  z-index: 80;
  top: 0;
  border-right: 1px solid $border-color;
  background:  linear-gradient(
    60deg,
    #2B4566 0%,
    #2F4E6F 100%);//$sidebar-grey;
  color: #BFC4C8;
  transition: all .5s ease-in-out;
  overflow: hidden;

  @media screen and (min-width: 1124px) {
    left: 0;
  }
  &__toggle-theme {
    margin: 0 auto 0 12px;
    width: calc(100% - 35px);
    cursor: pointer;
    padding: 10px 0 10px 0;
    p {
      margin-left: 10px;
    }
    img {
      filter: none;
      max-width: 22px;
    }
  }

  &__toggle {
    text-align: left;
    max-width: 30px;
    cursor: pointer;
    margin: 0 auto;
    right: 0;
    position: relative;
    img {
      max-width: 100%;
      height: auto;
      margin-right: auto;
      filter: invert(49%) sepia(4%) saturate(238%) hue-rotate(169deg)
        brightness(91%) contrast(88%);
    }
    &.shrink {
      margin: 0 auto 0 auto;
      position: relative;
    }
  }
  &__content {
    height: calc(100% - 165px);
  }
  &.expanded {
     width: 210px;   

    .navItem {
      cursor: pointer;
      width: calc(100%);
      justify-content: unset;

      
    }

 
     .organizationItem {
      top:3px;
      height:67px;
      width: 100%;

      border-bottom: solid 1px #284163;
      &__photo {
        max-width: 50px;
        height: 42px;
        width: 42px;
      }
      &__detail {
        margin: auto 3px auto 9px;
        flex: 1;

        p {
          @include font-medium(14px, "normal");
          color: #BFC4C8;
          max-width: 17ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        a {
          @include font-regular(10px, "normal");
          color: #696969;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &__text {
        font-size: 13px;
        color: $white;
        padding-left: 15px;
        display: block;
        white-space: nowrap;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .spinner-main {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 90px;
    }
  }

  &.slim {
    left: -100%;
    width: 200px;

    p {
      display: block;
    }
    .sidebar__toggle-theme {
      > div {
        justify-content: center;
      }
    }


    @media screen and (min-width: 1124px) {
     width: 0px;
      left: 0;
      p {
        display: none;
      }
      .separator {
        p {
          display: none;
        }
      }
    }
    .organizationItem__avatar,
    .userItem__avatar {
      margin: 0;
    }

    .nav_menu_btn{
      transform: translate(-8px);
      display: none !important;
    }
    .organizationItem {
      width: 78px;
      &:hover {
        .organizationItem__overlay-link {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .organizationItem__overlay-link {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      display: flex;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      background-color: rgba(26, 27, 41, 0.1);
      a {
        color: $white;
      }
    }

    .organizationItem__menu {
      display: none !important;
    }
    .navItem {
      cursor: pointer;
      width: 60px;
      &.active {
        border-left: 2px solid $primary-button-bg;
      }
    }
    .spinner-main {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 90px;
    }
  }
}

.eventNavigation__header {
  left: 0px;
  width: calc(100% - 0px);
  transition: all .5s ease-in-out;
}

@media screen and (min-width: 1124px) {


.content {
    padding-left: 210px;
    overflow: hidden;
    height: 100vh;
    .eventNavigation__header {
      left: 20px;
      width: calc(100% - 0px);
      transition: all .5s ease-in-out;
    }

    .itineraries-transparent {
      left: 210px;
    }

  &.fixed {
    padding-top: 125px;
  }

  &.slim {
    padding-left: 0px;
  }
  &.expanded{
    .eventNavigation__header {
      left: 210px;
      width: calc(100% - 210px);
    }
  }
}

.organizationItem,
.userItem {
  display: flex;
  align-items: center;
  padding: 17.5px 18px;
  &__avatar {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: cover;
    }
  }
}
}
.organizationItem {
  margin-top: -7px;
  position: relative;

  &:hover {
    .organizationItem__overlay {
      opacity: 1;
      visibility: visible;
      left: 0;
    }
  }

  &__photo {
    overflow: hidden;
    border-radius: 10px;
    @include flexbox;
    height: 40px;
    width: 40px;
    img {
      object-fit: cover;
    }
  }
  &__overlay {
    transition: opacity 300ms ease-in;
    position: absolute;
    top: 0;
    left: -99999px;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    a {
      @include font-medium(13px, normal);
      color: $white;
    }
  }

  &__container {
    margin-bottom: 18px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    .btn {
      @include font-medium(13px, normal);
      text-transform: uppercase;
      height: auto;
    }
    .btn-link:hover {
      text-decoration: none !important;
    }
    .dropdown-menu-right {
      transform: translate3d(30px, 50px, 0) !important;
    }
  }

  &__button {
    padding: 20px;
    background-color: $page-bg;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__itemsContainer {
    height: calc(50vh - 175px);
    position: relative;
    overflow-y: auto;
    margin-bottom: 45px;
  }

  &__menu {
    min-width: 19rem;
    background-color: $page-bg;
    border-radius: 2px;
    box-shadow: 0 1px 20px 0 rgba(26, 27, 41, 0.1);
    left: 18px !important;
    top: 17.5px !important;
    max-height: 600px;
    z-index: 55;
    &.noOrgs {
      height: 12vh;
    }
  }
}

.organizationListItem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #373a42;
  transition: background-color 300ms ease-in;
  background-color: rgba(26, 27, 41, 0);
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: rgba(26, 27, 41, 0.5);
    .organizationListItem__icon {
      opacity: 1;
      visibility: visible;
    }
  }
  &__text {
    font-size: 13px;
    color: $white;
    padding-left: 15px;
  }
  &__avatar {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    overflow: hidden;
    @include flexbox;
    img {
      object-fit: cover;
    }
  }
  &__icon {
    margin-left: auto;
    transition: 200ms ease-in opacity;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    color: $font-dark-grey;
  }
}

.userItem {
  
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  bottom: 67px;
  width: 100%;
  cursor: pointer;
}

.separator {
  padding: 10px 10px 0px 12px;
border-top:solid 1px #284163;
  margin-top: 5px;
  width:99%;
  p {
    /*text-transform: uppercase;*/
    font-size: 9.6px;
    color: #CFD3D4 !important;
    opacity: 0.9;
  }
}

.navItem {
  cursor: pointer;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;/*
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;*/
  transition: background-color 300ms ease-out;
  &__icon-max,
  &__icon-container {
    width: 18px;
  }
  &__icon {
    margin-right: 10px;
    svg {
      color: $font-dark-grey;
    }
    
  }
  p {
    color: #CFD3D4;
    margin-left: 5px;
    font-family: "opensansregular";
    font-size:12px;
  }
  &:hover {
    .navItem__icon {
      filter: invert(45%) sepia(99%) saturate(345%) hue-rotate(165deg)
        brightness(87%) contrast(84%);
    }
    // background-color: rgba(64, 132, 191, 0.15);
  }
  &.active {
    border-left: 2px solid $blue;
    .navItem__icon {
      filter: invert(45%) sepia(99%) saturate(345%) hue-rotate(165deg)
        brightness(87%) contrast(84%);
    }
    background-color: $primary-button-bg !important;//rgba(64, 132, 191, 0.15);
    p, i{
    color: #fff !important;
    }
  }
}

.navItem__icon-container img {
  max-width: 15.9px;
}

.userItem h6{
  color: #CFD3D4;
  font-size:12px !important;
}