/* add flight page css start here */
.flight-page {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  background: transparent;
  min-height: 100%;
  /* right section start here */
  .flight-expand-section {
    min-width: 500px;
    width: 500px;
    height: 100vh;
    overflow-y: auto;
    background: $white;
    .flight-head {
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      padding: 17px 30px;
      background: rgba($color: $white, $alpha: 0.1);
      width: 100%;
      .text-left {
        width: 100%;
        h1 {
          @include font-bold(15px, normal);
          color: $white;
        }
        p {
          @include font-regular(13px, 1.69);
          color: rgba($color: $white, $alpha: 0.7);
        }
      }
      .close-icon d-none {
        @include flexbox;
        justify-content: flex-end;
        width: auto;
        .close-round {
          @include flexbox;
          align-items: center;
          justify-content: flex-end;
          padding: 7px;
          background: rgba($color: $white, $alpha: 0.1);
          border-radius: 100%;
          cursor: pointer;
        }
      }
    }
    .flight-inside {
      width: 100%;
      padding: 30px;
      .form-group {
        .react-datepicker-popper {
          transform: translate3d(14px, 56px, 0px) !important;
        }
        .react-datepicker-wrapper {
          width: 202.5px;
          border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
          input {
            cursor: pointer;
          }
        }
        .react-datepicker-time__header {
          color: $white;
        }
        li.react-datepicker__time-list-item--selected {
          background-color: transparent !important;
          &:hover {
            background: rgba($color: $white, $alpha: 0.05);
          }
        }
        .react-datepicker__time-list {
          max-height: 150px;
        }
        .react-datepicker__time-container {
          width: 230px;
        }
        .react-datepicker.react-datepicker--time-only {
          border-radius: 0;
          padding: 0;
        }
        .react-datepicker__time-box ul {
          top: 0;
        }
        li.react-datepicker__time-list-item:hover {
          background: rgba($color: $white, $alpha: 0.05) !important;
        }
        .react-datepicker__triangle {
          display: none;
        }
        .search-btn {
          @include flexbox;
          align-items: center;
          justify-content: center;
          width: 25px;
          max-width: 25px;
          max-height: 25px;
          height: 25px;
          border-radius: 2px;
          position: absolute;
          right: 0;
          bottom: 12px;
          cursor: pointer;
          background: $primary-button-bg;
        }
        .form-control:disabled,
        .form-control[readonly] {
          background: transparent;
        }
      }
      .type-head {
        h3 {
          @include font-regular(12px, normal);
          color: rgba($color: $white, $alpha: 0.6);
          padding-bottom: 10px;
        }
      }
      .type_section {
        width: 100%;
        padding-bottom: 35px;
        &_select {
          @include flexbox;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 20px;
          margin-bottom: 15px;
          background: rgba($color: $white, $alpha: 0.02);
          cursor: pointer;
        }
        .active {
          background: rgba($color: $blue, $alpha: 0.2);
        }
        &_left {
          @include font-regular(15px, normal);
          color: $white;
        }
        &_icon {
          cursor: pointer;
        }
      }
      .submit-button {
        margin-top: 15px;
        button {
          @include font-medium(13px, normal);
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}

.stopFlightModal {
  background-color: $almost-white;
  padding: 20px;
  border-radius: 12px;
  &__item {
    cursor: pointer;

    &:hover {
      p {
        @include font-medium(14px, "normal");
      }
    }
    &:nth-child(odd) {
      background-color: #ffffff;
    }
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
    @include flexbox;
    justify-content: flex-start;
    padding: 6px;
  }
}

.stopcard {
  margin-top: 10px;
  border: 1px solid rgba($color: $black, $alpha: 0.25);
  border-radius: 2px;
  &__side {
    h6 {
      @include font-bold(18px, "normal");
      color: $black;
    }
    p {
      @include font-regular(14px, "normal");
      color: rgba($color: $black, $alpha: 0.7);
      &:last-child {
        @include font-regular(13px, "normal");
      }
    }
  }

  &__details {
    padding: 6px 18px;
  }

  &__footer {
    border-top: 1px solid rgba($color: $black, $alpha: 0.25);
    padding: 6px 18px;
    p {
      @include font-regular(13px, "normal");
      color: rgba($color: $black, $alpha: 0.7);
    }
  }

  &__footer-edit {
    @include font-regular(13px, "normal");
    text-decoration: underline;
    color: $primary-button-bg;
  }

  &__footer-delete {
    @include font-regular(13px, "normal");
    text-decoration: underline;
    color: $red;
  }
}

.commercialFlight,
.privateFlight {
  &__head {
    h4 {
      color: $black;
    }
    p {
      margin-top: 0.7rem;
      font-style: italic;
      color: rgba($color: $black, $alpha: 0.7);
    }
  }

  &__form {
    .react-datepicker-wrapper {
      padding-bottom: 7px;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.15);
      display: block;
    }
    .react-datepicker__input-container {
    }
  }
}

svg.label-info {
  font-size: 1rem;
}

.flight-actions {
  .dropdown,
  .dropleft {
    width: auto;
  }
}

[data-theme="dark"] {
  .stopcard {
    margin-top: 10px;
    border: 1px solid rgba($color: $white, $alpha: 0.25);
    border-radius: 2px;
    &__side {
      h6 {
        @include font-bold(18px, "normal");
        color: $white;
      }
      p {
        @include font-regular(14px, "normal");
        color: rgba($color: $white, $alpha: 0.7);
        &:last-child {
          @include font-regular(13px, "normal");
        }
      }
    }

    &__details {
      padding: 6px 18px;
    }

    &__footer {
      border-top: 1px solid rgba($color: $white, $alpha: 0.25);
      padding: 6px 18px;
      p {
        @include font-regular(13px, "normal");
        color: rgba($color: $white, $alpha: 0.7);
      }
    }

    &__footer-delete {
      color: $red;
    }
  }

  .stopFlightModal {
    background-color: #21242f;
    .text-dark {
      color: $white !important;
    }
    &__item {
      &:nth-child(even) {
        background-color: #252833;
      }
      &:nth-child(odd) {
        background-color: #2e3240;
      }
    }
  }
  .dropdown-list {
    li {
      a:not([href]):not([class]) {
        color: $white;
        &:hover {
          color: $primary-button-bg;
        }
      }
    }
  }
  .commercialFlight {
    &__form {
      .react-datepicker-wrapper {
        padding-bottom: 7px;
        border-bottom: transparent;
        display: block;
      }
    }
    &__head {
      h4,
      p {
        color: $white;
      }
    }
  }
}
