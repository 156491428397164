.taskItem {
  position: relative;
  @include flexbox;
  align-items: center;
  padding: 8px 8px;
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);

  &__details {
    flex: 3;
    cursor: pointer;
    @include flexbox;
    flex-direction: column;
    span {
      @include font-regular(12px, normal);
      color: rgba($color: $white, $alpha: 0.7);
    }
  }

  &__check {
    cursor: pointer;
  }

  &__note {
    height: auto;
    overflow-y: scroll;
    padding: 10px 18px;
    border-top: 1px solid rgba($color: $black, $alpha: 0.1);
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
  }

  &__progress {
    span {
      @include font-regular(11px, normal);
      color: $primary-button-bg;
    }
    .progress {
      width: 60px;
      height: 0.4rem;
      background-color: #323744;
    }
    .progress-bar {
      background-color: $primary-button-bg;
    }
  }

  &__priority {
    @include font-regular(14px, normal);
    .low {
      color: $green;
    }
    .high {
      color: $red;
    }
    .medium {
      color: $orange;
    }
  }

  &__actions {
    flex: 1;
  }

  &__subtasks {
    .list-subheader {
      padding-left: 66px;
      padding-right: 66px;
    }
  }

  &__subtask {
    padding: 8px 0;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
  }

  &__subtask.completed {
    .taskItem__subtasks-detail {
      text-decoration: line-through;
      color: #777a82;
    }
  }

  &__subtasks-check {
    margin-right: 12px;
    width: 18px;
    svg {
      max-width: 100%;
    }
  }

  &__subtasks-detail {
    @include font-regular(12px, normal);
  }
}

.subTask {
  &__dropzone {
    margin-top: 20px;
    padding: 12px 20px;
    background-color: #262a37;
    border: 1px dashed #3c3f4b;
    p {
      @include font-regular(14px, normal);
      color: rgba($color: $white, $alpha: 0.7);
    }
  }
}

.fileList {
  &__file {
    padding: 12px 0;
    margin-top: 12px;
    p {
      @include font-regular(14px, normal);
      color: rgba($color: $white, $alpha: 1);
    }
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
  }
}

.px-66 {
  padding: 8px 66px;
}

.card .taskItem {
  border: 0 !important;
}
