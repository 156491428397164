.collab-screen {
  width: 100%;
  padding: 12px;
  text-align: left;
}

.collaborationDetail {
  &__body {
    color: white;
    height: 100%;
  }

  &__chat {
    height: calc(100% - 3rem);
    border: 1px solid $light-grey;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
  }

  &__message-box {
    background-color: #282c38;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem;
  }

  &__message-body {
    @include font-regular($size: 14px, $lineHeight: "normal");
  }

  &__message-footer {
    @include font-regular($size: 11px, $lineHeight: "normal");
    opacity: 0.6;
  }

  &__invitationDetails {
    color: $white;
  }

  &__message {
    margin: 8px 0;
    color: $white;
    border-radius: 5px;
    padding: 0.5rem;
    &.guest {
      padding-left: 1rem;

      background-color: $primary-button-bg;
    }
    &.local {
      padding-right: 1rem;
      text-align: right;
    }
  }
}

.collabEditor {
  &__editor {
    border: 1px solid rgba($color: $white, $alpha: 0.6);
    border-top: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: $white;
    padding: 1rem 6px;
    @include font-regular($size: 14px, $lineHeight: "normal");
  }

  &__toolbar {
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
}
