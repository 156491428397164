.hotelEmail {
  &__field {
    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 6px;
    }
    margin: 12px auto;
    position: relative;
    border-bottom: 1px solid #dbdbdc;
    padding-bottom: 3px;
    span {
      flex: 5;
      color: #696969;
    }
    .rbt,
    input {
      flex: 95;
    }

    input {
      border: none;
      outline: none;
      &:focus,
      &:active {
        outline: none;
        border: none !important;
      }
    }
  }
  &__recipients {
    span {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.7);
      margin-bottom: 10px;
    }
    margin-top: 20px;
    padding-bottom: 10px;
    @include flexbox;
    align-items: flex-start;
    flex-direction: column;
    > div {
      @include flexbox;
      flex-wrap: wrap;
    }
  }

  &__recipient {
    &:first-child {
      margin-left: 0;
    }
    margin: 2.5px;
    @include flexbox;
    align-items: center;
    padding: 7px 10px;
    border-radius: 14px;
    background-color: rgba($color: $white, $alpha: 0.05);
    flex: 1;
    max-width: 200px;
    p {
      margin-right: 5px;
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: $white;
    }
    span {
      @include flexbox;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 4px;
      background-color: #252934;
      border-radius: 50%;
      margin: 0;
      img {
        width: 12px;
      }
    }
  }

  &__resources {
    background-color: #ecf3f8;
    border-radius: 8px;
    padding: 6px 1px;
    svg {
      margin: 0 6px;
      color: #484954;
      font-size: 1rem;
    }
  }

  &__recipients-input {
    border: none !important;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.4) !important;
    background-color: transparent !important;
    width: 100%;
    height: 50px !important;
  }

  &__cta {
    text-align: right;
    svg {
      color: $primary-button-bg;
      cursor: pointer;
      &:hover {
      }
    }
  }

  &__subject {
    margin-top: 5px;
  }

  &__note {
    margin-top: 20px;
  }

  &__email-container {
    background: #fff;
    padding: 0;
    z-index: 1;
    position: relative;
  }

  &__check {
    padding: 10px 8px;
    background-color: #1c1f27;
    border-radius: 2px;
    margin: 0 2.5px;
    &:first-child {
      margin-left: 0;
    }
    &:nth-child(4) {
      margin-right: auto;
    }
  }

  &__footer {
    @include flexbox;
    align-items: center;
    position: absolute;
    left: 30px;
    width: calc(100% - 60px);
    bottom: 30px;
    .hotel-check {
      padding: 0;
    }
    .form-check-input {
      margin-left: 0;
    }
    label {
      margin-left: 20px;
    }
    button {
      max-width: 150px;
      @include font-bold($size: 13px, $lineHeight: "normal");
      text-transform: uppercase;
    }
  }
}

[data-theme="dark"] {
  .hotelEmail {
    &__recipients {
      span {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }
    &__cta {
      a {
        color: rgba($color: $white, $alpha: 0.7);
        &:hover {
          color: $white;
        }
      }
    }
    &__recipients-input {
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.4) !important;
    }
  }
}
