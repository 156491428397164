.tickets-table {
  thead {
    th {
      background-color: $dark-darker-theme;
      @include font-regular(12px, normal);
      color: rgba($color: $white, $alpha: 1);
      text-transform: uppercase;
      border-top: 1px solid #363a45;
      border-bottom: 1px solid #363a45;
    }
  }

  tbody {
    td {
      border-bottom: 1px solid #363a45;
      &.approved {
        border-left: 3px solid $green;
      }
      &.cancelled {
        border-left: 3px solid $red;
      }
    }
    th {
      @include font-regular(12px, normal);
      border-top: none;
      height: 50px;
      vertical-align: middle;
    }
  }
}

.dot-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.guestList {
  &__options {
    .popup-overlay {
      background-color: rgba($color: $black, $alpha: 0.25);
      z-index: 1;
    }

    .popup-content {
      background: none !important;
      border: none !important;
      height: 0 !important;
    }

    .popup-arrow {
      display: none;
    }
  }
}

.eventDropdown {
  height: 30px;
  span#dropDown {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #363a45;
  }
  .dropdown-menu.show {
    width: 100%;
    background: #2c303c;
  }
  .dropDownList {
    cursor: pointer;
    width: 100%;
    @include font-regular(14px, normal);
    color: rgba($color: $white, $alpha: 1);
    padding: 6px 18px;
    border-bottom: 1px solid #363a45;
    &:hover {
      color: $primary-button-bg;
    }
  }
}

.guestList {
  &__events {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 40%;
    background-color: $dark-light-theme;
    z-index: 5;
  }

  &__events-list {
    padding: 18px;
  }

  &__events-item {
    max-width: 70%;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }
}

.infoBox {
  padding: 12px;
  background-color: rgba(64, 132, 191, 0.15);
  min-height: 94px;
  &__title {
    @include font-bold(14px, normal);
    color: rgba($color: $white, $alpha: 1);
  }
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 220px;
  }
}

.event-status {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  &.Normal {
    background-color: $primary-button-bg;
  }
  &.Confirmed {
    background-color: $green;
  }
  &.Cancelled {
    background-color: $error-color;
  }
  &[class*="Tentative"] {
    background-color: $yellow;
  }
  &.Postponed {
    background-color: $purple;
  }
}

.Normal, .Confirmed, .Cancelled, .Tentative, .Postponed{
  font-size:10px;
  vertical-align: top;margin-top:5px;
  
}

.Normal {
  color: $primary-button-bg;
}
.Confirmed {
  color: $green;
}
.Cancelled {
 color: $error-color;
}
.Tentative {
  color: $yellow;
}
.Postponed {
  color: $purple;
}