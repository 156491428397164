.hotelDetail {
  &__root {
    .img-thumbnail {
      background-color: transparent;
      border: none;
    }
  }

  &__title {
    @include font-medium(16px, "normal");
    color: $black;
  }

  &__info {
    @include flexbox;
  }
  &__picture {
    overflow: hidden;
    width: 140px;
    max-height: 120px;
    
    img {
      margin: 0 auto;
      object-fit: cover;
      max-width: 100%;
    }
  }
  &__information {
    flex: 1;
  }
  &__information-inner {
    background-color: #f7f8f8;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
  }
  &__main {
    h3 {
      @include font-bold($size: 20px, $lineHeight: "normal");
      color: $black;
    }
    p {
      @include font-regular($size: 15px, $lineHeight: "normal");
      color: #606060;
    }
  }

  &__notes-head {
    &.flight {
      border-bottom: 1px solid #eaeaea;
    }
    padding: 0 3px;
    margin-top: 8px;
    cursor: pointer;
    h6 {
      color: #606060;
      @include font-regular($size: 13px, $lineHeight: "normal");
    }
    svg {
      color: $black;
      font-size: 2rem;
    }
  }

  &__notes {
    &.flight {
      border-top: 1px solid #dcdee0;
      margin: 6px -16px 0;
      padding: 0 16px;
    }
    p {
      margin: 10px 0;
      color: $black;
      @include font-regular($size: 14px, $lineHeight: "normal");
    }
  }

  &__links {
    @include flexbox;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    color: $font-dark-grey;
    a {
      text-decoration: underline;
      cursor: pointer !important;
      color: $font-dark-grey !important;
      @include font-regular($size: 12px, $lineHeight: "normal");
    }
  }

  &__cover {
    padding-bottom: 59.25%;
    background-color: $link-color;
    margin-bottom: 20px;
  }

  &__reservation {
    @include flexbox;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    color: black;
    button {
      height: auto;
      margin: 0 5px;
      &:first-child {
        padding: 8px 10px;
        @include font-regular($size: 12px, $lineHeight: "normal");
        color: $black;
        background-color: #563542;
      }
      &:last-child {
        padding: 8px 15px;
        background-color: $primary-button-bg;
        @include font-regular($size: 12px, $lineHeight: "normal");
        color: $black;
      }
    }
  }

  

  &__perks-inner {
    padding: 6px 20px;
    .hotelItem__label {
      &:first-child {
        margin-left: 0;
      }
      margin: 3px;
    }
  }

  &__notes-head {
    background-color: rgba($color: #f0b45d, $alpha: 1);
    border-radius: 8px;
 
  }

  &__perks-header {
    cursor: pointer;
    background-color: rgba($color: $primary-button-bg, $alpha: 1);
    border-radius: 8px;
    svg {
      color: $white;
      font-size: 1.6rem !important;
    }
    &:hover {
      background-color: rgba($color: $primary-button-bg, $alpha: 0.8);
    }
    h6 {
      color: $white;
      @include font-medium($size: 14px, $lineHeight: "normal");
    }
  }

  &__details {
    position: relative;
    padding-bottom: 20px;
    h3 {
      @include font-bold($size: 20px, $lineHeight: 1.3);
      padding-bottom: 10px;
      color: $black;
    }
    p {
      @include font-regular($size: 15px, $lineHeight: "normal");
      color: $font-dark-grey;
    }
  }

  &__schedule {
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);

    p:first-child {
      color: $black;
      @include font-medium($size: 14px, $lineHeight: "normal");
    }
    p:nth-child(2),
    p:nth-child(3) {
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: #606060;
    }
  }

  &__contact {
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    svg {
      color: #606060;
    }
    a.link {
      color: $primary-button-bg !important;
      &:hover {
        text-decoration: underline;
      }
    }
    a {
      color: #606060 !important;
      &:hover {
        text-decoration: underline;
      }
      @include font-regular($size: 13px, $lineHeight: "normal");
    }
    span {
      display: block;
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 1);
    }
    p {
      @include font-medium($size: 14px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 1);
    }
  }

  &__amenities-container,
  &__included-container {
    margin-bottom: 20px;
    span {
      padding-bottom: 10px;
      display: block;
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.7);
    }
  }

  &__amenities,
  &__included {
    @include flexbox;
    flex-wrap: wrap;
  }

  &__header {
    padding: 12px 20px;
    background-color: #f7f8f8;
    border-bottom: 1px solid #e6e8ea;
    border-top: 1px solid #e6e8ea;
    button {
      font-size: 14px;
      text-transform: capitalize;
    }
    h6 {
      color: $font-dark-grey;
      @include font-regular($size: 16px, $lineHeight: "normal");
    }
  }

  &__add {
    padding: 3.5px 5px;
    background-color: $primary-button-bg;
    border-radius: 2px;
  }
}

.roomList {
  &__item-field {
    flex: 1;
    padding: 0 6px;
    .drop-icon {
      position: absolute;
      top: 0;
      right: 0;
      height: 25px;
      width: 25px;
    }
    svg {
      fill: $black;
    }
  }

  &__item {
    border-bottom: 2px solid #e6e8ea;
    padding: 6px 30px;
    p {
      color: #606060;
    }
    &:nth-child(even) {
      background-color: #f7f8f8;
    }
    &:nth-child(odd) {
      background-color: $white;
    }
    .css-yk16xz-control {
      min-height: auto;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.4);
    }
    .form-control,
    .react-datepicker__input-container {
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
    }
    &:hover {
      .roomList__item-details a {
        @include font-regular(12px, "normal");
      }
    }
  }
  &__item-details-container {
    flex: 2;
  }
  &__item-details {
    @include flexbox;
    flex-direction: column;
    justify-content: center;
    h6 {
      @include font-medium(15px, "normal");
      color: $black;
    }
    a {
      @include font-regular(0, "normal");
      color: #606060;
      transition: 100ms ease-out font-size;
    }
  }
  &__item-avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    @include flexbox;
    img {
      object-fit: cover;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
  &__actions {
    background-color: #f7f8f8;
    padding: 6px 30px;
    border-bottom: 2px solid #e8eaeb;
    .btn.primary {
      background-color: #75a6cf;
    }
    .form-group {
      border-radius: 5px;
      border: 1px solid #ececec;
      background-color: $white;
      margin: 0;
      input {
        padding: 6px;
        text-shadow: none;
        border: 0;
        border-bottom: none !important;
        &::placeholder {
          color: rgba($color: $black, $alpha: 0.4);
        }
      }
    }
  }
}

.assignedHotelMember {
  &__root {
    &.passenger {
      &:nth-child(even) {
        background-color: #f7f8f8;
      }
      &:nth-child(odd) {
        background-color: #fefefe;
      }
      padding-right: 16px;
      padding-left: 16px;
      .flex-60 {
        flex: 60;
      }
      .flex-40 {
        flex: 40;
      }
    }
    color: $font-dark-grey;
    padding: 3px 0;
    border-bottom: 1px solid #e6e8ea;
    svg {
    }

    &:nth-child(odd) {
      background-color: #f7f8f8;
    }
    &:nth-child(even) {
      background-color: #fefefe;
    }
  }

  &__details {
    @include flexbox;
    align-items: center;
    margin-right: auto;
    &:hover {
      .assignedHotelMember__personal span {
        @include font-regular(12px, "normal");
      }
    }
    p {
      @include font-regular(14px, "normal");
    }
  }

  &__personal {
    @include flexbox;
    justify-content: center;
    flex-direction: column;
    margin-left: 12px;
    p {
      @include font-medium(15px, "normal");
      color: $black;
    }
    span {
      @include font-regular(0, "normal");
      transition: 300ms ease-out font-size;
    }
  }

  &__details-right {
    flex: 1;
  }

  &__name {
    margin-left: 10px;
    margin-right: auto;
    color: $black;
  }

  &__action {
    width: 30px;
    height: 30px;
    padding: 0 5px;
    cursor: pointer;
    img {
      transition: all 300ms ease-in;
    }
    &:last-child {
      margin-right: 0;
    }
    .rotated-180 {
      transition: all 300ms ease-in;
      transform: rotate(180deg);
    }
  }

  &__fields {
    @include flexbox;
    height: 0;
    overflow: hidden;
    transition: all 300ms ease-out;
    margin-top: 20px;
    &.show {
      height: auto;
      overflow: visible;
      transition: all 300ms ease-out;
    }
  }

  &__field {
    text-align: left;
    padding: 0 3px;
    p {
      @include font-light(13px, "normal");
      color: $black;
    }
    label {
      color: rgba($color: $black, $alpha: 1);
      @include font-regular($size: 13px, $lineHeight: "normal");
    }
    .form-group {
      position: relative;
      .drop-icon {
        top: 18px;
      }
    }
    .form-control {
      margin-top: 5px;
      height: 20px;
      font-size: 1em;
    }
    .css-yk16xz-control {
      min-height: 25px;
    }
    .css-1pahdxg-control {
      height: 25px;
    }
  }

  &__avatar {
    width: 45px;
    height: 45px;
    padding: 6px;
    overflow: hidden;
    @include flexbox;
    img {
      height: auto;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

[data-theme="dark"] {
  .roomList {
    &__item-field {
      .css-yk16xz-control {
        border-bottom: 1px solid #373c49 !important;
      }
      .form-group.input.form-control {
        &::placeholder {
          color: #5b5c60;
        }
      }
    }
    &__item {
      h6 {
        color: $white;
      }
      a {
        color: #9d9fa1;
        &:hover {
          color: $primary-button-bg;
        }
      }
      input.form-control {
        color: $white;
        border-bottom: 1px solid #373c49 !important;
        &:focus {
          border-bottom: 1px solid $primary-button-bg;
        }
        &::placeholder {
          color: rgba($color: $white, $alpha: 0.7);
        }
      }
      &:nth-child(even) {
        background-color: #252833;
      }
      &:nth-child(odd) {
        background-color: #2e3240;
      }
      border-bottom: 2px solid #51535c;
    }
    &__actions {
      .form-group {
        border: none;
      }

      .btn.primary {
        background-color: #366794;
      }

      input.form-control {
        background-color: #1d202a;
        color: $white;
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.7) !important;
        &:focus {
          border-bottom: 1px solid $primary-button-bg;
        }
      }
      background-color: #1d202a;
      border-bottom: 2px solid rgba($color: $white, $alpha: 0.2);
    }
  }
  .hotelForm-clear {
    opacity: 0.5;
  }

  .event-page .event-expennd-section .event-inside.hotel .form-control,
  .event-page
    .event-expennd-section
    .event-inside.hotel
    .react-datepicker-wrapper {
    border-bottom: 1px solid #373c49;
  }

  .hotelDetail {
    &__root {
      background-color: transparent;
    }

    &__information-inner {
      background-color: #2e3240;
    }

    &__title {
      color: $white;
    }

    &__schedule {
      background-color: transparent;
    }
    &__main {
      h3 {
        color: $almost-white;
      }
      p {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }
    &__details {
      h3 {
        color: $almost-white;
      }
      p {
        color: $font-dark-grey;
      }
    }

    &__amenities-container,
    &__included-container {
      color: rgba($color: $white, $alpha: 0.7);
      span {
        color: $font-dark-grey;
      }
    }

    &__contact {
      span {
        color: $font-dark-grey;
      }
      p {
        color: $almost-white;
      }
    }

    &__perks {
      color: $white;
      h6 {
        color: $almost-white;
      }
    }

    &__perks-header {
      border-bottom: 1px solid #353a47;
      background-color: $blue;
      svg {
        color: #9d9fa1;
      }
    }

    &__notes {
      &.flight {
        border-top: 1px solid #4f5568;
      }
      color: $white;
      h6 {
        color: $almost-white;
      }
    }
    &__notes-head {
      border-bottom: 1px solid #353a47;
      &.flight {
        border-bottom: 1px solid #4f5568;
      }
      h6 {
        color: $almost-white;
      }
      svg {
        color: #9d9fa1;
      }
    }

    &__reservation-qty {
      color: $font-dark-grey;
      h6 {
        color: $almost-white;
      }
    }

    &__header {
      background-color: #2e3240;
      border-top: none;
      border-bottom: 1px solid #353a47;
      h6 {
        color: $almost-white;
      }
    }

    &__reservation {
      button {
        color: $almost-white;
      }
    }

    &__schedule {
      p {
        &:first-child {
          color: $white;
        }
        &:last-child {
          color: rgba($color: $white, $alpha: 0.6);
        }
      }
      h5 {
        @include font-regular($size: 12px, $lineHeight: "normal");
        color: $white;
      }
    }
  }

  .assignedHotelMember {
    &__root {
      border-bottom: 1px solid #313542;
      &:first-child {
        background-color: #2e3240 !important;
      }
      &:nth-child(even) {
        background-color: #252833;
      }
      &:nth-child(odd) {
        background-color: #2e3240;
      }
    }
    &__name {
      color: $almost-white;
    }
    &__details,
    &__details-right {
      p {
        color: $almost-white;
      }
    }
    &__field {
      label {
        color: $almost-white;
      }
      p {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }
  }
}

.custom-control-label:before {
  background-color: $white;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: black;
}
