.activitiesList {
  height: 100%;
  &__header {
    padding: 8px 0;
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    h3 {
      @include font-regular(18px, normal);
      color: $black;
    }
  }

  &__buttons {
    button.secondary {
      background-color: $red;
    }
    button.secondary,
    button.primary {
      padding: 6px 12px;
      white-space: pre;
      @include font-medium(13px, normal);
      text-transform: capitalize;
    }
  }

  &__actions {
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    padding: 6px 18px;
    background-color: #c0d4e3;
    p {
      color: $black;
      @include font-medium(13px, normal);
    }
    span {
      @include font-regular(13px, normal);

      color: rgba($color: $black, $alpha: 0.6);
    }
    button {
      white-space: pre;
      @include font-regular(12px, normal);
      color: $font-dark-grey;
    }
  }

  &__heading {
    padding: 12px 18px;
    background-color: $hover-grey;
    p {
      @include font-regular(14px, normal);
      color: $font-dark-grey;
    }
  }
}

.activity {
  &__form {
    padding: 0 18px;
    .mce-content-body {
      height: 100px;
      max-height: 100px;
      overflow-y: scroll;
      overflow-x: auto;
      color: $black;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
      color: $black;
    }
    .drop-icon {
      position: absolute;
      top: 24px;
      right: 0;
      cursor: pointer;
      color: $black;
    }
    textarea {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
    input {
      height: auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .form-group {
      margin: 8px;
    }
    .css-yk16xz-control {
      border: none;
    }
    .css-2b097c-container {
      margin-left: 0;
      padding-left: 0;
      border: none;
      // border-bottom: 1px solid rgba($color: $black, $alpha: 0.09) !important;
    }

    .form-control.filled {
      background-color: transparent;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.09) !important;
      color: rgba($color: $black, $alpha: 0.8);
    }

    .show-more {
      text-decoration: underline;
      color: rgba($color: $black, $alpha: 0.8);
      cursor: pointer !important;
      &:hover {
        color: rgba($color: $black, $alpha: 0.8);
        text-decoration: underline;
      }
    }

    .col {
      margin: 8px 0;
      padding-left: 8px;
      padding-right: 8px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    .react-datepicker-wrapper {
      display: block;
      input {
        height: 40px;
        border-bottom: 1px solid rgba($color: $black, $alpha: 0.09) !important;
      }
    }
  }
}

.activitiesItem {
  cursor: pointer;
  border-bottom: 1px solid #e6e8ea;
  position: relative;
  &:nth-child(odd) {
    background-color: #ffffff;
  }
  &:nth-child(even) {
    background-color: #EFF0F4;
  }
  &:last-child {
    padding-bottom: 8px;
  }

  &__time {
    @include font-regular(11px, normal);
    color: $black;
    flex: 1;
    span {
      color: rgba($color: $black, $alpha: 0.6);
    }
  }
  &__actions {
    cursor: pointer;

    position: absolute;
    top: 0;
    right: 0;
    width: 7%;
    height: 100%;
    transition: opacity 300ms ease-out;
    @include flexbox;
    align-items: center;
    justify-content: center;
    dropdown {
      svg {
        cursor: pointer;
        margin: auto 5px;
      }
    }
  }
  &__alarm {
    margin-right: 18px;
    font-size: 1rem !important;
    color: #f57f4e;
    position: absolute;
    right: 25px;
  }
  &__details {
    flex: 7;
    padding-left: 10px;
    border-left: 1px solid $primary-button-bg;
    &:hover {
      svg.edit {
        opacity: 1;
        visibility: visible;
      }
    }
    p.top {
      @include font-medium(14px, normal);
      color: $black;
      max-width: 55ch;
      word-break: break-word;
      @media screen and (max-width: 1200px) {
        max-width: 42ch;
      }
    }

    .form-control {
      width: 70%;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
      padding-bottom: 3px;
      &::placeholder {
        color: rgba($color: $black, $alpha: 0.3);
      }
      &:focus {
        border-bottom: 1px solid rgba($color: $primary-button-bg, $alpha: 1);
      }
    }

    svg.check {
      color: $action-green;
      font-size: 1.3em;
      opacity: 1;
      visibility: visible;
      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }

    svg.edit {
      color: $font-dark-grey;
      font-size: 1em;
      margin-left: 6px;
      opacity: 0;
      visibility: hidden;

      &:hover {
        color: $orange;
      }
    }

    .text {
      cursor: default;
      display: block;
      width: 90%;
      word-break: break-all;
      @include font-regular(12px, normal);
      color: rgba($color: $black, $alpha: 0.6);
      a {
        color: $primary-button-bg;
      }
    }
  }
  .dropdown-menu {
    min-width: 10rem;
  }
  .dropdown-menu.show {
    width: 30%;
    background-color: $white;
  }
  .dropdown.show {
    .dropDownList {
      @include font-regular(14px, normal);
      padding: 6px 12px;
      cursor: pointer;
      &:hover {
        color: $primary-button-bg;
      }
    }
  }
  .dot-icon {
    position: relative;
    svg {
      color: $black;
    }
  }
}

[data-theme="dark"] {
  .activity__form {
    .css-yk16xz-control,
    input,
    textarea {
      border-bottom: 1px solid #373c49 !important;
      &::placeholder {
        color: #5e6063 !important;
      }
      color: $almost-white !important;
    }
    .show-more {
      color: $almost-white;
    }
  }
  .activitiesList {
    &__header {
      h3 {
        color: $white;
      }
      p {
        color: $white;
      }
    }
    &__actions {
      background-color: #34455d;
      p {
        color: #262a35;
      }
      span {
        color: rgba($color: $white, $alpha: 1);
      }
    }
    &__heading {
      background-color: $blue-dark-theme;
      p {
        color: $white;
      }
    }
  }

  .activitiesItem {
    border-bottom: 2px solid #353945;
    &:nth-child(odd) {
      background-color: #2e3240;
    }
    &:nth-child(even) {
      background-color: #252833;
    }
    svg {
      color: $white;
    }
    svg.activitiesItem__alarm {
      color: #f57f4e;
    }
    &__time {
      color: $white;
      span {
        color: #9d9fa1;
      }
    }
    &__details {
      .form-control {
        color: $white;
      }
      p {
        color: $almost-white;
      }
      span {
        color: rgba($color: $white, $alpha: 0.6);
      }
    }
  }
}
