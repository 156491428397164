.dashboard {
  &__layout {
    
    &.organization__list {
      .organizationSidebar__root {
        left: 0;
      }
      .sidebar,
      .sidebar.slim {
        left: 80px;
      }
      .content.slim {
        padding-left: 160px;
      }
      .eventNavigation__header {
        left: 160px;
      }
      .content.expanded {
        padding-left: 290px;
     }
      .content.expanded .eventNavigation__header {
        left: 290px;
        width: calc(100% - 290px);
        
      }
    }
  }
}

.dashboard-screen {
  @include flexbox;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
 

  


  .dashboard-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    p {
      font-size: 20px;
      font-weight: 500;
      color: $black;
    }
  }
}

.dashboard-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  left: 80px;
  height: 100%;
  overflow: hidden;
  &.expanded {
    left: 210px;
  }
}

.itineraries-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  left: 80px;
  height: 100%;
  overflow: hidden;
  -webkit-box-shadow: 9px 0px 10px -8px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 9px 0px 10px -8px rgba(0, 0, 0, 0.55);
  box-shadow: 9px 0px 10px -8px rgba(0, 0, 0, 0.55);
  &.expanded {
    left: 210px;
  }
}

.todo-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 700px;
}

.notifications-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  left: 80px;
  height: 100%;
  width: 700px;
  overflow: hidden;
  .event-expennd-section {
    min-width: 700px;
    width: 700px;
  }
  &.expanded {
    left: 210px;
  }
}

.organization-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  right: 0;
   height: 100%;
}

.production-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  left: 80px;
  height: 100%;
}

.ground-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
}
.hotel-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  .react-datepicker-popper {
    left: -65px !important;
  }
}
.contact-transparent {
  background: rgba($color: $black, $alpha: 0.7);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
}

[data-theme="dark"] {
  .dashboard-screen {
    background: url("../../images/background.jpg");
    .dashboard-content p {
      color: $white;
    }
  }
  .dashboard {
    &__layout {
      background-color: $header-dark;
    }
  }
}
