/* Time drodown page css start here */
.time-dropmenu {
  @include flexbox;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 210px;
  max-height: 130px;
  background: $page-bg;
  position: absolute;
  z-index: 999999999;
  padding: 20px;
  .time-section {
    @include flexbox;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50px;
    height: auto;
    max-width: 50px;
    .arrow {
      cursor: pointer;
    }
    .current-status {
      @include flexbox;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border: 1px solid rgba($color: $white, $alpha: 0.1);
      border-radius: 2px;
      background: transparent;
      margin: 5px 0;
      p {
        @include font-regular(15px, normal);
        color: $white;
      }
    }
  }
}