.itinerariesItem {
  &__root {
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 15px;
    border-bottom: 1px solid $divider-grey;
    transition: background-color 400ms ease-in;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0px;
      width: 0;
      height: calc(100%);
      background-color: $primary-button-bg;
      transition: width 200ms ease-in;
    }
    &.active {
      &:before {
        width: 5px;
      }
      background-color: $hover-grey;
    }
    input {
      &:focus {
        color: $black;
        border-bottom: 1px solid rgba($color: $font-dark-grey, $alpha: 0.1);
      }
    }
  }

  &__details {
    .react-datepicker__input-container {
      input {
        border: none;
        background-color: transparent;
      }
    }
    width: 80%;
    &:hover {
      svg {
        visibility: visible;
        opacity: 1;
      }
    }

    input {
      @include font-regular($size: 15px, $lineHeight: "normal");
      color: $black;
      border-bottom: 1px solid rgba($color: $black-bg, $alpha: 0.3);

      &:focus {
        border-bottom: 1px solid rgba($color: $black-bg, $alpha: 0.5);
      }
    }
    p {
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: $font-grey;
    }
    p.name {
      @include font-regular($size: 15px, $lineHeight: "normal");
      color: $black;
    }
    svg {
      color: rgba($color: $font-dark-grey, $alpha: 0.5);
      font-size: 1rem;
      margin-left: 6px;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
    }
    svg.check {
      color: #1ba863;
    }
  }

  &__actions {
    position: relative;
    height: 53px;
    @include flexbox;
    align-items: center;
    cursor: pointer;
  }

  &__more {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    width: 25px;
    &:focus {
      outline: none;
    }
  }

  &__menu {
    .dropDownList {
      padding: 0.15rem;
      &.delete {
        color: $red;
      }
      &:hover {
        color: $link-color;
      }
    }

    .dropdown-menu.show {
      background-color: $white;
      top: 100%;
      min-width: 13rem;
      // height: 220px;
      padding: 0;
      overflow-y: auto;
      .dropDownList {
        padding: 6px 9px;
      }
    }
  }
}

.itineraries__form {
  background-color: $white;
  padding: 12px !important;
  label {
    color: $black;
  }
  input {
    color: $black !important;
    border-bottom: 1px solid rgba($color: $font-dark-grey, $alpha: 0.3) !important;
  }
  &__header {
    background-color: #272a36;
  }
}

.itineraries__list {
  .popup-content {
    border: none !important;
    background-color: $white !important;
  }
}

[data-theme="dark"] {
  .itineraries__form {
    background-color: #21242f;
    padding: 12px !important;
    label {
      color: $almost-white;
    }
    input {
      color: $white !important;
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.3) !important;
    }
    &__header {
      background-color: #272a36;
    }
  }

  .itineraries__list {
    .popup-content {
      border: none !important;
      background-color: $page-bg !important;
    }
  }
  .itinerariesItem {
    &__root {
      border-bottom: 1px solid $border-lighter-grey;
      input {
        color: $white;
        &:focus {
          color: $white;
          border-bottom: 1px solid rgba($color: $font-dark-grey, $alpha: 0.1);
        }
      }
      &:hover,
      &.active {
        background-color: $dark-hover;
      }
    }

    &__details {
      width: 40%;
      h6,
      input {
        color: $white;
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);

        &:focus {
          border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
        }
      }
      p {
        color: $font-grey;
      }
      p.name {
        @include font-regular($size: 15px, $lineHeight: "normal");
        color: $white;
      }
      svg {
        color: rgba($color: $font-dark-grey, $alpha: 0.5);
      }
      svg.check {
        color: #1ba863;
      }
    }

    &__menu {
      .dropdown-menu.show {
        background-color: $black-bg;
        top: 100%;
        min-width: 13rem;
        padding: 0;
        .dropDownList {
          &.delete {
            color: $red;
          }
          padding: 6px 9px;
          color: rgba($color: $white, $alpha: 0.8);
          &:hover {
            color: $primary-button-bg;
          }
        }
      }
    }

    &__menu,
    &__actions {
      svg {
        color: $white;
      }
    }
  }
}

.template-item {
  @include font-medium($size: 15px, $lineHeight: "normal");
  color: $black;
  padding: 6px 0;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
