.img-responsive {
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}
.close-icon d-none {
  display: flex;
  justify-content: flex-end;
  .close-bg {
    background: rgba($color: $white, $alpha: 0.4);
    border-radius: 100%;
    width: 22px;
    height: 22px;
    line-height: 1.2;
    text-align: center;
    cursor: pointer;
  }
}
.img-responsive-venue {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.image-main {
  text-align: center;
}
.img-responsive-input {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.event-detail-page {
  height: auto;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
  .listSection {
    padding: 10px;
    width: 55%;
    min-height: 100%;
    height: calc(100vh - 113px);
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 0;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba($color: $white, $alpha: 0);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #888, $alpha: 0);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .modalSection {
    width: 45%;
    position: relative;
    color: $white;
    min-height: 100%;
    height: calc(100vh - 133px);
    padding: 10px;
    overflow-y: auto;
    top: 10px;
    background: rgba($color: #fafafa, $alpha: 1);
    ul {
      top: 120px;
      left: calc(60% + 32px);
      position: fixed;
      width: calc(40% - 32px);
      height: 100%;
      z-index: 1;
      background-color: transparent;
      &:hover {
        background: transparent;
      }
    }

    .sidebar__item {
      width: 100%;
      padding: 20px;
      height: 100%;
      &.is-current {
        display: block;
      }
      &:hover {
        cursor: default;
      }
      a {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    .sidebar-flight {
      padding: 10px 0;
    }
    .flight-sidebar {
      width: 100%;
      padding: 0;
      height: calc(100vh - 125px);
      &:hover {
        background: transparent;
      }
      .sidebar-header {
        @include flexbox;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow-x: auto;
        background: $dark-light-theme;
        border-bottom: 2px solid #4084bf;
        .header-section {
          width: 100%;
          min-width: 230px;
          text-align: center;
          white-space: nowrap;
          padding: 0 15px 15px;
          h4 {
            @include font-medium(14px, normal);
            color: $black;
          }
          .active {
            color: $blue;
          }
        }
      }

      .flight-body {
        padding: 20px 20px 0;
        .hideshow-info {
          @include flexbox;
          p {
            @include font-regular(12px, normal);
            width: auto;
            cursor: pointer;
            color: $black;
            text-decoration: underline;
          }
        }
        .info-text {
          padding-bottom: 15px;
          border-bottom: 1px solid rgba($color: $white, $alpha: 0.07);
          p {
            @include font-regular(11px, normal);
            color: $font-dark-grey;
          }
          h4 {
            @include font-regular(14px, 1.47);
            color: $black;
          }
        }
      }
      .flight-detail {
        .detail-inside {
          padding: 15px 20px;
          .detail-main {
            @include flexbox;
            align-items: center;
            justify-content: space-between;
            .flight_status {
              h2 {
                @include font-regular(30px, normal);
                color: $black;
              }
              h4 {
                @include font-regular(14px, normal);
                color: rgba($color: $black, $alpha: 0.8);
              }
              p {
                @include font-regular(13px, normal);
                color: rgba($color: $black, $alpha: 0.6);
              }
            }
          }
          .button-section {
            @include flexbox;
            align-items: center;
            width: 100%;
            padding: 20px 0;
            button {
              @include font-medium(14px, normal);
              padding: 4px 10px;
              border-radius: 15px;
              background: $light-green;
              color: $white;
              border: none;
              &:focus {
                outline: none;
              }
            }
            .link-section {
              @include font-regular(14px, normal);
              color: rgba($color: $white, $alpha: 0.5);
              text-decoration: underline;
              padding-left: 10px;
              cursor: pointer;
            }
          }
          .info-text {
            padding-bottom: 15px;
            p {
              @include font-regular(13px, normal);
              color: rgba($color: $black, $alpha: 0.7);
              padding-bottom: 5px;
            }
            h4 {
              @include font-regular(14px, 1.47);
              color: $black;
            }
            span {
              padding-left: 5px;
            }
          }
        }
        .member-section {
          @include flexbox;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px 25px;
          background: rgba($color: $black, $alpha: 0.1);
          .member-text {
            @include font-regular(12px, normal);
            color: $white;
            width: 100%;
          }
          .member_btn {
            @include flexbox;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            &_remove {
              @include font-regular(13px, normal);
              padding: 8px 10px;
              margin-right: 10px;
              border-radius: 2px;
              background: $brown;
              color: $black;
            }
            &_add {
              @include font-regular(13px, normal);
              padding: 8px 15px;
              border-radius: 2px;
              background: $blue;
              color: $black;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1124px) {
  body{
    // overflow: hidden;
    height: 100vh;
  }
  .dashboard-screen{
    height: 100vh;
    margin: 0 !important;

  }
  .event-detail-page {
    height: 100vh;
    flex-direction: column-reverse;
    align-items: flex-start;

    .modalSection {
      width: 100%;
      position: fixed;
      color: $white;
      min-height: 40%;
      height: 30%;
      padding: 10px;
      top: 100px;
      overflow: hidden;
    }
    .listSection {
      position: absolute;
      top: calc(40% + 105px);
      padding: 0;
      width: 100%;
      min-height: 46%;
      height: calc(57vh - 113px);
  }
}
}

.resource {
  background-color: $white;
  min-height: 250px;
  /* border: 1px solid $divider-grey;
*/
  border-radius: 2px;
  margin-bottom: 20px;

  .css-2b097c-container {
    border: none;
    height: auto;
  }
  svg {
    color: #606060;
  }
  &__header {
    @include flexbox;
    justify-content: space-between;
    align-items: center;

    color: $black;
    min-height: 38px;
    h4 {
      @include font-regular($size: 14px, $lineHeight: "normal");
    }
  }
  .spinner-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 250px;
  }

  &__body {
    padding: 0;
    color: $black;
    .form-group .react-datepicker__input-container {
      padding-top: 0;
    }
    .drop-icon {
      top: 18px;
    }
    ul {
      width: 100%;
      height: auto;
      top: 70px;
      left: 0;
      z-index: 40;
    }
    .form-group {
      margin: 0;
      margin-top: 2px;
      .css-yk16xz-control {
        border: none;
      }
      .css-1n7v3ny-option {
        &:hover {
        }
      }
      input {
        color: $black;
      }
      label {
        color: #8a8a8a;
      }
      .css-1pahdxg-control {
        min-height: 34px;
        &:hover {
          border: none;
          border-bottom: none;
        }
      }
      .css-1hwfws3 {
        margin-top: -9px;
        &:hover {
          margin-top: -9px;
        }
      }
      .css-1hb7zxy-IndicatorsContainer {
        margin-top: -9px;
      }
      .css-4ljt47-MenuList {
        padding: 0;
      }
      .form-control {
        border: none;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $black;
        padding-bottom: 0;
      }
      .note-scroll {
        resize: vertical;
        border: none;
        text-overflow: ellipsis;
        overflow: auto;
        padding: 3px;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        box-shadow: inset 0px 0px 3px 0px rgba(155, 156, 157, 0.3);
      }
    }
    .row {
      padding: 0 3px 8px;
      border-bottom: 1px solid $border-grey;
      align-items: center;
      max-height: 57px;
      &:last-child {
        max-height: initial;
      }
    }
    #react-collapsed-toggle-1,
    #react-collapsed-toggle-2,
    #react-collapsed-toggle-3,
    #react-collapsed-toggle-4,
    #react-collapsed-toggle-5,
    #react-collapsed-toggle-6,
    #react-collapsed-toggle-7,
    #react-collapsed-toggle-8,
    #react-collapsed-toggle-9,
    #react-collapsed-toggle-10 {
      @include font-regular($size: 13px, $lineHeight: "normal");
      background: $primary-button-bg;
      border: none;
      width: 95px;
      color: $white;
      border-radius: 2px;
      padding: 3px 12px;
      margin: 10px 28px;
      white-space: pre;
      &:focus {
        outline: none;
      }
    }
    // .venue-general {
    //   .form-control {

    //   }
    // }
  }
  &_inside {
    border-radius: 2px;
    padding-top: 10px;
    position: relative;
    transition: background-color 100ms ease-in;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: calc(100% - 0px);
      background-color: $primary-button-bg;
      transition: width 200ms ease-in;
    }
    &.active,
    &:hover {
      &:before {
        width: 5px;
      }
      background-color: $hover-grey;
    }
    h4 {
      @include font-medium($size: 14px, $lineHeight: "normal");
    }
    span {
      @include font-regular($size: 15px, $lineHeight: "normal");
      color: rgba($color: $white, $alpha: 0.8);
      padding-left: 0;
    }
    p {
      @include font-regular($size: 13px, $lineHeight: "normal");
      color: $font-dark-grey;
    }
    .stop {
      @include font-regular(10px, 1);
      color: #f29c32;
      padding-top: 4px;
    }
    .img-right {
      @include flexbox;
      justify-content: flex-end;
      max-width: 187px;
      padding-left: 0;
    }
    .col {
      white-space: nowrap;
      .img-container-flight {
        @include flexbox;
        margin-right: 50px;
        .flight-img {
          height: 20px;
          margin: 0px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          position: relative;
          width: 18px;
          img {
            background-color: #596376;
            border: 2px solid #1f2532;
            border-radius: 100px 100px 100px 100px;
            color: #ffffff;
            display: block;
            font-family: sans-serif;
            font-size: 12px;
            font-weight: 100;
            height: 24px;
            line-height: 24px;
            text-align: center;
            width: 24px;
          }
        }
      }
    }
    .flight-icon {
      width: 20px;
      max-width: 80px;
      img {
        height: 32px;
      }
    }
    .team-section {
      @include flexbox;
      width: 100%;
      padding-left: 14px;
      margin-bottom: 8px;
      .box {
        width: auto;
        padding: 3px 10px;
        background: rgba($color: $blue, $alpha: 0.7);
        border-radius: 12px;
        p {
          font-size: 12px;
          line-height: 1.2;
          color: $white;
        }
      }
    }

    .dot-icon {
      padding-right: 15px;
      img {
        cursor: pointer;
      }
    }
    .flight-detail {
      max-width: 350px;
      white-space: normal;
      span {
        color: rgba($color: $black, $alpha: 0.75);
        @include font-light(12px, "normal");
      }
    }
  }
  &_border {
    border-top: 1px solid #4084bf;
  }
  &_button-right {
    @include flexbox;
    align-items: center;
   
    .search-filter {
      width: 135px;
      height: 25px;
      margin-right: 5px;
      text-align: center;
      border-radius: 2px;
      background: rgba($color: $black, $alpha: 0.05);
      input {
        @include font-regular(11px, normal);
        color: rgba($color: $black, $alpha: 0.5);
        height: 25px;
        padding: 2px 10px;
        &::placeholder {
          color: rgba($color: $black, $alpha: 0.5);
        }
        &:focus {
          border: none;
        }
      }
    }
    .template-section {
      @include font-regular(13px, normal);
      padding: 5px 15px;
      margin-right: 0;
      text-align: center;
      border-radius: 2px;
      cursor: pointer;
      background: rgba($color: $blue, $alpha: 0.1);
    }
    .filter-icon {
      width: 45px;
      height: 28px;
      margin-right: 10px;
      text-align: center;
      border-radius: 7px;
      cursor: pointer;
      @include flexbox;
      align-items: center;
      justify-content: center;
      background: rgba($color: $black, $alpha: 0.05);
      &.green {
        background: #4ad196;
      }
      &.peach {
        background: #edc4c0;
      }
    }
  }

  .react-datepicker-wrapper {
    padding-bottom: 0;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    display: block;
  }
  .CSS-4ljt4j-MenuList {
    padding: 0;
  }
  /* dropdown plugin */
  .css-yk16xz-control {
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid #373c49;
    min-height: 34px;
  }
  .css-1pahdxg-control {
    background: transparent !important;
    border: none !important;
    border-radius: 0;
    // border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    box-shadow: none !important;
  }

  .css-26l3qy-menu {
    min-height: 100%;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    .css-1n7v3ny-option {
      font-size: 14px;
      color: $black;
      &:hover {
        font-size: 14px;
      }
    }
  }
  .css-b8ldur-Input {
    pointer-events: none;
    height: auto;
  }
  .css-1uccc91-singleValue {
    color: $white;
  }
  .css-1wa3eu0-placeholder {
    font-size: 14px;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1hwfws3 {
    padding: 0;
    &:focus {
      padding: 0;
      margin: 0;
    }
  }
  .css-tlfecz-indicatorContainer {
    &:hover {
      color: $white;
    }
  }
  .css-1gtu0rj-indicatorContainer {
    color: $white;
  }
  .css-1n7v3ny-option {
    color: $black;
    background: rgba($color: $white, $alpha: 0.2);
    cursor: pointer;
  }
  .css-9gakcf-option {
    background: rgba($color: $white, $alpha: 0.2);
  }
  .css-1gtu0rj-indicatorContainer {
    padding: 0;
  }

  input,
  .css-yk16xz-control,
  .react-datepicker-wrapper,
  textarea {
    border-bottom: none;
    resize: none;
  }
}

.form-control.text-area.note-scroll {
  border: none;
  text-overflow: ellipsis;
  overflow: auto;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: inset 0px 0px 3px 0px rgba(155, 156, 157, 0.3);
  color: black;
}

.venueDetail {
  position: relative;
  padding: 0 0 20px 0;
  &__note {
    border: none;
    text-overflow: ellipsis;
    overflow: auto;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 8px;
    box-shadow: inset 0px 0px 3px 0px rgba(155, 156, 157, 0.3);
    a,
    p {
      color: #696969;
    }
  }
  &__detail-inner {
    background-color: #fff;
    padding: 12px;
  }
  &__weather {
    position: relative;
    z-index: 1;
  }
  &__text {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    left: 0;
    padding: 20px;
  }
  .weather-detais {
    @include flexbox;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 4px solid $link-color;
    width: 100%;
    background: $white;
    padding: 8px;
    .weather-section {
      width: 100%;
      height: 100%;
      color: $black;
      text-align: center;
      border-right: 1px solid rgba($color: $black, $alpha: 0.5);
      &:last-child {
        border: none;
      }
      .weather-img {
        padding: 10px 0;
      }
      h3 {
        font-size: 10px;
      }
      p {
        font-size: 10px;
        padding-bottom: 4px;
      }
    }
  }
  .weather-status {
    padding-left: 0;
  }
  &__degree {
    position: relative;
    height: 16px;
    width: 16px;
    border: 4px solid $white;
    border-radius: 50%;
    margin-right: 18px;
    margin-left: 4px;
  }
  &__inner {
    display: flex;
    flex-direction: row;

    h3 {
      @include flexbox;
      @include font-regular($size: 48px, $lineHeight: "normal");
      color: $white;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    }
    p {
      &:first-child {
        @include font-regular($size: 14px, $lineHeight: "normal");
      }
      &:last-child {
        @include font-regular($size: 20px, $lineHeight: "normal");
      }
    }
    .weather-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .loaction-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__map {
    margin: 20px auto;
    height: 20rem;
    background-color: rgba($color: #000000, $alpha: 0.2);
    box-shadow: 0px 0px 12px 0px rgba(155, 156, 157, 0.3);
  }
  .map-nodata {
    @include flexbox;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    p {
      font-size: 24px;
    }
  }
  &__details {
    margin-top: 20px;
  }

  &__captionText {
    @include font-medium($size: 14px, $lineHeight: "normal");
    color: $black;
    opacity: 1;
    @include flexbox;
    align-items: center;
    &:hover {
      svg {
        opacity: 1;
        visibility: visible;
      }
    }
    svg {
      margin-left: 3px;
      font-size: 1rem;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
    }
  }

  &__bodyText {
    @include font-regular($size: 15px, $lineHeight: 1.47);
    color: #696969;
    margin-bottom: 10px;
    a {
      color: $primary-button-bg;
    }
  }

  &__thumbnails {
    margin: 20px 0;
    @include flexbox;
    flex-direction: row;
    align-items: center;
  }

  &__thumbnail {
    margin: 0 6.5px;
    height: 80px;
    width: 80px;
    margin-left: 10px;
    border-radius: 2px;
    background-color: $dark-theme;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      .venueDetail__zoom {
        opacity: 1;
        visibility: visible;
      }
    }
    &:hover {
      .venueDetail__cross {
        visibility: visible;
        opacity: 1;
        // position: absolute;
        // top: 0;
        // right: 2;
      }
      .close-icon d-none-venue {
        position: absolute;
        right: 6px;
        top: 4px;
        .close-bg {
          background: rgba($color: $black, $alpha: 0.3);
          border-radius: 100%;
          height: 25px;
          width: 25px;
          border-radius: 4px;
          line-height: 1.2;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  &__zoom {
    position: absolute;
    height: 25px;
    width: 25px;
    bottom: 5px;
    right: 5px;
    background-color: rgba($color: $black, $alpha: 0.3);
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    z-index: 5;
    pointer-events: none;
  }
  .venue-dummyimg {
    @include flexbox;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80px;
    height: 80px;
    margin-right: 6.5px;
    background: transparent;
    border: 1px solid rgba($color: $white, $alpha: 0.1);
    border-style: dashed;
    text-align: center;
    position: relative;
  }
  .venuebtn-section {
    @include flexbox;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
    .venue-button {
      margin-right: 10px;
      margin-bottom: 10px;
      button {
        background: rgba($color: $white, $alpha: 0.2);
        border-radius: 2px;
        width: 180px;
        height: 60px;
        color: $white;
        border: none;
        &:focus {
          outline: none;
        }
        p {
          @include font-regular($size: 15px, $lineHeight: "normal");
          margin-bottom: 2px;
        }
        span {
          @include font-regular($size: 13px, $lineHeight: "normal");
          color: rgba($color: $white, $alpha: 0.7);
        }
      }
      .active {
        background: $primary-button-bg;
      }
    }
  }
}

.gm-style-iw-t button {
  display: none !important;
}

.sidebarEvents {
  &__root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dark-darker-theme;
  }
  &__header {
    padding: 12px 18px;
    h3 {
      @include font-regular($size: 26px, $lineHeight: "normal");
      color: $white;
    }
  }
}

.venue-general {
  .popup-content {
    background-color: transparent !important;
    border: none !important;
  }
}

.sidebarEvents {
  
  &__item {
    cursor: pointer;
    &:hover {
      .text-section {
        h3,
        a {
          color: $primary-button-bg !important;
        }
      }
    }
    padding: 8px 0;
    .right-icon {
      margin-top: 6px;
    }
    .icon-section {
      .MuiSvgIcon-root {
        font-size: 1rem;
        height: 22px;
      }
    }
  }
}

.venues {
  &__list {
    background-color: $dark-darker-theme;
    width: 600px;
    height: 400px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 18px;
  }
  &__item-edit {
    width: 30px;
    height: 30px;
    border: 2px solid $primary-button-bg;
    background-color: transparent;
    border-radius: 50%;
    @include flexbox;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 1rem;
      color: $black;
    }
  }
  &__item {
    padding: 8px 0;
    cursor: pointer;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.05);
    @include flexbox;
    align-items: center;
    justify-content: space-between;
    &:hover {
      h4 {
        color: $primary-button-bg;
      }
    }
    h4 {
      @include font-regular($size: 14px, $lineHeight: "normal");
      color: $black;
    }
    p {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $black, $alpha: 0.4);
      margin-top: 3px;
      max-width: 55ch;
    }
  }
}

.eventType {
  &__input {
    .popup-content {
      background-color: transparent !important;
      border: none !important;
    }
  }
  &__list {
    background-color: $dark-darker-theme;
    width: 220px;
    height: 400px;
    position: relative;
    left: -7px;
  }
  &__item {
    padding: 8px 0;
    @include font-regular($size: 14, $lineHeight: "normal");
    color: $black;
    cursor: pointer;
    &:hover {
      color: $primary-button-bg;
    }
  }
}
.note .actionIcons svg {
  height: 16px;
}
.note {
  padding: 18px;
  min-height: 210px;
  border-radius: 2px;
  input.form-control,
  textarea {
    border-bottom: rgba($color: $white, $alpha: 0.1);
    color: $black-bg;
    text-shadow: none;
    &::placeholder {
      color: rgba($color: $black, $alpha: 0.4) !important;
    }
  }
  .note__drag {
    button {
      position: relative;
      z-index: 10;
      outline: none;
      border: none;
      background-color: none;
      padding: 0;
      color: $black;
      &:active,
      &:hover {
        background: none !important;
      }
    }
  }
  &.maximized {
    height: 75vh;
    width: 50vw !important;
    left: 10% !important;
    top: 100px !important;
    transform: translate(0, 0) !important;
    .note__drag {
      display: none;
    }
    .note__drag svg {
      height: 24px !important;
    }
  }
  &__pin {
    position: absolute;
    top: 9px;
    right: 9px;
    z-index: 55;
    svg {
      font-size: 0.9em;
      cursor: pointer;
    }
  }
  &__drag {
    cursor: grabbing;
    display: flex;
   
  }
  h6 {
    @include font-bold($size: 16px, $lineHeight: "normal");
    color: $black;
  }
  h6.draggable {
    flex: 1;
    @include font-medium($size: 14px, $lineHeight: "normal");
    background-color: rgba($color: #000000, $alpha: 0.05);
    padding: 4px 8px;
  }
  &__button {
    background-color: rgba($color: #000000, $alpha: 0.05);
    font-size: 20px;
    cursor: pointer;
  }
  &__editor {
    margin: 12px 0 12px 0;
    padding: 0 6px;
    background-color: rgba($color: #000000, $alpha: 0);
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &__text {
    height: 150px;
  }
  &__toolbar {
    margin: 8px 0;
    svg {
      font-size: 1.2em;
      cursor: pointer;
      margin: 0 3px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  p {
    margin-top: 6px;
    @include font-regular($size: 13px, $lineHeight: 20px);
    color: $black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    /*height: 150px;*/
    overflow-y: hidden;
  }
  p.note__event {
    margin-bottom: 8px;
    height: auto;
    min-height: 22px;
  }
  &__gallery {
    margin-top: 10px;
  }
  &__gallery-item {
    cursor: pointer;
    width: 30px;
  }
  &__author {
    @include font-bold($size: 12px, $lineHeight: "normal");
    font-style: italic;
    color: $black;
  }
  &__editor {
    img {
      max-width: 80%;
      max-height: 80%;
    }
  }
}

.viewer-documents {
  width: 50vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  iframe {
    height: 100%;
    width: 100%;
  }
  .image-viewer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: scroll;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.css-4ljt47-MenuList {
  background: $white;
  .css-9gakcf-option {
    color: $black;
    background-color: rgba($color: $black, $alpha: 0.06);
    font-size: 14px;
  }
  .css-1n7v3ny-option,
  .css-yt9ioa-option {
    color: $black;
    &:hover {
      background-color: rgba($color: $black, $alpha: 0.06);
    }
  }
}
.form-group.visibility {
  .css-26l3qy-menu,
  .css-4ljt47-MenuList {
    opacity: 0;
    visibility: hidden;
  }
}
