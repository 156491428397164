/* Round page css start here */
.flight-page {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  background: transparent;
  min-height: 100%;
  /* right section start here */
  .flight-expand-section {
    min-width: 500px;
    width: 500px;
    height: 100vh;
    overflow-y: auto;
    background: $dark-light-theme;
    .flight-head {
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      padding: 17px 30px;
      background: rgba($color: $black, $alpha: 0.1);
      width: 100%;
      .text-left {
        width: 100%;
        h1 {
          @include font-bold(15px, normal);
          color: $white;
        }
        p {
          @include font-regular(13px, 1.69);
          color: rgba($color: $white, $alpha: 0.7);
        }
        .line {
          width: 1px;
          height: 10px;
          margin: 0 10px;
          border: 0.5px solid rgba($color: $white, $alpha: 0.2);
        }
      }
      .close-icon d-none {
        @include flexbox;
        justify-content: flex-end;
        width: auto;
        .close-round {
          @include flexbox;
          align-items: center;
          justify-content: flex-end;
          padding: 7px;
          background: rgba($color: $white, $alpha: 0.1);
          border-radius: 100%;
          cursor: pointer;
        }
      }
    }
    .flight-main {
      padding-bottom: 20px;
      .flight-status {
        @include flexbox;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .way-section {
          @include flexbox;
          align-items: center;
          width: 100%;
          padding: 30px;
          .radio {
            @include flexbox;
            align-items: center;
            margin-right: 40px;
            img {
              cursor: pointer;
            }
            label {
              @include font-regular(14px, normal);
              width: auto;
              cursor: pointer;
              padding-left: 5px;
            }
          }
        }
        .active {
          border-bottom: 2px solid $blue;
        }
      }
      .flight-inside {
        width: 100%;
        padding: 0 30px 0;
        .row {
          .col-md-6 {
            padding: 0;
          }
          .p-right {
            padding-right: 10px;
          }
          .p-left {
            padding-left: 10px;
          }
        }
        form {
          padding-top: 30px;
          .form-group {
            position: relative;
            margin-bottom: 25px;
            .react-datepicker-popper {
              transform: translate3d(14px, 56px, 0px) !important;
            }
            .form-control {
              @include font-regular(14px, 1.67);
              color: $white;
              padding: 0;
              padding-bottom: 10px;
              position: relative;
              border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
              &:focus {
                border-bottom: 1px solid rgba($color: $link-color, $alpha: 1);
                color: $white;
              }
            }
            label {
              @include font-regular(12px, normal);
            }
          }
        }
        .stop-section {
          .head-text {
            @include font-regular(12px, normal);
            color: rgba($color: $white, $alpha: 0.6);
          }
          .flight-stop {
            @include flexbox;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 10px;
            border-bottom: 1px solid rgba($color: $white, $alpha: 0.04);
            background: rgba($color: $white, $alpha: 0.02);
            padding: 20px 30px;
          }
          .time-section {
            @include flexbox;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: rgba($color: $white, $alpha: 0.02);
            padding: 16px 30px;
            .time-left {
              @include font-regular(13px, normal);
              color: rgba($color: $white, $alpha: 0.6);
            }
            .link-section {
              @include flexbox;
              align-items: center;
              .edit-link {
                @include font-regular(15px, normal);
                color: rgba($color: $link-color, $alpha: 1);
                text-decoration: underline;
                padding-right: 10px;
                cursor: pointer;
              }
              .delete-link {
                @include font-regular(15px, normal);
                color: rgba($color: $red, $alpha: 1);
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          .flight-detail {
            width: auto;
            color: $white;
            h2 {
              @include font-medium(20px, normal);
            }
            p {
              @include font-regular(14px, normal);
              color: rgba($color: $white, $alpha: 0.8);
            }
            .date {
              @include font-regular(13px, normal);
              color: rgba($color: $white, $alpha: 0.6);
            }
          }
        }
        .submit-button {
          @include flexbox;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          padding-top: 35px;
          width: 100%;
          .secondary {
            width: auto;
            padding: 12px 42px;
            margin-right: 20px;
            background: $secondary-button-bg;
          }
          .brown {
            width: auto;
            padding: 12px 42px;
            margin-right: 20px;
            color: $white;
            background: rgba($color: $brown-light, $alpha: 0.25);
            &:focus {
              outline: none;
            }
          }
        }
      }
      .flight-info {
        width: 100%;
        padding: 13px 30px;
        margin-bottom: 10px;
        background: #1c1f28;
        P {
          @include font-regular(12px, normal);
          color: $white;
        }
      }
    }
  }
}

[data-theme="dark"] {
  .modalSection {
    .flight-body {
      p,
      h4 {
        color: white !important;
      }
    }
  }
  .event-detail-page .modalSection .flight-detail {
    .detail-inside .detail-main {
      h2,
      p {
        color: $white !important;
      }
    }
    .info-text {
      p,
      h4 {
        color: $font-dark-grey !important;
      }
    }
  }
}
