/* reset password page css start here */
.login-background {
  width: 100%;
  position: relative;
  
  @media screen and (max-width: 767px) {
    height: 100%;
    display: flex;
    align-self: center;

  }
  // background: url("../../images/login-bg.png") no-repeat;
  // background-size: cover;
  // background-position: center;
  .login-content {
    width: 100%;
    height: 100%;
    padding: 30px 60px;
    background-image: linear-gradient(
      to bottom,
      rgba(53, 58, 74, 0) 103%,
      #1a1b29
    );
    @media screen and (max-width: 1100px) {
      padding: 24px;
    }
    @media screen and (max-width: 1000px) {
      padding: 0;
    }
  }
  .inside-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;


    h4 {
      // color: $white;
      @include font-bold(16px, "normal");
      margin: 12px auto;
    }
    p.lead {
      // color: $white;
      @include font-regular(14px, "normal");
    }
    .row {
      @include flexbox;
    }
    .carousel-left {
      @include flexbox;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
      .login-left {
        .carousel {
          width: 100%;
          height: 100%;
          // color: $white;
          .carousel-inner {
            .carousel-item {
              padding-top: 41px;
              transition: transform 0.8s ease-in-out;
              .carousel-text {
                h1 {
                  @include font-black(70px, normal);
                  // color: rgba($color: $white, $alpha: 0.2);
                  position: absolute;
                  top: 0;
                }
                h2 {
                  @include font-bold(25px, 1.36);
                  // color: $white;
                }
                p {
                  @include font-regular(15px, 1.6);
                  // color: rgba($color: $dark, $alpha: 0.7);
                }
              }
            }
          }
        }
      }
    }
    .col-md-6 {
      padding: 0;
      &:last-child {
        @media screen and (max-width: 1100px) {
          margin-top: 50px;
        }
      }
    }
    .login-right {
      min-width: 550px;
      width: 550px;
      margin: 0 auto;

      @media screen and (max-width: 767px) {
        padding: 0;
        width: 100%;
        min-width: 100%;
       
      }

      .login-right-bg {
        width: 100%;
        height: 100%;
        padding: 40px 40px 30px 40px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        @media screen and (max-width: 767px) {
          padding: 24px;
        }
        .spinner-border {
          color: #343a40 !important;
        }
        .right-content {
          .head-section {
            h1 {
              @include font-bold(25px, 1.3);
              padding-bottom: 3px;
              color: $black;
            }
            p {
              @include font-regular(15px, 1.4);
              color: rgba($color: $black, $alpha: 0.7);
            }
          }
          /* form */
          form.login-form {
            padding-top: 30px;
            .form-group {
              position: relative;
              label {
                @include font-regular(12px, normal);
                // color: $white;
              }
              input {
                text-shadow: none;
                // color: $white;
                // -webkit-text-fill-color: $white !important;
              }
              .form-control {
                @include font-regular(15px, normal);
                color: $black;
                border-bottom: 1px solid rgba($color: $black, $alpha: 1);

                &:focus {
                  border-bottom: 1px solid $link-color;
                }
                .was-validated .form-control:invalid,
                &.is-invalid {
                  border-color: $error-color;
                  background-image: none;
                  &:focus {
                    border-color: $error-color;
                    box-shadow: 0 0 0 0.2rem rgba($error-color, 0.25);
                  }
                  ~ .invalid-feedback,
                  ~ .invalid-tooltip {
                    display: block;
                  }
                }
              }
            }
            .password-second {
              padding-top: 15px;
              margin-bottom: 15px;
            }
            .forgot-password {
              width: auto;
              position: absolute;
              top: 34px;
              left: 50%;
              transform: translate(-50%, 0);
              &.link {
                top: 24px;
              }
            }
          }
          /* fotgot password */
          .forgot-password {
            @include flexbox;
            justify-content: flex-end;
            width: 100%;
            p {
              @include font-regular(14px, normal);
              // color: $white;
              cursor: pointer;
            }
            img {
              cursor: pointer;
            }
          }
          /* remember login section */
          .remember-login-section {
            @include flexbox;
            align-items: center;
            justify-content: space-between;
            padding-top: 55px;
            width: 100%;
            position: relative;
            .remember-left {
              .radio {
                @include flexbox;
                align-items: center;
                input {
                  font-size: 25px;
                  margin-bottom: 5px;
                  cursor: pointer;
                }
                label {
                  @include font-regular(14px, 1.3);
                  cursor: pointer;
                  // color: $white;
                  padding-left: 10px;
                }
              }
            }
            .button-right {
              button {
                @include font-medium(13px, normal);
                width: 175px;
              }
            }
          }
        }
      }
      /* login footer */
      .login-footer {
        width: 100%;
        .footer-inside {
          padding: 20px 10px 23px;
          width: 100%;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          .footer-content {
            @include flexbox;
            align-items: center;
            justify-content: center;
            p {
              @include font-regular(15px, normal);
              color: rgba($color: $black, $alpha: 0.7);
              cursor: pointer;
            }
            span {
              @include font-regular(15px, normal);
              color: #01aeff;
              cursor: pointer;
              padding-left: 5px;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
