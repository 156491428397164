.flightDetail {
  &__root {
    padding: 8px 16px;
  }
  &__header {
    h4 {
      color: $black;
    }
  }
  &__left {
    flex: 25;
    img {
      border-radius: 4px;
    }
  }
  &__right {
    flex: 75;
  }
  &__airline,
  &__stops {
    padding-bottom: 6px;
    border-bottom: 1px solid #e2e4e6;
  }
  &__detail {
    @include flexbox;
    align-items: flex-start;
    
    background-color: #f7f8f8;
    
    padding: 12px;
    p.font-weight-bold {
      color: $black;
      @include font-medium(16px, "normal");
    }
    p {
      color: #606060;
      @include font-regular(13px, "normal");
    }
    span {
      font-weight: 400;
      @include font-regular(14px, "normal");
      color: $black;
    }
  }
  &__journey {
    margin-top: 12px;
    p.title {
      @include font-bold(16px, "normal");
      color: #696969;
    }
  }

  &__est {
    @include font-regular(14px, "normal");
    color: #606060;
  }

  &__buttons {
    background-color: #f7f8f8;
    margin: 0 -16px;
  }

  &__header,
  &__header-members {
    background-color: #f7f8f8;
    margin: 0 -16px;
    padding: 0 16px;
    .flex-40 {
      flex: 40;
    }
    .flex-60 {
      flex: 60;
    }
    p {
      color: #696969;
    }
  }

  &__header-members {
    border-top: 1px solid #eaeaea;
    p {
      @include font-regular(13px, "normal");
    }
  }

  &__notes-head {
    border-bottom: 1px solid #eaeaea;
  }

  &__item {
    &:nth-child(n + 2) {
      margin-top: 10px;
    }
    margin-top: 6px;
    @include flexbox;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    background-color: #c4d4e0;
    border-radius: 8px;
    p {
      @include font-medium(14px, "normal");
      color: $black;
    }
  }
}

[data-theme="dark"] {
  .flightDetail {
    &__header {
      h4 {
        color: $white;
      }
    }
    &__journey {
      p {
        color: #9d9fa1;
      }
    }

    &__airline,
    &__stops {
      border-bottom: 1px solid #353945;
    }

    &__header-members {
      border-top: 1px solid #4f5568;
    }

    &__header-members,
    &__buttons {
      background-color: #2e3240;
      p {
        color: #9d9fa1;
      }
    }

    &__item {
      background-color: #33455e;
      p {
        color: $white;
      }
    }
    &__detail {
      background-color: #2e3240;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      p.font-weight-bold,
      span {
        color: $white;
      }
      p {
        color: #9d9fa1;
      }
    }
  }
}
