.settings {
  &-page {
    margin-top: 2rem !important;
  }
  &__card {
    border: 1px solid rgba($color: $white, $alpha: 0.6);
    border-radius: 6px;
    text-align: center;
    padding: 1.4rem 0.5rem;
  }

  &__image {
    height: 90px;
    width: 90px;
    margin: 0 auto;
    @include flexbox;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba($color: $white, $alpha: 0.1);
  }

  &__link {
    margin-top: 0.5rem;
    a {
      @include font-regular($size: 18px, $lineHeight: "normal");
      color: rgba($color: $white, $alpha: 1);
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: rgba($color: $white, $alpha: 0.5);
    }
  }
}
