.event {
}

.event__selectable {
  padding: 8px 18px;

  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.06);
  .icon .calendar-inside {
    h3 {
      color: $black;
    }
    p {
      color: $black;
    }
  }
  .icon-section {
    svg {
      font-size: 1.1rem;
      margin-right: 6px;
    }
  }
  h3 {
    @include font-regular(16px, 22px);
    color: $black;
  }
  p {
    @include font-light(14px, normal);
    color: #606060;
    max-width: 75ch;
  }
}

#eventNote {
  &:focus {
    border-bottom: 1px solid $primary-button-bg !important;
  }
}

// Dark Mode
[data-theme="dark"] {
  .event__selectable {
    h3,
    a {
      color: $almost-white;
      &:hover {
        color: $primary-button-bg;
      }
    }
    .icon .calendar-inside {
      h3,
      p {
        color: $almost-white;
      }
    }
  }
}
