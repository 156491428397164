.contactItem {
  &__root {
    @include flexbox;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid $divider-grey;
    transition: background-color 400ms ease-in;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: calc(100%);
      background-color: $primary-button-bg;
      transition: width 200ms ease-in;
    }
    &.active {
      &:before {
        width: 5px;
      }
      background-color: $hover-grey;
    }
  }
  &__actions {
    margin-left: auto;
    text-align: right;
    cursor: pointer;
  }
  &__details {
    @include flexbox;
    align-items: center;
    width: 360px;
    @media screen and (max-width: 1400px) {
      width: 220px;
    }
    a {
      color: $font-dark-grey;

      &:hover {
        color: $black;
      }
    }
    p {
      @include font-medium($size: 14px, $lineHeight: "normal");
      color: $black;
      &.small {
        @include font-regular($size: 12px, $lineHeight: "normal");
        color: $font-dark-grey;
      }
    }
  }

  &__position {
    text-align: left;
    margin-right: 18px;
    span {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: #606060;
    }
    a {
      @include font-regular($size: 12px, $lineHeight: "normal");

      color: #606060;
      &:hover {
        color: $primary-button-bg;
      }
    }
    p {
      @include font-regular($size: 12px, $lineHeight: "normal");
      color: $white;
    }
  }

  &__avatar {
    margin-right: 17px;
    height: 35px;
    width: 35px;

    @include font-bold($size: 13px, $lineHeight: "normal");
    letter-spacing: 0.93px;
    @include flexbox;
    align-items: center;
    justify-content: center;
  }
}

[data-theme="dark"] {
  .contactItem {
    &__root {
      border-bottom: 1px solid $border-lighter-grey;
      &.active {
        background-color: $dark-hover;
      }
    }
    &__position {
      span {
        color: #9d9fa1;
      }
      a {
        color: $white;
        &:hover {
          color: $primary-button-bg;
        }
      }
    }
    &__details {
      p,
      a,
      .small {
        color: $almost-white;
      }
      .small {
        color: #9d9fa1;
      }
      a:hover {
        color: $primary-button-bg;
      }
    }
  }
}
