/* create event page start here */
.event-page {
  height: 100%;
  overflow-y: auto;

  /*background: $background_modals;*/
  background: #fafafa;
  /* right section start here */
  .event-expennd-section {
    min-width: 500px;
    width: 500px;

    &.flightMembers {
      min-width: 700px;
      width: 700px;
    }
    &.activities {
      min-width: auto;
      width: auto;
      padding-bottom: 24px;
    }
    &.link {
      min-width: 700px;
      width: 700px;
    }
    &.production {
      min-width: calc(90vw - 80px);
      width: calc(90vw - 80px);
    }
    &.hotel-members {
      min-width: 994px;
      width: 994px;
    }
    &.multiple-contact {
      min-width: 80vw;
      width: 80vw;
    }
    &.move-modal {
      min-width: 740px;
      width: 740px;
    }
    &.invite-team {
      min-width: 100%;
      width: 100%;
    }
    .text-head {
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      background: #f5f6fa;
      width: 100%;
      svg {
        color: $font-dark-grey;
        cursor: pointer;
      }
      &.ground {
        padding: 5px 30px;
      }
      &.hotel {
        margin-top: 0;
        padding: 10px 30px;
        .btn {
          font-size: 12px;
          color: $font-dark-grey;
        }
      }
      .text-left {
        &.flex {
          display: flex;
          align-items: center;
        }
        h1 {
          @include font-bold(15px, normal);
          color: $black;
        }
        h3 {
          @include font-regular(12px, 1.69);
          color: $font-dark-grey;
        }
        p {
          @include font-regular(12px, 1.69);
          color: $font-dark-grey;
        }
      }
      .close-icon d-none {
        @include flexbox;
        justify-content: flex-end;
        width: auto;
        .close-round {
          @include flexbox;
          align-items: center;
          justify-content: flex-end;
          padding: 7px;
          background: rgba($color: $black, $alpha: 0.3);
          border-radius: 100%;
          cursor: pointer;
        }
      }
    }

    table {
    }
    thead {
      th {
        @include font-light(14px, normal);
      }
      tr,
      th {
        border: none;
      }
      border: none;
      color: $black;
      background: rgba($color: $black, $alpha: 0.1);
    }
    tbody {
      th {
        border-top: none;
        label {
          display: none;
        }
      }
    }
    .addEvent__form {
      .form-control,
      .react-datepicker-wrapper {
        border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
      }
      .react-datepicker-popper {
        left: -65px !important;
      }
      .react-datepicker-wrapper input[type="text"] {
        // padding-bottom: 7px;
      }
    }
    .event-inside {
      width: 100%;
      height: auto;
      padding: 0px 30px 0px 30px;
      .subtitle {
        @include font-light(12px, normal);
        color: $white;
        text-transform: uppercase;
      }
      &.org {
        height: auto;
      }
      &.hotel {
        height: auto;
        padding-bottom: 0;
        .form-control,
        .react-datepicker-wrapper {
          border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
        }
        form {
          padding-top: 0;
        }
        .css-2b097c-container {
          padding-left: 0;
        }
      }

      &.move {
        padding-left: 18px;
        padding-right: 18px;
      }
      .event-content {
        width: 100%;
        height: 100%;
        button {
          @include font-medium(13px, normal);
        }
        .css-2b097c-container {
          padding-left: 0;
        }
        .css-yk16xz-control {
          &:hover {
            border-bottom: none;
          }
        }
      }
      input {
        &::placeholder {
          color: rgba($color: $black, $alpha: 0.65);
        }
      }

      form {
        label {
          @include font-regular(12px, normal);
          color: #8a8a8a;
        }
        .form-group {
          position: relative;
          margin-bottom: 5px;

          .react-datepicker-wrapper {
            padding-bottom: 7px;
            border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
            display: block;
          }

          .form-control {
            @include font-regular(14px, 1.67);
            padding: 0;
            padding-bottom: 10px;
            color: $black;
            position: relative;
            border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);

            &:focus {
              border-bottom: 1px solid rgba($color: $link-color, $alpha: 1);
            }
          }
          label {
            @include font-regular(12px, normal);
            color: #8a8a8a;
          }
          .drop-icon {
            position: absolute;
            top: 24px;
            right: 0;
            cursor: pointer;
          }
          /* dropdown plugin */
          .css-yk16xz-control {
            background: transparent;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
          }
          .css-1pahdxg-control {
            background: transparent;
            border: none !important;
            border-radius: 0;
            cursor: pointer;
            border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
            box-shadow: none !important;
          }
          .css-26l3qy-menu {
            min-height: 100%;
            overflow-y: auto;
            margin: 0;
            padding: 0;
            .css-1n7v3ny-option {
              font-size: 14px;
            }
            .css-yt9ioa-option {
              font-size: 14px;
            }
          }
          .css-b8ldur-Input {
            pointer-events: none;
          }
          .css-1uccc91-singleValue {
            color: $black;
          }
          .css-1wa3eu0-placeholder {
            font-size: 14px;
          }
          .css-1okebmr-indicatorSeparator {
            display: none;
          }
          .css-1hwfws3 {
            padding: 0;
            &:focus {
              padding: 0;
              margin: 0;
            }
          }
          .css-tlfecz-indicatorContainer {
            &:hover {
              color: $white;
            }
          }
          .css-1gtu0rj-indicatorContainer {
            color: $white;
          }
          .css-9gakcf-option {
            background: rgba($color: $white, $alpha: 0.2);
          }
          .css-1gtu0rj-indicatorContainer {
            padding: 0;
          }

          input {
            color: transparent;
            text-shadow: 0 0 0 #ffffff;

            &:focus {
              outline: none;
            }
          }
        }

        .row {
          .col-md-6 {
            padding: 0;
          }
          .p-right {
            padding-right: 10px;
          }
          .p-left {
            padding-left: 10px;
          }
        }
      }
      .button-section {
        @include flexbox;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;

        .button-left {
          button {
            @include font-medium(13px, normal);
            width: 210px;
          }
        }
        .submit-button {
          padding-top: 0;
          button {
            @include font-medium(13px, normal);
            width: 210px;
            color: $white;
          }
        }
      }
    }

    label {
      @include font-regular(12px, normal);
      color: #8a8a8a;
    }
    input {
      color: $black;
      &:focus {
        color: $black;
        border-bottom: 1px solid rgba($color: $font-dark-grey, $alpha: 0.1);
      }
    }
  }
  .event-scroll {
    position: static !important;
  }
}

.clean-link {
  color: inherit;
}

.black {
  color: $black;
}

.two-buttons {
  @include flexbox;
  padding: 25px 11px;
  button {
    margin: 0 5px;
  }
}

ul {
  width: 100%;
  height: auto;
  position: absolute;
  top: 57px;
  left: 0;
  z-index: 36;
  li {
    color: rgba($color: $black, $alpha: 0.7);
    padding: 10px 20px;
    font-size: 14px;
    line-height: 1.1;
    cursor: pointer;
    &:hover {
      background: rgba($color: $black, $alpha: 0.2);
    }
  }
}

.form-group.horizontal {
  .css-yk16xz-control {
    background: #2a2d37;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: none;

    min-height: 40px;
  }
}
.form-group {
  position: relative;
  margin-bottom: 10px;
  .react-datepicker__input-container {
    padding-top: 6px;
  }
  .react-datepicker-wrapper {
    padding-bottom: 7px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.15);
    display: block;
  }
  .form-control {
    @include font-regular(14px, 1.67);
    padding: 0;
    padding-bottom: 10px;
    color: $black;
    position: relative;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.15);
    &:focus {
      border-bottom: 1px solid rgba($color: $link-color, $alpha: 1);
    }
  }
  label {
    @include font-regular(12px, normal);
  }
  .drop-icon {
    position: absolute;
    top: 24px;
    right: 0;
    cursor: pointer;
  }
  /* dropdown plugin */
  .css-yk16xz-control {
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid #373c49;
  }
  .css-1pahdxg-control {
    background: transparent !important;
    border: none !important;
    border-radius: 0;
    cursor: pointer;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
    box-shadow: none !important;
  }
  .css-26l3qy-menu {
    min-height: 100%;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    z-index: 11;
    .css-1n7v3ny-option {
      font-size: 14px;
    }
    .css-yt9ioa-option {
      font-size: 14px;
    }
  }
  .css-b8ldur-Input {
    pointer-events: none;
  }
  .css-1uccc91-singleValue {
    color: $black;
  }
  .css-1wa3eu0-placeholder {
    font-size: 14px;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1hwfws3 {
    padding: 0;
    &:focus {
      padding: 0;
      margin: 0;
    }
  }
  .css-tlfecz-indicatorContainer {
  }
  .css-1gtu0rj-indicatorContainer {
    color: $white;
  }
  .css-1n7v3ny-option {
    cursor: pointer;
  }
  .css-9gakcf-option {
    background: rgba($color: $black-bg, $alpha: 0.15);
  }
  .css-1gtu0rj-indicatorContainer {
    padding: 0;
  }

  input {
    color: transparent;
    text-shadow: 0 0 0 #ffffff;
    &:focus {
      outline: none;
    }
  }
}

.text-head {
  @include flexbox;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: #E5E8EB !important;//rgba($color: $black, $alpha: 0.1);
  width: 100%;
  .text-left {
    &.flex {
      display: flex;
      align-items: center;
    }
    h1 {
      @include font-bold(15px, normal);
      color: $black;
    }
    h3 {
      @include font-regular(12px, 1.69);
      color: rgba($color: $black, $alpha: 0.7);
    }
    p {
      @include font-regular(12px, 1.69);
      color: rgba($color: $black, $alpha: 0.7);
    }
  }
  .close-icon d-none {
    @include flexbox;
    justify-content: flex-end;
    width: auto;
    .close-round {
      @include flexbox;
      align-items: center;
      justify-content: flex-end;
      padding: 7px;
      background: rgba($color: $black, $alpha: 0.2);
      border-radius: 100%;
      cursor: pointer;
    }
  }
}

.background-dark {
  background: $dark-light-theme;
}

.buttons-invite {
  button {
    font-weight: 300 !important;
    @include font-light(12px, normal);
  }
}

.no-label {
  label {
    display: none;
  }

  input {
    height: 33px !important;
  }
}

.multipleEvents {
  &__toolbar {
    padding: 18px 36px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.3);
  }
  &__footer {
    padding: 7px ​8px;
  }
}

.eventForm {
  &__horizontal {
    &:nth-child(even) {
      // background-color: #fefefe;
      background-color: #fff;
    }
    &:nth-child(odd) {
      //background-color: #f7f8f8;
      background-color: #fff;
    }

    svg.delete {
      color: $red;
      cursor: pointer;
      &:hover {
        color: darken($color: $red, $amount: 15);
      }
    }
  }
}

.onBoarding.contact-transparent {
  .eventForm {
    &__horizontal {
      background-color: $white;
      svg.delete {
        color: $red;
        cursor: pointer;
        &:hover {
          color: darken($color: $red, $amount: 15);
        }
      }
    }
  }
}

/* dropdown plugin */

.css-2b097c-container {
  border-radius: 2px;
  background-color: transparent;
  height: 40px;
  font-size: 13px;
  padding: 0 14px;
  border-bottom: none;
}

.css-yk16xz-control {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
}
.css-1pahdxg-control {
  background: transparent !important;
  border: none !important;
  border-radius: 0;
  cursor: pointer;
  border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
  box-shadow: none !important;
}

.css-b8ldur-Input {
  pointer-events: none;
}
.css-1uccc91-singleValue {
  color: $black;
}
.css-1wa3eu0-placeholder {
  font-size: 14px;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-1hwfws3 {
  padding: 0;
  &:focus {
    padding: 0;
    margin: 0;
  }
}
.css-tlfecz-indicatorContainer {
  &:hover {
    color: $white;
  }
}
.css-1gtu0rj-indicatorContainer {
  color: $white;
}
.css-1n7v3ny-option {
  cursor: pointer;
}

.css-1gtu0rj-indicatorContainer {
  padding: 0;
}

.eventHorizontal {
  ul {
    top: 40px;
  }
  .react-datepicker__input-container {
    input {
      height: 40px;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
    }
  }
  .form-control.filled {
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
    box-shadow: none;
    &::placeholder {
      color: rgba($color: $black, $alpha: 0.7) !important;
    }
  }
  .css-1wa3eu0-placeholder {
    color: rgba($color: $black, $alpha: 0.7) !important;
  }
  .css-yk16xz-control {
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2) !important;
  }
}

[data-theme="dark"] {
  .eventHorizontal {
    ul {
      top: 40px;
    }
    .react-datepicker__input-container {
      input {
        height: 40px;
      }
    }
    .form-control.filled {
      background: transparent;
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
      box-shadow: none;
      &::placeholder {
        color: rgba($color: $white, $alpha: 0.7) !important;
      }
    }
    .css-1wa3eu0-placeholder {
      color: rgba($color: $white, $alpha: 0.7) !important;
    }
    .css-yk16xz-control {
      background: transparent !important;
      border: none !important;
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.2) !important;
    }
  }

  .event-page .event-expennd-section .text-head .text-left h3 {
    color: $almost-white;
  }

  .css-26l3qy-menu {
    background: $dark-light-theme;
    min-height: 100%;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    z-index: 11;
    .css-1n7v3ny-option {
      font-size: 14px;
      color: $white;
    }
    .css-yt9ioa-option {
      font-size: 14px;
      color: $white;
    }
  }

  .css-9gakcf-option {
    background: rgba($color: $white, $alpha: 0.2);
    color: $white;
    &:hover {
      background-color: $dark-hover;
    }
  }

  .text-head .text-left h1,
  .text-head .text-left p {
    color: #9d9fa1;
  }

  .event-page .event-expennd-section .addEvent__form .form-control,
  .event-page .event-expennd-section .addEvent__form .react-datepicker-wrapper,
  .event-page
    .event-expennd-section
    .event-inside
    .event-content
    .css-2b097c-container,
  textarea.form-control {
    border-bottom: 1px solid #373c49;
  }

  .event-page .event-expennd-section label {
    color: #ccc; //rgba($color: $white, $alpha: 0.6);
  }

  .visible-page .visible-expand-section .admin-container .visible-detail {
    background-color: #1d212b;
  }
  .event-page .event-expennd-section input {
    color: $white;
    border-bottom: 1px solid $border-light-grey;
    &::placeholder {
      color: #9b9c9d;
    }
    &:active,
    &:focus {
      border-bottom: 1px solid $primary-button-bg !important;
    }
  }
  .event-page .event-expennd-section .text-head {
    background-color: $dark-modal-header;
    button {
      color: $almost-white !important;
    }
    .text-left {
      h1 {
        color: $almost-white;
      }
      p {
        color: $font-dark-grey;
      }
    }
  }
  .event-page
    .event-expennd-section
    .event-inside
    form
    .form-group
    .form-control {
    color: $almost-white;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
  }

  .event-expennd-section,
  .event-page,
  .flight-page .flight-expand-section {
    background-color: #21242f;
  }
  .form-group .css-1uccc91-singleValue {
    color: $white;
  }
  .form-group .css-1wa3eu0-placeholder {
    color: $almost-white;
  }
}


.guestList th {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ticketRequestModal{
  min-width:1200px !important;
}