/* Round page css start here */
.flight-page {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  background: transparent;
  min-height: 100%;
  /* right section start here */
  .flight-expand-section {
    min-width: 500px;
    width: 500px;
    height: 100vh;
    overflow-y: auto;
    background: $white;
    .flight-head {
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      padding: 17px 30px;
      background: rgba($color: $black, $alpha: 0.1);
      width: 100%;
      .text-left {
        width: 100%;
        h1 {
          @include font-bold(15px, normal);
          color: $black;
        }
        p {
          @include font-regular(13px, 1.69);
          color: rgba($color: $black, $alpha: 0.7);
        }
        .line {
          width: 1px;
          height: 10px;
          margin: 0 10px;
          border: 0.5px solid rgba($color: $black, $alpha: 0.2);
        }
      }
      .close-icon d-none {
        @include flexbox;
        justify-content: flex-end;
        width: auto;
        .close-round {
          @include flexbox;
          align-items: center;
          justify-content: flex-end;
          padding: 7px;
          background: $header-dark;
          border-radius: 100%;
          cursor: pointer;
        }
      }
    }
    .flight-main {
      padding-bottom: 20px;
      .flight-status {
        @include flexbox;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .change-section {
          width: 100%;
          padding: 12px 0;
          border-bottom: 1px solid $header-dark;
          cursor: pointer;
          p {
            @include font-regular(14px, normal);
            @include flexbox;
            align-items: center;
            justify-content: center;
            color: $white;
            img {
              margin-left: 4px;
            }
          }
        }
        .active {
          border-bottom: 2px solid $blue;
        }
      }
      .flight-inside {
        width: 100%;
        padding: 20px 30px 0;
        .row {
          .col-md-6 {
            padding: 0;
          }
          .p-right {
            padding-right: 10px;
          }
          .p-left {
            padding-left: 10px;
          }
        }
        form {
          padding-top: 30px;
          .form-group {
            position: relative;
            margin-bottom: 25px;
            .form-control {
              @include font-regular(14px, 1.67);
              color: $white;
              padding: 0;
              padding-bottom: 10px;
              position: relative;
              border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
              &:focus {
                border-bottom: 1px solid rgba($color: $link-color, $alpha: 1);
                color: $white;
              }
            }
            label {
              @include font-regular(12px, normal);
            }
          }
        }
        .submit-button {
          @include flexbox;
          align-items: center;
          justify-content: space-between;
          padding-top: 25px;
          width: 100%;
          .secondary {
            width: auto;
            white-space: nowrap;
            padding: 12px 42px;
            margin-right: 20px;
            background: $secondary-button-bg;
          }
          .brown {
            width: auto;
            padding: 12px 42px;
            margin-right: 20px;
            color: $white;
            background: rgba($color: $brown-light, $alpha: 0.25);
            &:focus {
              outline: none;
            }
          }
        }
      }
      .flight-info {
        width: 100%;
        padding: 13px 30px;
        background: #1c1f28;
        P {
          @include font-regular(12px, normal);
          color: $white;
        }
      }
    }
  }
}

[data-theme="dark"] {
}
